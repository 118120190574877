import React, {Component} from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types'
import network from "../lib/network";
import * as Swal from "sweetalert2";
import auth from '../lib/auth';
import utils from '../lib/utils';
import Pulse from "../components/spinner/Pulse";
import * as firebase from 'firebase/app';
import '@firebase/messaging';
import {DB_CONFIG} from "../config";
import store from '../store';


let messaging;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: "",
      validated: false,
      isLoading: false
    };
  }

  componentDidMount() {
    const params = utils.getParams(this.props.location.search);
    this.setState({ next: params.get('next') });
  }

  valid() {
    return (utils.isValidEmail(this.state.email)) && this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {

    event.preventDefault();
    this.setState({
      validated: true
    });

    if (this.valid()) {
      this.setState({
        isLoading: true,
      });

      const response = await network.POST('/users/v2/sign_in', {
        ...this.state,
        user_type: 'admin',
      });

      if (response.status !== 'success') {
        Swal.fire({
          type: 'error',
          text: response.errorMessage,
        });
        this.setState({
          isLoading: false,
        });
        return;
      }


      (async function () {
        if (!firebase.apps.length) {
          firebase.initializeApp(DB_CONFIG);
        }

        messaging = firebase.messaging();
        await messaging.requestPermission();
        const currentToken = await messaging.getToken();
        await network.POST('/users/web_push_token', {
          web_push_token: currentToken
        }, [], true);
      })();

      auth.set(response.token);
      const me = await network.getMe();
      if (!(me.company && me.company.company_type === 'retail')) {
        await Swal.fire({
          type: 'error',
          text: `Mohon maaf, email anda telah terdaftar sebagai ${me.user_type} dari ${me.company.name}`,
        });
        localStorage.clear();
        this.setState({
          isLoading: false
        });
        return;
      }

      await Swal.fire({
        type: 'success',
        text: 'Selamat datang',
      });

      store.dispatch({ type: 'auth.update', data: { isAuthorized: true, user: me } })
      document.location.href = this.state.next ? '/#' + decodeURIComponent(this.state.next) : '/#/dashboard';

      this.setState({
        isLoading: false
      });
    }
  };

  render() {
    return (
      <main>
        <div id="primary" className="p-t-b-100 height-full ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-md-auto">
                <div className="text-center">
                  <img src="/assets/img/logo.png" alt="" style={{maxWidth: '75%'}}/>
                  <h3 className="mt-2">Login</h3>
                </div>
                <form action="/" onSubmit={this.handleSubmit}>
                  <label htmlFor="email">
                      Email
                  </label>
                  <div className="form-group has-icon"><i className="icon-envelope-o"/>
                    <input type="text"
                      placeholder='Email'
                      className={`form-control form-control-lg ${!this.valid() && this.state.validated ? 'is-invalid' : ''}`}
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                    />
                    <div className="invalid-feedback">
                        Format email tidak sesuai
                    </div>
                  </div>
                  <label htmlFor="password">
                      Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-key2"/>
                    <input type="password"
                      placeholder='Password'
                      className="form-control form-control-lg"
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                    />
                  </div>
                  <div>
                    <button type="submit" className={`btn btn-ritase btn-lg btn-block`}>
                      {this.state.isLoading ? <Pulse/> : 'Masuk'}
                    </button>
                    <br/>
                    <span>Belum memiliki akun?
                      <Link to={`/register${this.state.next && '?next=' + encodeURIComponent(this.state.next)}`}>
                        <span className="ml-1">Daftar disini</span>
                      </Link>
                    </span>
                    <br/>
                    <span>Lupa Password?
                      <Link to="/forgot">
                        <span className="ml-1">Reset disini</span>
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Login;
