import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import network from '../../lib/network';
import { createMuiTheme } from '@material-ui/core';
import { format, parse } from 'date-fns';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import auth from '../../lib/auth';
import NpwpChecker from "./NpwpChecker";

class ImportGatepass extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentStep: -1,
      showForm: false,
      isLoading: true,
      npwp: '',
      user_id: '',
      action: props.match.params.action
    };
  }

  componentDidMount = async () => {
    if (auth.check()) {
      const me = await network.GET('/users/me', {}, {}, true);
      this.setState({
        npwp: me.company.npwp_number,
        isLoading: false,
        user_id: me.id,
      });
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  };

  componentWillUnmount() {
    this.props.resetImportGatepass()
  }


  setStep = (step) => {
    this.setState({
      currentStep: step
    });
  };

  parseToDate = (date, type) => {
    if (!date) return '';
    let obj = {};
    if (type === 'dateTime') {
      obj.currentParser = 'yyyyMMddHHmmss';
      obj.formatParser = 'yyyy-MM-dd HH:mm:ss';
    } else {
      obj.currentParser = 'yyyy-MM-dd';
      obj.formatParser = 'dd-MM-yyyy';
    }

    let parsedDate = parse(date, obj.currentParser, new Date());
    return format(new Date(parsedDate), obj.formatParser);
  }

  handleNpwpValid = (data) => {
    this.props.setImportGatepass(data);
    this.setState({ currentStep: 0 });
  }

  render() {
    const { currentStep, showForm, isLoading } = this.state;
    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        error: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#27A397',
        },
      },
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#27A397',
          },
        },
        MuiPickersDay: {
          day: {
            color: '#2d2d2d',
          },
          daySelected: {
            backgroundColor: '#27A397',
          },
          dayDisabled: {
            color: '#d4d4d4',
          },
          current: {
            color: '#f24d07',
          },
        },
      },
    });

    const Steps = () => {
      switch (currentStep) {
      case 0:
        return <Step0
          ritaseTheme={ritaseTheme}
          setStep={(step) => this.setStep(step)}
          actionType={this.state.action}
        />;
      case 1:
        return <Step1
          ritaseTheme={ritaseTheme}
          setStep={(step) => this.setStep(step)}
          actionType={this.state.action}
        />;
      case 2:
        return <Step2
          setStep={(step) => this.setStep(step)}
          parseToDate={(date, type) => this.parseToDate(date, type)}
        />;
      case 3:
        return <Step3
          setStep={(step) => this.setStep(step)}
          parseToDate={(date, type) => this.parseToDate(date, type)}
          actionType={this.state.action}
        />;
      default:
        return <NpwpChecker action={this.state.action} initialNpwp={this.state.npwp} userId={this.state.user_id} onNpwpValid={this.handleNpwpValid} />;
      }
    }

    return (
      <Fragment>
        <Navbar/>
        <Sidebar/>
        <Content>
          <div id="primary" className="p-t-b-50 height-full">
            <div className="container">
              <div className="row">
                {isLoading ?
                  <div>Loading...</div>
                  : <div className="col-lg-10 mx-md-auto">
                    <Steps />
                  </div>
                }
              </div>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setImportGatepass: (payload) => dispatch({type: 'orders.import_gatepass', payload}),
  resetImportGatepass: () => dispatch({type: 'orders.import_gatepass.reset'})
});

export default connect(null, mapDispatchToProps)(ImportGatepass);
