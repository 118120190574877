import React, { Component } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Pulse from './spinner/Pulse';
import './OrderContactModal.css';
import network from '../lib/network';

class OrderContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      validate: false,
      contacts: {}
    }
  }  
  
  componentDidMount() {
    let { contacts } = this.state;
    let template = { detail: '', name: '', phone_number: '' }
    let orders = _.get(this.props, 'item.orders', []);
    let origin_contact_id = _.get(orders, '[0].origin_contact_id', null);
    contacts[origin_contact_id] = { ...template };

    _.each(orders, order => {
      let contact_id = _.get(order, 'destination_contact_id', null);
      contacts[contact_id] = { ...template };
    });

    this.setState({ contacts });
  }
  

  validateForm() {
    return _.every(this.state.contacts, c => c.name.length > 0 && c.phone_number.length > 0);
  }

  handleChange = (event, id, field) => {
    let value = event.target.value;
    let { contacts } = this.state;
    
    _.set(contacts, `[${id}][${field}]`, value);

    this.setState({ contacts });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return Swal.fire('', 'Lengkapi data kontak', 'error');
    }
    try {
      console.log(true, this.state)
      let contacts = _.map(this.state.contacts, (value, key) => {
        return {
          id: key,
          ...value
        }
      });
      let body = {
        trip_id: this.props.item.id,
        contacts
      };
      
      const response = await network.POST(`/orders/retail/update_contact`, body, {}, true);
      
      if (response.errorMessage) {
        throw response;
      } else {
        if (response.skipPayment) {
          this.props.onFinish();
        } else {
          this.props.continueToPayment();
        };
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };
  
  render() {
    const { open, closeModal, item } = this.props;
    const ContactForm = ( type, contact_id ) => {
      const formItem = [
        { field: 'detail', label: 'Detail Alamat', placeholder: 'Contoh: nomor rumah', required: false },
        { field: 'name', label: 'Nama', placeholder: 'Nama ', required: true },
        { field: 'phone_number', label: 'Kontak', placeholder: 'Contoh: 081234567890', required: true },
      ];
      let form = formItem.map((form) => {
        const placeholder = form.field === 'name' ? `${form.placeholder}${type}` : form.placeholder;
        return (
          <div key={form.field}>
            <label htmlFor={form.field}>
              {`${form.label} ${form.field !== 'detail' ? type : ''}`}
              {form.required &&
                <i className="s-18 text-danger cur-help" onMouseDown={() => {
                  Swal.fire('', 'Bagian ini harus diisi', 'info');
                }}>*</i>
              }
            </label>
            <div className="form-group">
              <input 
                type={form.field === 'phone_number' ? 'number' : 'text'}
                placeholder={placeholder}
                className="form-control"
                value={_.get(this.state.contacts, `[${contact_id}][${form.field}]`, '')}
                onChange={(e) => this.handleChange(e, contact_id, form.field)}
              />
              {form.required &&
                <div className="invalid-feedback">
                  Kolom ini harus diisi
                </div>
              }
            </div>
          </div>
        )
      })

      return form;
    }

    return (
      <Dialog
        open={open}
        onClose={() => closeModal()}
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle onClose={() => closeModal()} className="border-bottom">
          Informasi Kontak
          <i
            className="icon icon-close2 mt-2 float-right"
            style={{ paddingRight: 0, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </DialogTitle>
        <DialogContent>
          <form action="/" onSubmit={this.handleSubmit}>
            <div className="order-form">
              <div className="row card-history-item list-location-form" style={{overflow: 'visible'}}>
                <div className="col-6">
                  <ul className="list-group location">
                    <li className="list-group-item list-origin">
                      <label>Origin</label>
                      <strong>
                        {_.get(item, 'orders[0].origin_contact_name', '')} - {_.get(item, 'orders[0].origin_contact_number', '')}
                      </strong>
                      <label>
                        {_.get(item, 'orders[0]["origin"]["address"]', '')}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-6 border-bottom">
                  {ContactForm('pengirim', _.get(item, 'orders[0].origin_contact_id', ''))}
                </div>
              </div>

              <div className="row card-history-item list-location-form mt-3">
                <div className="col-6">
                  <ul className="list-group location">
                    <li className="list-group-item list-destination">
                      <label>Destination</label>
                      <strong>
                        {_.get(item, 'orders[0].destination_contact_name', '')} - {_.get(item, 'orders[0].destination_contact_number', '')}
                      </strong>
                      <label>
                        {_.get(item, 'orders[0]["destination"]["address"]', '')}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className={`col-6 ${item.orders.length > 1 ? 'border-bottom' : ''}`}>
                  {ContactForm('penerima', _.get(item, 'orders[0].destination_contact_id', ''))}
                </div>
              </div>

              {item.orders.map((order, idx) => {
                if (idx === 0) return true;
                return (
                  <div key={idx} className="row card-history-item list-location-form mt-3">
                    <div className="col-6">
                      <ul className="list-group location">
                        <li className="list-group-item list-destination">
                          <strong>
                            {_.get(order, 'destination_contact_name', '')} - {_.get(order, 'destination_contact_number', '')}
                          </strong>
                          <label>
                            {_.get(order, '["destination"]["address"]', '')}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className={`col-6 ${idx !== item.orders.length - 1 ? 'border-bottom' : ''}`}>
                      {ContactForm('penerima', _.get(order, 'destination_contact_id', ''))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='mt-3 pt-4 mb-4 text-right border-top'>
              <button
                type="button"
                className="btn btn-lg btn-outline-secondary mr-4"
                onClick={() => closeModal()}
              >
                Close
              </button>
              <button 
                type="submit" 
                className="btn btn-primary btn-lg" 
                onClick={() => {
                  this.setState({
                    validate: true
                  })
                }}
              >
                {this.state.loading ? <Pulse/> : 'Lanjutkan ke Pembayaran'}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default OrderContactModal;