import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'
import './Routes.css'

import VehicleRow from './VehicleRow'
import Locations from "../Locations";

import firebase from 'firebase/app'
import {DB_CONFIG} from "../../config";
import {addHours} from 'date-fns';

import Swal from 'sweetalert2'
import Bar from '../spinner/Bar'
import auth from '../../lib/auth';
import utils from '../../lib/utils';
import network from '../../lib/network';

import DateFnsUtils from "@date-io/date-fns";
import idLocale from "date-fns/locale/id";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CreateDepoModal from './CreateDepoModal'

let fb = firebase;

class NPCTRoute extends Component {
  constructor(props) {
    super(props);

    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }
    this.database = {
      addon_form: fb.database().ref('/addon_form'),
      rates: fb.database().ref('/rates'),
      vehicle_type: fb.database().ref('/vehicle_type'),
    };

    this.state = {
      vehicle_type: [],
      addon_form: [],
      rates: [],
      depo: [],
      openModal: false
    }
  }

  componentDidMount() {
    this.props.scrollToTop();
    this.props.setStep();
    for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({[key]: snap.val()});
      });
    }
    this.props.setPickupType({pType: 'SCHEDULED'})();
    this.props.handleButton({
      add_drop: false,
    });

    if (auth.check()) {
      this.getDepo();

      if (this.props.location.search) {
        const params = utils.getParams(this.props.location.search);

        if (params.get('reference_no')) {
          this.props.setReferenceNo(params.get('reference_no'));
          this.getGatePass(params.get('reference_no')).then(gatepass => {
            let gatepass_no = decodeURIComponent(params.get('gatepass_no'))
            if (gatepass && gatepass.length > 0 && gatepass_no) {
              let selected_gatepass = gatepass.find(gp => gp.tar === gatepass_no);
              if (selected_gatepass) {
                let event = {
                  target: {
                    value: selected_gatepass.gatepassType.toLowerCase()
                  }
                }
                this.handleChangeDeliveryType(event, this.props)
              }
            }
          });
        }
        if (params.get('gatepass_no')) {
          this.props.setGatePassNo(decodeURIComponent(params.get('gatepass_no')))
        }
      }
    } else {
      Swal.fire({
        type: "error",
        text: "Silakan login terlebih dahulu untuk membuat penawaran.",
      });
      window.location.href = `#/login?next=${window.location.hash.substr(1)}`;
      return;
    }
  }

  componentWillUnmount() {
    this.props.handleButton({
      add_drop: true,
    });
  }

  async getGatePass(referenceNumber) {
    const response = await network.POST('/econ/gatepass', { referenceNumber }, {}, true);

    if (response.messages) {
      return Swal.fire('', response.messages, 'error');
    }

    if (response.collections) {
      return response.collections.gatepass;
    }

    return;
  }

  async checkNPWP() {
    if (auth.check()) {
      const { me } = this.props;

      if (!_.get(me, 'company.npwp_number', false)) {
        let html = '<span>Silakan isi NPWP anda terlebih dahulu <a href="/#/profile">di sini</a></span>'
        Swal.fire({
          html,
          type: 'error'
        });
        return;
      }

      const profile = await network.POST('/econ/profile', {
        taxId: me.company.npwp_number, action: "import"
      }, {}, true);
      if (profile.status === 404) {
        let html = '<span>NPWP tidak tercatat di sistem NPCT-1. Mohon mengganti sesuai dengan NPWP terdaftar <a href="/#/profile">di sini</a>.</span>'
        Swal.fire({
          html,
          type: 'error'
        });
        return;
      }

      return profile;
    } else {
      Swal.fire({
        type: "error",
        text: "Silakan login terlebih dahulu untuk membuat penawaran.",
      });
      window.location.href = `#/login?next=${window.location.hash.substr(1)}`;
      return;
    }
  }

  async getDepo() {
    let params = {
      contact_type: 'container_depot',
      populate: 'location'
    }
    const response = await network.GET('/contacts', params, {}, true);

    if (response.rows) {
      this.setState({
        depo: response.rows
      })
    }
  }

  render() {

    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        error: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#27A397',
        },
      },
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#27A397',
          },
        },
        MuiPickersDay: {
          day: {
            color: '#2d2d2d',
          },
          daySelected: {
            backgroundColor: '#27A397',
          },
          dayDisabled: {
            color: '#d4d4d4',
          },
          current: {
            color: '#f24d07',
          },
        },
      },
    });

    const datePicker = (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <ThemeProvider theme={ritaseTheme}>
        <DateTimePicker
          className='form-control bg-white'
          theme={ritaseTheme}
          format="dd MMM yyyy HH:mm"
          ampm={false}
          minutesStep={5}
          onChange={this.props.pickPickupTime}
          value={this.props.pickupTime}
          minDate={this.timeUtils(1)}
          maxDate={this.timeUtils(4)}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>);

    return (
      <div id="info">
        <h6 className="mb-2"><b>Jenis Truk</b></h6>
        <div className="mb-4 bg-white border rounded">
          {this.state.vehicle_type.length > 0 ? this.state.vehicle_type.filter(vehicle => utils.npct_vehicles_id.includes(vehicle.id)).map((row, idx, arr) => (
            <VehicleRow
              locations={this.props.orders.locations}
              key={row.id}
              checked={this.props.vehicle_type === row.id}
              onChange={this.props.pickVehicle(row)}
              tooltip_url={row.tooltip_url}
              rate={
                /*orders.hasSumatera(this.props.orders.locations) ? this.state.rates['Sumatera'][row.id] : this.state.rates['Jawa Bali'][row.id]*/
                this.state.rates
              }
              first={idx === 0}
              last={idx === arr.length - 1}
              {...row}
            />
          )) : <Bar/>}
        </div>

        <h6 className="mb-2"><b>Tipe Pengiriman</b></h6>
        <div className="row mb-4">
          <label className="col-6" style={{display: 'flex', alignItems: 'center'}}>
            <input
              checked={this.props.delivery_type === 'import'}
              onChange={(e) => this.handleChangeDeliveryType(e, this.props)}
              type="radio"
              name="pickup"
              value="import"
            />
            <span>Import</span>
          </label>

          <label className="col-6" style={{display: 'flex', alignItems: 'center'}}>
            <input
              checked={this.props.delivery_type === 'export'}
              onChange={(e) => this.handleChangeDeliveryType(e, this.props)}
              type="radio"
              name="pickup"
              value="export"
            />
            <span>Export</span>
          </label>
        </div>

        <h6 className="mb-2"><b>Reference Number</b></h6>
        <input
          type="text"
          placeholder="Reference Number"
          className="mb-4 s-14 form-control"
          value={this.props.reference_no}
          onChange={(e) => this.props.setReferenceNo(e.target.value)}
        />

        <h6 className="mb-2"><b>Nomor GatePass</b></h6>
        <input
          type="text"
          placeholder="Nomor GatePass"
          className="mb-4 s-14 form-control"
          value={this.props.gatepass_no}
          onChange={(e) => this.props.setGatePassNo(e.target.value)}
        />

        <h6 className="mb-2"><b>Waktu Penjemputan</b></h6>

        {this.props.pickup_type.pType === 'SCHEDULED' ?
          <div className="row">
            <div className="pl-3 input-group pt-2 pb-2 col-12 pl-0 input-scheduled">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon icon-calendar" />
                </span>
              </div>
              {datePicker}
            </div>
          </div> : null
        }

        <h6 className="mb-2 mt-4"><b>Asal dan Tujuan</b></h6>
        <div className="bg-white">
          <Locations
            disableOrigin={this.props.delivery_type === 'import'}
            disableDestination={this.props.delivery_type === 'export'}
          />
        </div>

        <h6 className="mb-2">
          <input
            className='pointer pt-5'
            type="checkbox"
            checked={this.props.is_depo}
            onChange={(e) => this.props.setIsDepo(e.target.checked)}
          />
          <b>Depo</b>
        </h6>
        {this.props.is_depo &&
          <div>
            <select
              className="form-control"
              name="depo"
              onChange={(e) => this.props.setDepo(e.target.value)}
              value={this.props.depo}
            >
              {this.state.depo.length > 0 &&
                <option disabled value=''></option>
              }
              {this.state.depo.length > 0 ?
                this.state.depo.map(item => (
                  <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))
                :
                <option disabled value='empty'>Silakan tambah depo terlebih dahulu</option>
              }
            </select>
            <div
              className="btn-link pointer mt-2 s-12"
              onClick={() => this.setState({ openModal: true })}
            >
              +&nbsp;&nbsp;Tambah Depo
            </div>
          </div>
        }

        <div className="mt-2">
          <button className="btn btn-primary" onClick={() => this.nextStep(this.props)} style={{width: '100%'}}>
            Selanjutnya
          </button>
        </div>

        {this.state.openModal &&
          <CreateDepoModal
            open={this.state.openModal}
            closeModal={() => this.setState({ openModal: false })}
            callback={() => this.getDepo()}
            depo={this.state.depo}
          />
        }
      </div>
    )
  }

  timeUtils(what) {
    let d = new Date();
    switch (what) {
    case 1: /*Terjadwal*/
      return d.setHours(d.getHours() + 7, 0, 0, 0);
    case 2: /*Cepat 3 Jam Terjadwal*/
      this.props.setPickupType({fast: 1})();
      return d.setHours(d.getHours() + 3, d.getMinutes() + 5, 0, 0);
    case 3: /*Cepat 6 Jam Terjadwal*/
      this.props.setPickupType({fast: 2})();
      return d.setHours(d.getHours() + 6, d.getMinutes() + 5, 0, 0);
    case 4: /*Max*/
      return d.setHours(d.getHours() + 168 /*7day*/, d.getMinutes() + 5, 0, 0);
    default:
      return null;
    }
  }

  async nextStep(props) {
    try {
      utils.validateNegotiation({
        locations: props.locations,
        pickup_time: props.pickupTime,
        pickup_type: props.pickup_type,
        vehicle_type: props.vehicle_type,
        vehicle_area: props.vehicle_area,
        scheduledPickupTime: 24,
        delivery_type: props.delivery_type,
        gatepass_no: props.gatepass_no,
        reference_no: props.reference_no,
        isNPCT: true,
        is_depo: props.is_depo,
        depo: props.depo,
      }, 0);

      if (props.reference_no && props.gatepass_no) {
        const response = await this.checkNPWP();
        if (response) {
          const gatepass = await this.getGatePass(props.reference_no);
          if (!gatepass || gatepass.length === 0) {
            return Swal.fire('', 'Nomor Gate Pass tidak terdaftar. Mohon dilakukan pengecekan kembali.', 'error');
          }

          if (!gatepass.value) {
            let selected_gatepass = gatepass.find(gp => gp.tar === props.gatepass_no);
            if (!selected_gatepass) {
              return Swal.fire('', 'Nomor Gate Pass tidak terdaftar. Mohon dilakukan pengecekan kembali.', 'error');
            }

            window.location.href = '#/dashboard/npct1/submit';
            props.handleNext();
          }
        }
      } else {
        window.location.href = '#/dashboard/npct1/submit';
        props.handleNext();
      }

    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      })
    }
  }

  handleChangeDeliveryType(e, props) {
    let { value } = e.target;

    props.setDeliveryType(value);

    props.setLocation({
      id: value === 'import' ? 1 : 0,
      address: "",
      latitude: "",
      longitude: "",
      city: "",
      zipcode: "",
      area: ""
    });

    props.setLocation({
      id: value === 'import' ? 0 : 1,
      address: "Jl. Terminal Kalibaru Raya Kav.B No.1, Cilincing Jakarta Utara, Daerah Khusus Ibukota Jakarta 14110",
      latitude: -6.1320555,
      longitude: 106.8714848,
      city: "Kota Jakarta Utara",
      zipcode: "14110",
      area: "Jawa"
    });
  }
}

NPCTRoute.propTypes = {
  orders: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  vehicle_type: PropTypes.number,
  vehicle_area: PropTypes.array,
  pickVehicle: PropTypes.func,
  setPickupType: PropTypes.func,
  pickupTime: PropTypes.object,
  pickPickupTime: PropTypes.func,
  pickup_type: PropTypes.shape({
    pType: PropTypes.string,
    fast: PropTypes.number,
  }).isRequired,
  setStep: PropTypes.func,
  scrollToTop: PropTypes.func,
  delivery_type: PropTypes.string,
  gatepass_no: PropTypes.string,
  reference_no: PropTypes.string,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  vehicle_area: state.orders.vehicle_area,
  vehicle_type: state.orders.vehicle_type,
  pickupTime: state.orders.pickup_time,
  locations: state.orders.locations,
  pickup_type: state.orders.pickup_type,
  delivery_type: state.orders.delivery_type,
  gatepass_no: state.orders.gatepass_no,
  reference_no: state.orders.reference_no,
  is_depo: state.orders.is_depo,
  depo: state.orders.depo,
  me: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch({type: 'orders.step.goto', payload: 0}),
  handleNext: () => dispatch({type: 'orders.step.next'}),
  pickVehicle: (vehicle) => () => {
    dispatch({type: 'orders.vehicle_type', payload: vehicle.id});
    dispatch({type: 'orders.vehicle_area', payload: {
      routes: vehicle.routes,
    }});
  },
  setPickupType: (type) => () => {
    if (type.pType === 'SCHEDULED')
      dispatch({type: 'orders.pickup_time', payload: addHours(new Date(), 24.5)});

    dispatch({type: 'orders.pickup_type', payload: type})
  },
  pickPickupTime: (pickupTime) => {
    dispatch({type: 'orders.pickup_time', payload: pickupTime})
  },
  handleRemoveLocation: (id) => dispatch({type: 'orders.location.remove', payload: id}),
  setLocation: (payload) => {
    dispatch({
      type: 'orders.location.update',
      payload
    })
  },
  handleButton: (btn) => dispatch({
    type: 'orders.button',
    payload: btn
  }),
  setDeliveryType: (value) => dispatch({
    type: 'orders.delivery_type',
    payload: value
  }),
  setGatePassNo: (value) => dispatch({
    type: 'orders.gatepass_no',
    payload: value
  }),
  setReferenceNo: (value) => dispatch({
    type: 'orders.reference_no',
    payload: value
  }),
  setIsDepo: (value) => dispatch({
    type: 'orders.is_depo',
    payload: value
  }),
  setDepo: (value) => dispatch({
    type: 'orders.depo',
    payload: value
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NPCTRoute);
