import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';

class Content extends Component {
  render() {
    return (
      <div className={this.props.location.pathname === '/dashboard/history' ? "page has-sidebar-left" : "page has-sidebar-left bg-white"}>
        <header className="accent-3 relative nav-sticky">
          <div className="container-fluid">
            {this.props.children}
          </div>
        </header>
      </div>
    )
  }
}

Content.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Content);