const initialState = {
  POD: false,
  helper: false,
  driverhelp: false,
};

function addons(state = initialState, action) {
  switch (action.type) {
  case 'orders.addons.append':
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };
  case 'orders.addons':
    return action.payload;
  default:
    return state;
  }
}

export default addons;
