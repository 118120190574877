const initialState = '';

function gatepass_no(state = initialState, action) {
  if (action.type === 'orders.gatepass_no') {
    return action.payload;
  } else {
    return state
  }
}

export default gatepass_no;