import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../../spinner/Pulse";
import network from '../../../lib/network';

class Step0 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      bookingNo: this.props.bookingNo,
      check: {
        bookingNo: false
      }
    };
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleDateChange = (type, value) => {
    this.setState({
      [type]: value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        bookingNo: this.state.bookingNo.length > 0
      }
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      let body = {
        action: "export",
        bookingNo: this.state.bookingNo,
        messageId: this.props.messageId
      };
      
      const response = await network.POST(`/econ/order`, body, {}, true);
      
      if (parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        body.step0 = response.objects;
        this.props.setExportGatepass(body);
        this.props.setStep(1);
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  render() {
    const { ritaseTheme } = this.props;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Data Delivery Order</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <label htmlFor="bookingNo">
            Booking Order Number
            <i className="s-18 text-danger cur-help" onMouseDown={() => {
              Swal.fire('', 'Bagian ini harus diisi', 'info');
            }}>*</i>
          </label>
          <div className="form-group">
            <input type="text"
              placeholder='Enter booking order number'
              className={this.state.validate && !this.state.check.bookingNo ? "form-control is-invalid" : "form-control"}
              value={this.state.bookingNo}
              onChange={this.handleChange}
              id="bookingNo"
            />
            <div className="invalid-feedback">
              Kolom ini harus diisi
            </div>
          </div>
          <div className='mt-5'>
            <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={() => {
              this.setState({
                validate: true
              })
            }}>{this.state.loading ? <Pulse/> : 'Selanjutnya'}</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.export_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setExportGatepass: (payload) => dispatch({type: 'orders.export_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step0);
