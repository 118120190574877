import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'
import './Routes.css'

import VehicleRow from './VehicleRow'
import Locations from "../Locations";

import firebase from 'firebase/app'
import {DB_CONFIG} from "../../config";
import {addHours} from 'date-fns';

import Swal from 'sweetalert2'
import Bar from '../spinner/Bar'
import utils from '../../lib/utils';

import DateFnsUtils from "@date-io/date-fns";
import idLocale from "date-fns/locale/id";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

let fb = firebase;

class NegotiationRoutes extends Component {
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }
    this.database = {
      addon_form: fb.database().ref('/addon_form'),
      rates: fb.database().ref('/rates'),
      vehicle_type: fb.database().ref('/vehicle_type'),
    };

    this.state = {
      vehicle_type: [],
      addon_form: [],
      rates: [],
    }
  }

  componentDidMount() {
    this.props.scrollToTop();
    this.props.setStep();
    for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({[key]: snap.val()});
      });
    }
    this.props.setPickupType({pType: 'SCHEDULED'})();
  }

  render() {

    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        error: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#27A397',
        },
      },
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#27A397',
          },
        },
        MuiPickersDay: {
          day: {
            color: '#2d2d2d',
          },
          daySelected: {
            backgroundColor: '#27A397',
          },
          dayDisabled: {
            color: '#d4d4d4',
          },
          current: {
            color: '#f24d07',
          },
        },
      },
    });

    const datePicker = (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <ThemeProvider theme={ritaseTheme}>
        <DateTimePicker
          className='form-control bg-white'
          theme={ritaseTheme}
          format="dd MMM yyyy HH:mm"
          ampm={false}
          minutesStep={5}
          onChange={this.props.pickPickupTime}
          value={this.props.pickupTime}
          minDate={this.timeUtils(1)}
          maxDate={this.timeUtils(4)}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>);

    const notAllowedVehicleType = [...utils.npct_vehicles_id, ...utils.ltl_vehicles_id]
    
    return (
      <div id="info">
        <h6 className="mb-2"><b>Jenis Truk</b></h6>
        <div className="mb-4 bg-white border rounded">
          {this.state.vehicle_type.length > 0 ? this.state.vehicle_type.filter(vehicle => !notAllowedVehicleType.includes(vehicle.id)).map((row, idx, arr) => (
            <VehicleRow
              locations={this.props.orders.locations}
              key={row.id}
              checked={this.props.vehicle_type === row.id}
              onChange={this.props.pickVehicle(row)}
              tooltip_url={row.tooltip_url}
              rate={
                /*orders.hasSumatera(this.props.orders.locations) ? this.state.rates['Sumatera'][row.id] : this.state.rates['Jawa Bali'][row.id]*/
                this.state.rates
              }
              first={idx === 0}
              last={idx === arr.length - 1}
              {...row}
            />
          )) : <Bar/>}
        </div>

        <h6 className="mb-2"><b>Waktu Penjemputan</b></h6>

        {this.props.pickup_type.pType === 'SCHEDULED' ?
          <div className="row">
            <div className="pl-3 input-group pt-2 pb-2 col-12 pl-0 input-scheduled">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon icon-calendar" />
                </span>
              </div>
              {datePicker}
            </div>
          </div> : null
        }

        <h6 className="mb-2 mt-4"><b>Asal dan Tujuan</b></h6>
        <div className="bg-white">
          <Locations/>
        </div>

        <div className="mt-2">
          <button className="btn btn-primary" onClick={() => this.nextStep(this.props)} style={{width: '100%'}}>
            Selanjutnya
          </button>
        </div>
      </div>
    )
  }

  timeUtils(what) {
    let d = new Date();
    switch (what) {
    case 1: /*Terjadwal*/
      return d.setHours(d.getHours() + 7, 0, 0, 0);
    case 2: /*Cepat 3 Jam Terjadwal*/
      this.props.setPickupType({fast: 1})();
      return d.setHours(d.getHours() + 3, d.getMinutes() + 5, 0, 0);
    case 3: /*Cepat 6 Jam Terjadwal*/
      this.props.setPickupType({fast: 2})();
      return d.setHours(d.getHours() + 6, d.getMinutes() + 5, 0, 0);
    case 4: /*Max*/
      return d.setHours(d.getHours() + 168 /*7day*/, d.getMinutes() + 5, 0, 0);
    default:
      return null;
    }
  }

  nextStep(props) {
    try {
      utils.validateNegotiation({
        locations: props.locations,
        pickup_time: props.pickupTime,
        pickup_type: props.pickup_type,
        vehicle_type: props.vehicle_type,
        vehicle_area: props.vehicle_area,
        scheduledPickupTime: 24,
      }, 0);
      window.location.href = '#/dashboard/negotiation/submit';
      props.handleNext()
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      })
    }
  }
}

NegotiationRoutes.propTypes = {
  orders: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  vehicle_type: PropTypes.number,
  vehicle_area: PropTypes.array,
  pickVehicle: PropTypes.func,
  setPickupType: PropTypes.func,
  pickupTime: PropTypes.object,
  pickPickupTime: PropTypes.func,
  pickup_type: PropTypes.shape({
    pType: PropTypes.string,
    fast: PropTypes.number,
  }).isRequired,
  setStep: PropTypes.func,
  scrollToTop: PropTypes.func,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  vehicle_area: state.orders.vehicle_area,
  vehicle_type: state.orders.vehicle_type,
  pickupTime: state.orders.pickup_time,
  locations: state.orders.locations,
  pickup_type: state.orders.pickup_type,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch({type: 'orders.step.goto', payload: 0}),
  handleNext: () => dispatch({type: 'orders.step.next'}),
  pickVehicle: (vehicle) => () => {
    dispatch({type: 'orders.vehicle_type', payload: vehicle.id});
    dispatch({type: 'orders.vehicle_area', payload: {
      routes: vehicle.routes,
    }});
  },
  setPickupType: (type) => () => {
    if (type.pType === 'SCHEDULED')
      dispatch({type: 'orders.pickup_time', payload: addHours(new Date(), 24.5)});

    dispatch({type: 'orders.pickup_type', payload: type})
  },
  pickPickupTime: (pickupTime) => {
    dispatch({type: 'orders.pickup_time', payload: pickupTime})
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationRoutes);
