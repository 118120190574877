import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Swal from 'sweetalert2'
import network from '../../lib/network';
import auth from '../../lib/auth';
import utils from '../../lib/utils';
import orders from '../../lib/orders';
import $ from 'jquery'

import {DB_CONFIG} from "../../config";
import firebase from 'firebase/app'
import Pulse from "../spinner/Pulse";
import './Summary.css'
import Toggle from "../addons/Toggle";
import Select from "../addons/Select";
import Input from "../addons/Input";
import ChoosePayment from '../ChoosePayment';

import routeOrders from '../../routes/orders';

const checkoutSummaryRow = ({desc, price, type, removePromo}, idx) => (
  <div className="pl-1 pr-1" key={idx}>
    {idx === 0 ? null : <hr/>}
    <h6 className="mt-1 mb-1" style={{display: 'inline'}}>
      <b>{desc}</b>
    </h6>
    <div className="mb-1 text-right float-right" style={{display: 'inline'}}>
      {type === 'promo' ? `- ${utils.formatRupiah(price).replace('-', '')}` : utils.formatRupiah(price)}
    </div>
    {type === 'promo' ?
      <sup><i style={{cursor: 'pointer'}} className='ml-1 icon icon-close s-12 text-danger'
        onClick={removePromo}/></sup> : ''}
  </div>
);

checkoutSummaryRow.propTypes = {
  desc: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  removePromo: PropTypes.func,
};


class Summary extends Component {

  scrollToTop = () => {
    this.scrollRef.current && this.scrollRef.current.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      timestamp: null,
      promo: null,
      tnc: false,
      validOrder: false,
      loading: false,
      lineItems: [],
      totalPrice: -1,
      vehicleName: '',
      addons: [],
      addon_rates: {},
      addon_form: [],
      showPaymentModal: false,
      response: null,
    }
  }

  componentDidMount = () => {
    this.props.scrollToTop();
    this.scrollToTop();
    this.props.setStep();
    const lastGoodStep = utils.getLastGoodStep(this.props.state.orders);
    if (lastGoodStep < 1) {
      window.location.href = '#' + routeOrders[lastGoodStep + 1].path;
      return;
    }
    this.checkPrice({useCheckFee: (!auth.check() && this.props.promo)});
  };

  componentWillMount() {
    let fb = firebase;
    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }

    const rates_area = orders.hasSumatera(this.props.locations) ? 'Sumatera' : 'Jawa Bali';
    this.rates = fb.database().ref(`/rates/${rates_area}/${this.props.vType}/addon`);
    this.addon = fb.database().ref('/addon_form');

    this.rates.on('value', snap => {
      this.setState({
        addon_rates: snap.val()
      })
    });
    this.addon.on('value', snap => {
      this.setState({
        addon_form: snap.val()
      });
    });

    fb.database().ref('/vehicle_type/').once('value', (snap) => {
      const vehicle = snap.val().filter((vehicle) => (
        vehicle.id === this.props.state.orders.vehicle_type
      ));

      this.setState({
        vehicleName: vehicle[0] ? vehicle[0].name : 'CDE',
      });
    });

    fb.database().ref('/addon_form').once('value', (snap) => {
      this.setState({addons: snap.val()});
    });
  }

  async submitForm(state) {
    if (auth.check())
      try {
        utils.validateOrder(state.orders, 2);
        snap.show();
        const body = {
          locations: this.props.state.orders.locations,
          pickupType: this.props.state.orders.pickup_type.pType,
          vehicleType: this.props.state.orders.vehicle_type,
          remarks: this.props.state.orders.remarks,
          addons: {
            ...this.props.state.orders.addons,
            promo: this.props.state.orders.promo || undefined,
          },
        };

        if (this.props.state.orders.pickup_type.pType === 'SCHEDULED')
          body.pickupTime = this.props.state.orders.pickup_time.toISOString();

        const response = await network.POST('/orders/retail', body, {}, true);

        if (response.message && response.message.includes('authorized')) {
          network.logout('Sesi anda telah berakhir. Mohon login kembali.');
          return;
        }

        snap.hide();
        if (!(response.success !== undefined && response.success)) {
          Swal.fire('', 'Pembayaran gagal. Mohon coba kembali.', 'error');
          return;
        }
        if (response.price !== Number(sessionStorage.getItem('lastPrice'))) {
          await Swal.fire('', `Harga order telah berubah menjadi ${response.price}`, 'info')
        }

        if (response.skipPayment === true) {
          await Swal.fire('', `Order anda telah kami terima`, 'info');
          document.location.href = '/#/dashboard/history';
          window.location.reload();
          return;
        }

        sessionStorage.setItem('lastPrice', response.price);

        this.setState({
          showPaymentModal: true,
          response: response,
        });
      } catch (err) {
        /* global snap */
        snap.hide();
        Swal.fire({
          type: 'error',
          text: err.message,
        });
      }
    else {
      document.location.href = '/#/login'
    }
  }

  removePromo() {
    this.props.handleRemovePromo();
    this.checkPrice({});
  }

  checkPrice = async ({promo, useCheckFee = true}) => {
    this.setState({loading: true});

    const endpoint = (auth.check() && !useCheckFee) ? '/orders/retail/promo' : '/orders/retail/check_fee';

    const body = {
      locations: this.props.state.orders.locations,
      pickupType: this.props.state.orders.pickup_type.pType,
      vehicleType: this.props.state.orders.vehicle_type,
      remarks: this.props.state.orders.remarks,
      addons: {
        ...this.props.addons,
        promo: promo ? promo.toUpperCase() : this.props.state.orders.promo || undefined,
      },
    };
    if (this.props.state.orders.pickup_type.pType === 'SCHEDULED')
      body.pickupTime = this.props.state.orders.pickup_time.toISOString();

    try {
      let now = (new Date()).getTime();
      this.setState({
        timestamp: now
      });
      const response = await network.POST(endpoint, {
        timestamp: now,
        ...body
      }, {}, auth.check());
      this.processResponse(response, body.addons.promo);
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({
        validOrder: !!promo,
        loading: false,
      });
    }
  };

  processResponse = (response, promo) => {
    if (response.message) {
      if (response.message.includes('authorized')) {
        network.logout('Sesi anda telah berakhir. Mohon login kembali.');
        return;
      }
    }
    if (!response.valid) {
      Swal.fire('', 'Order anda tidak valid. Mohon pastikan anda telah mengisi detail dengan benar.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    if (response.errorMessage) {
      Swal.fire('', 'Maaf, kami tidak dapat membuat order anda untuk saat ini. Mohon coba kembali dalam beberapa saat.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }

    if (this.state.timestamp > response.timestamp){
      return;
    }

    sessionStorage.setItem('lastPrice', response.price);
    this.setState({validOrder: true, loading: false});

    const lineItems = [];
    const basePrice = response.paymentItems.filter(
      item => item.type === 'base'
    )[0];
    lineItems.push({
      desc: 'Harga dasar',
      price: basePrice.price
    });

    const multiDropQuantity = (
      (response.lineItems.multidrop
      && response.lineItems.multidrop.quantity)
      || 0
    );
    const multiDropSurcharge = (
      (response.lineItems.multidrop
      && response.lineItems.multidrop.totalPrice)
      || 0
    );

    const distancePrice = response.paymentItems.filter(
      item => item.type === 'distance'
    )[0];
    lineItems.push({
      desc: `Harga ${distancePrice.quantity} km`,
      price: distancePrice.price,
    });

    if (multiDropQuantity > 0) {
      lineItems.push({
        desc: `Multidrop x${multiDropQuantity}`,
        price: multiDropSurcharge,
      });
    }

    const addons = {};
    for (let addon of this.state.addons) {
      addons[addon.name] = addon;
    }

    for (let addon of Object.keys(response.lineItems.addons)) {
      if (addons[addon] === undefined) continue;
      lineItems.push({
        desc: addons[addon].label,
        price: response.lineItems.addons[addon],
      });
    }

    const promoPrice = response.paymentItems.filter(
      item => item.type === 'promo'
    );
    if (promo && promoPrice.length > 0) {
      if (promo !== this.props.promo) {
        Swal.fire({
          type: 'success',
          text: `Selamat anda mendapatkan potongan sebesar ${utils.formatRupiah(promoPrice[0].price).replace('-', '')}`
        });
        this.props.savePromo(promo && promo.toUpperCase());
      }
      lineItems.push({
        desc: `Promo : ${promo}`,
        price: promoPrice[0].price,
        type: 'promo',
        removePromo: this.removePromo.bind(this)
      });
    }

    this.setState({
      validOrder: true,
      loading: false,
      lineItems,
      totalPrice: response.price,
    });
  };

  agree() {
    this.setState({
      tnc: !this.state.tnc
    })
  }

  promo(ctx, e) {
    ctx.setState({
      promo: e.target.value
    });
  }

  render() {
    const lineItems = this.state.lineItems;
    const checkoutSummary = (
      <>
        {lineItems.map(checkoutSummaryRow)}
      </>
    );
    const totalPrice = this.state.totalPrice;
    const total = (
      <div className="p-1">
        <h3 className="mt-1 mb-2" style={{display: 'inline'}}>
          <b>Total</b>
        </h3>
        <span className="mb-1 float-right">
          <h4><b>{
            this.state.loading
              ? <Pulse color={'#27A397'}/>
              : utils.formatRupiah(totalPrice)
          }</b></h4>
        </span>
      </div>
    );

    const promo_field = (
      <div className="mt-2">
        <label htmlFor="promo">Kode Promo</label>
        <input type="text" id='promo' className="form-control mb-2 s-12" placeholder="Opsional"
          value={this.state.promo && this.state.promo.toUpperCase()} onChange={(e) => {
            this.setState({
              promo: e.target.value
            })
          }}/>

        <button
          className="p-1 btn btn-primary btn-xs float-right"
          disabled={(this.state.promo === '') || this.state.loading}
          onClick={() => {
            this.checkPrice({promo: this.state.promo, useCheckFee: false});
          }}>
          Gunakan
        </button>
      </div>
    );

    const buttons = (
      <div className="mt-2">
        <button className="btn btn-outline-danger" style={{width: '45%'}} onClick={() => {
          window.location.href = '#/dashboard/orders/contact';
          this.props.handleBack()
        }}>Kembali
        </button>

        <button
          onClick={() => {
            this.state.tnc ? this.submitForm(this.props.state) : Swal.fire('', 'Anda harus menyetujui Syarat dan Ketentuan yang berlaku', "warning")
          }}
          className="btn btn-primary float-right"
          style={{width: '45%'}}
          disabled={!this.state.validOrder || this.state.loading}>
          {this.state.loading ? <Pulse/> : 'Bayar'}
        </button>
      </div>
    );

    return (
      <div ref={this.scrollRef} id="summary">
        { this.state.addon_rates &&
          <>
          <h6 className="mb-2"><b>Layanan Extra</b></h6>
          <ul className="list-group mb-4">
            {this.state.addon_rates && this.state.addon_form.map((item) =>
              this.addOnItem(item, this.props, this.state.addon_rates[item.name])
            )}
          </ul>
          </>
        }

        <h6 className="mt-3 mb-2"><b>Harga Layanan</b></h6>
        <div className="card mb-3">
          <div className="card-header">Rincian Pesanan</div>
          <div className="card-body">
            <h6 className="mb-2 pl-1 pr-1">
              {this.state.vehicleName}
            </h6>
            {this.state.loading ? <Pulse color={'#27A397'}/> : checkoutSummary}
          </div>
          <div className="card-footer">
            {this.state.loading ? <Pulse color={'#27A397'}/> : total}
          </div>
        </div>
        {auth.check() ? promo_field : null}
        <div className='mt-lg-5'>
          <input className='pointer pt-5' type="checkbox" checked={this.state.tnc} onChange={() => this.agree()}/>
          <span onMouseDown={() => this.agree()} className='pointer noselect'>Saya menyetujui <a href="/#/tnc"> Syarat
          dan Ketentuan</a> yang berlaku</span>
        </div>
        {buttons}
        <ChoosePayment
          open={this.state.showPaymentModal}
          response={this.state.response}
          closeModal={() => {
            this.setState({ showPaymentModal: false })
          }}
          onFinish={() => document.location.href = '/#/dashboard/history'}
        />
      </div>
    )
  }

recheckFee = (e) => {
  this.props.handleChangeAddons(e)
  setTimeout(() => this.checkPrice({promo: this.props.promo, useCheckFee: !auth.check()}), 0);
};

handleTooltip(props) {
  let priceText = '';
  switch (props.price.type) {
  case 'RATE':
    // FIXME: fill this in
    break;
  case 'ENUM':
    // FIXME: use better logic
    priceText = `Harga: ${utils.formatRupiah(props.price.value[true])}`;
    break;
  case 'LOCATIONRATE':
    priceText = `Cuman nambah ${utils.formatRupiah(props.price.value)} per lokasi`;
    break;
  case 'LOCATIONENUM':
    priceText = [
      `Pengiriman kurang dari 5 lokasi: ${utils.formatRupiah(props.price.value['SHORT'])}`,
      `Pengiriman lebih dari 5 lokasi: ${utils.formatRupiah(props.price.value['LONG'])}`,
    ].join('<br/>');
    break;
  default:
    break;
  }

  Swal.fire({
    html: `${props.description}. <br/> ${props.note}<br/>${priceText}`,
    onBeforeOpen: function () {
      $('button.swal2-confirm').removeClass('swal2-confirm').removeAttr("style");
    },
    customClass: {
      confirmButton: 'btn btn-primary btn-xs',
    },
  })
}

addOnItem(props, p, price) {
  if (!props.enable) {
    return;
  }
  if (props.type === 'toggle') {
    return <Toggle key={props.name} name={props.name} note={props.note} label={props.label} handleTooltip={this.handleTooltip}
      checked={p.addons[props.name]}
      description={props.description} handleChange={this.recheckFee}
      price={price}/>
  } else if (props.type === 'select') {
    return <Select key={props.name} label={props.label} name={props.name} option={props.value} note={props.note}
      selected={p.addons[props.name]}
      handleTooltip={this.handleTooltip} description={props.description}
      handleChange={this.recheckFee}/>
  } else if (props.type === 'field') {
    return <Input key={props.name} name={props.name} note={props.note} label={props.label} handleTooltip={this.handleTooltip}
      value={p.addons[props.name]}
      description={props.description} handleChange={this.recheckFee}/>
  }
}
}

Summary.propTypes = {
  promo: PropTypes.string,
  state: PropTypes.object,
  handleBack: PropTypes.func,
  savePromo: PropTypes.func,
  handleRemovePromo: PropTypes.func,
  handleChangeAddons: PropTypes.func,
  removePromo: PropTypes.func,
  handleTooltip: PropTypes.func,
  addons: PropTypes.object,
  vType: PropTypes.number,
  scrollToTop: PropTypes.func,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({
    area: PropTypes.string,
  }))
};

const mapStateToProps = (state) => ({
  vType: state.orders.vehicle_type,
  state: state,
  locations: state.orders.locations,
  addons: state.orders.addons,
  promo: state.orders.promo,
  step: state.orders.step
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch ({type: 'orders.step.goto', payload: 2}),
  handleBack: () => dispatch({type: 'orders.step.back'}),
  savePromo: (promo) => dispatch({
    type: 'orders.promo',
    payload: promo.toUpperCase()
  }),
  handleRemovePromo: () => dispatch({
    type: 'orders.promo',
    payload: ''
  }),
  handleChangeAddons: (event) => {
    if (event.target.type === 'checkbox') {
      dispatch({
        type: 'orders.addons.append', payload: {
          key: event.target.name,
          value: event.target.checked
        }
      });
    } else {
      dispatch({
        type: 'orders.addons.append', payload: {
          key: event.target.name,
          value: event.target.value
        }
      });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
