import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { format } from "date-fns";
import _ from 'lodash';
import utils from "../lib/utils";

const DetailOrderLtlModal = ({ open, closeModal, item, viewPod }) => {
  const status = item.trip ? utils.statusOrder(item.trip) : {name: '', label: ''};

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth="sm"
      fullWidth
      scroll="body"
      disableEnforceFocus
    >
      <DialogTitle onClose={closeModal}>
        Detail Pesanan
        <i
          className="icon icon-close2 mt-2 float-right"
          style={{ paddingRight: 0, cursor: "pointer" }}
          onClick={closeModal}
        />
      </DialogTitle>
      <DialogContent dividers className="card-history-item">
        <div className="row">
          <div className="col-md-7">
            <div className="mb-2">
              <label>Nomor Invoice</label>
              <strong>{item.is_ftl ? item.trip_id : item.id}</strong>
            </div>
            <div className="mb-2">
              <label>Tanggal Dibuat</label>
              <strong>{new Date(item.created_at).toDateString()}</strong>
            </div>
            <div className="mb-2">
              <label>Kendaraan</label>
              <strong>
                {_.get(item, 'trip.vehicle_type.name', '-') || '-'}
              </strong>
            </div>
            <div className="mb-2">
              <label>Jadwal Penjemputan</label>
              <strong>
                {_.get(item, 'trip.ready_time')
                  ? `${new Date(item.trip.ready_time).toDateString()} ${format(
                    new Date(item.trip.ready_time),
                    "HH:mm"
                  )}`
                  : "-"}
              </strong>
            </div>
            <div className="mb-2">
              <label>Muatan</label>
              <strong>{_.get(item, 'trip.remarks', '-') || '-'}</strong>
            </div>
            <div className="mb-2">
              <label>Status</label>
              <strong style={{ textTransform: "capitalize" }}>
                {status.name}
              </strong>
            </div>
            {_.get(item, 'trip.retail_trip_gatepass.id', false) &&
              <div>
                <div className="mb-2">
                  <label>Nomor Reference</label>
                  <strong style={{ textTransform: "capitalize" }}>
                    {_.get(item, 'trip.retail_trip_gatepass.reference_number')}
                  </strong>
                </div>
                <div className="mb-2">
                  <label>Nomor Gatepass</label>
                  <strong style={{ textTransform: "capitalize" }}>
                    {_.get(item, 'trip.retail_trip_gatepass.gatepass_no')}
                  </strong>
                </div>
              </div>
            }
          </div>
          <div className="col-md-5">
            {["prebooked", "approved"].includes(status.name) && (
              <button
                type="button"
                className="btn btn-block btn-primary mb-2"
                onClick={item.payOrder}
              >
                Bayar Pesanan
              </button>
            )}
            {["prebooked", "broadcasted", "approved"].includes(status.name) && (
              <button
                type="button"
                className="btn btn-block btn-outline-danger"
                onClick={item.cancelOrder}
              >
                Tolak Penawaran
              </button>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogContent dividers className="card-history-item">
        <ul className="list-group location">
          {_.get(item, 'shipment_type', '') === 'export' &&
            <li className="list-group-item">
              <label>Depo</label>
              <strong>
                {_.get(item, 'depot_contact.name')} - {_.get(item, 'depot_contact.phone_number')}
              </strong>
              <label className="ellipsis">
                {_.get(item, 'depot_contact.location.address')}
              </label>
            </li>
          }
          <li className="list-group-item">
            <label>Origin</label>
            <strong>
              {item.origin_contact_name} -{" "}
              {item.origin_contact_number}
            </strong>
            <label>{_.get(item, 'origin.address', '-')}</label>
            {_.get(item, 'origin.detail', false) && <label>Detail: {_.get(item, 'origin.detail', '-')}</label>}
          </li>
          <li className="list-group-item">
            <label>Destination</label>
            <strong>
              {item.destination_contact_name} -{" "}
              {item.destination_contact_number}
            </strong>
            <label>{_.get(item, 'destination.address', '-')}</label>
            {_.get(item, 'destination.detail', false) && <label>Detail: {item.destination.detail}</label>}
            {status.name === 'done' && <button
              type="button"
              className="btn btn-outline-ritase btn-sm"
              onClick={() => viewPod(item)}
            >
              Lihat POD
            </button>}
          </li>
          {/* {item.orders.map((order, idx) => {
            if (idx === 0) return true;
            return (
              <li key={order.id} className="list-group-item">
                <strong>
                  {order.destination_contact_name} - {order.destination_contact_number}
                </strong>
                <label>{order.destination.address}</label>
                {_.get(order, 'destination.detail', false) && <label>Detail: {order.destination.detail}</label>}
                {status.name === 'done' && <button
                  type="button"
                  className="btn btn-outline-ritase btn-sm"
                  onClick={() => viewPod(order)}
                >
                  Lihat POD
                </button>}
              </li>
            );
          })} */}
          {_.get(item, 'shipment_type', '') === 'import' &&
            <li className="list-group-item">
              <label>Depo</label>
              <strong>
                {_.get(item, 'depot_contact.name')} - {_.get(item, 'depot_contact.phone_number')}
              </strong>
              <label className="ellipsis">
                {_.get(item, 'depot_contact.location.address')}
              </label>
            </li>
          }
        </ul>
      </DialogContent>
      <DialogContent dividers className="card-history-item">
        <table className="w-100">
          <tbody>
            <tr>
              <th>Detail pembayaran</th>
              <th className="px-3">Biaya</th>
            </tr>
            {_.get(item, 'trip.retail_quotation') && !_.get(item, 'trip.retail_quotation.id') && (
              <tr>
                <td>
                  <label>Harga Pokok</label>
                </td>
                <td className="px-3">
                  {utils.formatRupiah(_.get(item, 'trip.logistic_fee'))}
                </td>
              </tr>
            )}
            {_.get(item, 'trip.retail_quotation') &&
              !_.get(item, 'trip.retail_quotation.id') &&
              _.get(item, 'trip.surcharge_fees') &&
              item.trip.surcharge_fees.map((e) => {
                return (
                  e["charged_fee"] > 0 && (
                    <tr key={e["id"]}>
                      <td>
                        <label>
                          {utils.titleCase(
                            e["surcharge_type"].replace("retail_", "")
                          )}
                        </label>
                      </td>
                      <td className="px-3">
                        {utils.formatRupiah(e["charged_fee"])}
                      </td>
                    </tr>
                  )
                );
              })}
            {_.get(item, 'trip.retail_quotation.is_negotiated') === 1 && (
              <React.Fragment>
                <tr>
                  <td>
                    <label>Harga Penawaran</label>
                  </td>
                  <td className="px-3">
                    {_.get(item, 'trip.retail_quotation.shipper_price.previous_price.price') ?
                      utils.formatRupiah(
                        item.trip.retail_quotation.shipper_price.previous_price.price
                      )
                      : '-'
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Harga yang disetujui</label>
                  </td>
                  <td className="px-3">{utils.formatRupiah(_.get(item, 'fee'))}</td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <td>
                <label>Total Bayar</label>
              </td>
              <td className="px-3">
                <strong className="price">
                  {utils.formatRupiah(_.get(item, 'fee'))}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn btn-outline-ritase"
          onClick={closeModal}
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};

DetailOrderLtlModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  viewPod: PropTypes.func,
  item: PropTypes.object,
};

export default DetailOrderLtlModal;
