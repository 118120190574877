import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Swal from 'sweetalert2'
import _ from 'lodash';
import network from '../../lib/network';
import auth from '../../lib/auth';
import utils from '../../lib/utils';
import Pulse from "../spinner/Pulse";
import './Summary.css'
import ChoosePayment from '../ChoosePayment';

import routes from '../../routes/apk';

const checkoutSummaryRow = ({desc, price, type, removePromo}, idx) => (
  <div className="pl-1 pr-1" key={idx}>
    {idx === 0 ? null : <hr/>}
    <h6 className="mt-1 mb-1" style={{display: 'inline'}}>
      <b>{desc}</b>
    </h6>
    <div className="mb-1 text-right float-right" style={{display: 'inline'}}>
      {type === 'promo' ? `- ${utils.formatRupiah(price).replace('-', '')}` : utils.formatRupiah(price)}
    </div>
    {type === 'promo' ?
      <sup><i style={{cursor: 'pointer'}} className='ml-1 icon icon-close s-12 text-danger'
        onClick={removePromo}/></sup> : ''}
  </div>
);

checkoutSummaryRow.propTypes = {
  desc: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  removePromo: PropTypes.func,
};
class APKSummary extends Component {

  scrollToTop = () => {
    this.scrollRef.current && this.scrollRef.current.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      timestamp: null,
      promo: null,
      tnc: false,
      validOrder: false,
      loading: false,
      lineItems: [],
      totalPrice: -1,
      vehicleName: '',
      addons: [],
      showPaymentModal: false,
      response: null,
    }
  }

  componentDidMount = () => {
    this.props.scrollToTop();
    this.scrollToTop();
    this.props.setStep();
    const lastGoodStep = utils.getLastGoodStepApk(this.props.state.orders);
    if (lastGoodStep < 1) {
      window.location.href = '#' + routes[lastGoodStep + 1].path;
      return;
    }
    this.checkPrice({useCheckFee: (!auth.check() && this.props.promo)});
    // this.checkPrice({useCheckFee: true});
  };

  async submitForm(state) {
    if (auth.check())
      try {
        utils.validateApkOrder(state.orders, 2);
        this.setState({
          loading: true
        })
        snap.show();
        let locations = this.props.locations;
        let post_locations = [];
        let origin = {...locations[0]};
        let destination = {...locations[1]};

        post_locations.push({
          id: 0,
          address: origin.address,
          name: origin.name,
          detail: origin.detail,
          phone_number: origin.phone_number,
          area: '',
          remarks: '',
          latitude: origin.latitude,
          longitude: origin.longitude,
          city: origin.city,
          zipcode: origin.zipcode,
          port: {
            id: _.get(origin, 'port.id'),
            code: _.get(origin, 'port.code'),
            name: origin.label,
            location: {
              latitude: origin.latitude,
              longitude: origin.longitude
            }
          }
        });
        
        post_locations.push({
          ...destination
        });

        const body = {
          locations: post_locations,
          smuno: this.props.smuno,
          ba_date: this.props.ba_date,
          addons: {
            ...this.props.state.orders.addons,
            promo: _.toUpper(this.state.promo) || undefined,
          },
          pickupType: 'CONSOLIDATION',
          vehicleType: this.props.vType.id,
          is_apk: 1,
          remarks: 'dari APK',
          is_ftl: 0
        };

        const response = await network.POST('/orders/retail', body, {}, true);

        if (response.message && response.message.includes('authorized')) {
          network.logout('Sesi anda telah berakhir. Mohon login kembali.');
          return;
        }

        snap.hide();
        if (response && response.errorMessage) {
          Swal.fire('Pembayaran gagal', _.get(response, 'errorMessage', '').split('\n')[0], 'error');
          this.setState({
            loading: false
          })
          return;
        }

        if (!(response.success !== undefined && response.success)) {
          Swal.fire('', 'Pembayaran gagal. Mohon coba kembali.', 'error');
          this.setState({
            loading: false
          })
          return;
        }
        if (response.price !== Number(sessionStorage.getItem('lastPrice'))) {
          this.setState({
            loading: false
          })
          await Swal.fire('', `Harga order telah berubah menjadi ${response.price}`, 'info')
        }

        if (response.skipPayment === true) {
          this.setState({
            loading: false
          })
          await Swal.fire('', `Order anda telah kami terima`, 'info');
          document.location.href = '/#/dashboard/history';
          window.location.reload();
          return;
        }

        sessionStorage.setItem('lastPrice', response.price);

        this.setState({
          showPaymentModal: true,
          response: response,
          loading: false,
        });
      } catch (err) {
        /* global snap */
        snap.hide();
        this.setState({
          loading: false
        })
        Swal.fire({
          type: 'error',
          text: err.message,
        });
      }
    else {
      document.location.href = '/#/login'
    }
  }

  removePromo() {
    this.props.handleRemovePromo();
    this.checkPrice({});
  }

  checkPrice = async ({promo, useCheckFee = true}) => {
    this.setState({loading: true});

    const endpoint = (auth.check() && !useCheckFee) ? '/orders/retail/promo' : '/orders/retail/check_fee';
    // const endpoint = '/orders/retail/check_fee';

    let locations = this.props.locations;
    let post_locations = [];
    let origin = {...locations[0]};
    let destination = {...locations[1]};

    post_locations.push({
      id: 0,
      address: origin.address,
      name: origin.name,
      detail: origin.detail,
      phone_number: origin.phone_number,
      area: '',
      remarks: '',
      latitude: origin.latitude,
      longitude: origin.longitude,
      city: origin.city,
      zipcode: origin.zipcode,
      port: {
        id: _.get(origin, 'port.id'),
        code: _.get(origin, 'port.code'),
        name: origin.label,
        location: {
          latitude: origin.latitude,
          longitude: origin.longitude
        }
      }
    });
    
    post_locations.push({
      ...destination,
    });

    const body = {
      locations: post_locations,
      smuno: this.props.smuno,
      ba_date: this.props.ba_date,
      addons: {
        POD: false,
        driverhelp: false,
        helper: false,
        promo: _.toUpper(this.state.promo) || undefined,
      },
      pickupType: 'CONSOLIDATION',
      vehicleType: this.props.vType.id,
      is_apk: 1,
    };

    try {
      let now = (new Date()).getTime();
      this.setState({
        timestamp: now
      });
      const response = await network.POST(endpoint, body, {}, auth.check());
      this.processResponse(response, body.addons.promo);
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({
        validOrder: !!promo,
        loading: false,
      });
    }
  };

  processResponse = (response, promo) => {
    if (response.message) {
      if (response.message.includes('authorized')) {
        network.logout('Sesi anda telah berakhir. Mohon login kembali.');
        return;
      }
    }
    if (response.errorMessage) {
      Swal.fire('', _.get(response, 'errorMessage', 'Maaf, kami tidak dapat membuat order anda untuk saat ini. Mohon coba kembali dalam beberapa saat.').split('\n')[0], 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    if (!response.valid) {
      Swal.fire('', 'Order anda tidak valid. Mohon pastikan anda telah mengisi detail dengan benar.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }

    if (this.state.timestamp > response.timestamp){
      return;
    }

    sessionStorage.setItem('lastPrice', response.price);
    this.setState({validOrder: true, loading: false});

    const lineItems = [];
    const basePrice = response.paymentItems.filter(
      item => item.type === 'base'
    )[0];
    lineItems.push({
      desc: 'Harga dasar',
      price: basePrice.price
    });

    const distancePrice = response.paymentItems.filter(
      item => item.type === 'distance_and_weight'
    )[0];
    lineItems.push({
      desc: `Harga ${distancePrice.distance} km & ${distancePrice.weight} kg`,
      price: distancePrice.price,
    });
    
    const kargoPrice = response.paymentItems.filter(
      item => item.type === 'angkasa_pura_kargo'
    )[0];
    lineItems.push({
      desc: `Jasa Kargo`,
      price: kargoPrice.price,
    });

    let tkbmPrice = _.reduce(response.paymentItems, (result, item) => {
      if (item.type === 'koli') {
        return result + item.price
      }
      return result
    }, 0)
    lineItems.push({
      desc: `Jasa TKBM`,
      price: tkbmPrice,
    });

    const addons = {};
    for (let addon of this.state.addons) {
      addons[addon.name] = addon;
    }

    for (let addon of Object.keys(response.lineItems.addons)) {
      if (addons[addon] === undefined) continue;
      lineItems.push({
        desc: addons[addon].label,
        price: response.lineItems.addons[addon],
      });
    }

    const promoPrice = response.paymentItems.filter(
      item => item.type === 'promo'
    );
    if (promo && promoPrice.length > 0) {
      if (promo !== this.props.promo) {
        Swal.fire({
          type: 'success',
          text: `Selamat anda mendapatkan potongan sebesar ${utils.formatRupiah(promoPrice[0].price).replace('-', '')}`
        });
        this.props.savePromo(promo && promo.toUpperCase());
      }
      lineItems.push({
        desc: `Promo : ${promo}`,
        price: promoPrice[0].price,
        type: 'promo',
        removePromo: this.removePromo.bind(this)
      });
    }

    this.setState({
      validOrder: true,
      loading: false,
      lineItems,
      totalPrice: response.price,
    });
  };

  agree() {
    this.setState({
      tnc: !this.state.tnc
    })
  }

  promo(ctx, e) {
    ctx.setState({
      promo: e.target.value
    });
  }

  render() {
    const { lineItems, totalPrice } = this.state;
    const checkoutSummary = lineItems.map(checkoutSummaryRow);
    const total = (
      <div className="p-1">
        <h4 className="mt-1 mb-2" style={{display: 'inline'}}>
          <b>Total</b>
        </h4>
        <span className="mb-1 float-right">
          <h5><b>{
            this.state.loading
              ? <Pulse color={'#27A397'}/>
              : utils.formatRupiah(totalPrice)
          }</b></h5>
        </span>
      </div>
    );

    const promo_field = (
      <div className="mt-2">
        <label htmlFor="promo">Kode Promo</label>
        <input type="text" id='promo' className="form-control mb-2 s-12" placeholder="Opsional"
          value={this.state.promo && this.state.promo.toUpperCase()} onChange={(e) => {
            this.setState({
              promo: e.target.value
            })
          }}/>

        <button
          className="p-1 btn btn-primary btn-xs float-right"
          disabled={(this.state.promo === '') || this.state.loading}
          onClick={() => {
            this.checkPrice({promo: this.state.promo, useCheckFee: false});
          }}>
          Gunakan
        </button>
      </div>
    );

    const buttons = (
      <div className="mt-2">
        <button className="btn btn-outline-danger" style={{width: '45%'}} onClick={() => {
          window.location.href = '#/dashboard/apk/contact';
          this.props.handleBack()
        }}>Kembali
        </button>

        <button
          onClick={() => {
            this.state.tnc ? this.submitForm(this.props.state) : Swal.fire('', 'Anda harus menyetujui Syarat dan Ketentuan yang berlaku', "warning")
          }}
          className="btn btn-primary float-right"
          style={{width: '45%'}}
          disabled={!this.state.validOrder || this.state.loading}>
          {this.state.loading ? <Pulse/> : 'Bayar'}
        </button>
      </div>
    );

    return (
      <div ref={this.scrollRef} id="summary">
        <h6 className="mb-2"><b>Detail Barang</b></h6>
        <ul className="list-group">
          {this.props.details.map((item, i) => (
            <li 
              key={i} 
              className="list-group-item"
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
            >
              <h6 className="mb-1">
                <b>{item.fasilitas}</b>
              </h6>
              <div className="mb-1 text-right">
                {item.weight_act} kg
              </div>
            </li>
          ))}
        </ul>

        <h6 className="mt-3 mb-2"><b>Harga Layanan</b></h6>
        <div className="card mb-3">
          <div className="card-header">Rincian Pesanan</div>
          <div className="card-body">
            {this.state.loading ? <Pulse color={'#27A397'}/> : checkoutSummary}
          </div>
          <div className="card-footer">
            {this.state.loading ? <Pulse color={'#27A397'}/> : total}
          </div>
        </div>
        {auth.check() ? promo_field : null}
        <div className='mt-lg-5'>
          <input className='pointer pt-5' type="checkbox" checked={this.state.tnc} onChange={() => this.agree()}/>
          <span onMouseDown={() => this.agree()} className='pointer noselect'>Saya menyetujui <a href="/#/tnc"> Syarat
          dan Ketentuan</a> yang berlaku</span>
        </div>
        {buttons}
        <ChoosePayment
          open={this.state.showPaymentModal}
          response={this.state.response}
          closeModal={() => {
            this.setState({ showPaymentModal: false })
          }}
          onFinish={() => document.location.href = '/#/dashboard/history'}
        />
      </div>
    )
  }
}

APKSummary.propTypes = {
  promo: PropTypes.string,
  state: PropTypes.object,
  handleBack: PropTypes.func,
  savePromo: PropTypes.func,
  handleRemovePromo: PropTypes.func,
  removePromo: PropTypes.func,
  addons: PropTypes.object,
  vType: PropTypes.object,
  scrollToTop: PropTypes.func,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({
    area: PropTypes.string,
  }))
};

const mapStateToProps = (state) => ({
  vType: state.orders.apk.vehicle_type,
  state: state,
  locations: state.orders.locations,
  addons: state.orders.addons,
  promo: state.orders.promo,
  step: state.orders.step,
  smuno: state.orders.apk.smuno,
  ba_date: state.orders.apk.ba_date,
  details: state.orders.apk.details,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch ({type: 'orders.step.goto', payload: 2}),
  handleBack: () => dispatch({type: 'orders.step.back'}),
  savePromo: (promo) => dispatch({
    type: 'orders.promo',
    payload: promo.toUpperCase()
  }),
  handleRemovePromo: () => dispatch({
    type: 'orders.promo',
    payload: ''
  }),
  setPaymentItems: (value) => dispatch({
    type: 'orders.apk.fee',
    payload: value
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(APKSummary);
