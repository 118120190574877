import React from 'react'
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import network from '../lib/network';
import auth from '../lib/auth';
import $ from 'jquery'
import './Sidebar.css'
import { connect } from 'react-redux';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }

  collapse() {
    $(document.body).removeClass('sidebar-open');
    $(document.body).addClass('sidebar-collapse');
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({isMobile: window.innerWidth < 767});
  }

  render() {
    const { user, isAuthorized } = this.props;
    const headerLoggedIn = (
      <div className="text-center mt-4 ml-3 mr-3 bg-white pt-3 pb-3 r-10">
        <h5 className="mb-1 pl-2 pr-2 text-ritase" style={{
          wordBreak: 'break-word',
        }}>{isAuthorized && user.name}</h5>
        <h6 className="mb-4 w-100 email text-ritase" title={isAuthorized && user.email}>{isAuthorized && user.email}</h6>
        <div style={{
          display: 'flex',
          padding: '5px 20px'
        }}>
          <Link to="/profile" className="btn btn-xs btn-outline-primary pl-2 pr-2 r-5 ml-2 mr-1" style={{
            flex: '1',
          }} onClick={() => this.collapse()}>
            Ubah Profil
          </Link>
          <a href="#/" className="btn btn-xs btn-outline-danger pl-2 pr-2 r-5 ml-1 mr-2" onClick={() => network.logout()}
            style={{
              flex: '1',
            }}>
            Logout
          </a>
        </div>
      </div>
    );

    const historyMenu = (
      <li className="treeview">
        <Link to="/dashboard/history">
          <img src="assets/img/sidebar/riwayat.svg" alt="riwayat" className="mr-2" /> <span>Riwayat Order</span>
        </Link>
      </li>
    );

    // const doneMenu = (
    //   <li className="treeview">
    //     <Link to="/dashboard/done">
    //       <i className="icon icon-flag-checkered cyan-text s-18"/> <span>Order Selesai</span>
    //     </Link>
    //   </li>
    // );

    return (
      <aside className="main-sidebar fixed offcanvas shadow bg-ritase no-b sidebar-dark">
        <section className="sidebar">
          <span className='float-right mt-3 mr-3 pointer' onMouseDown={() => {this.collapse()}}>
            <i className='icon icon-close s-18 text-white'/></span>
          <br/>
          {isAuthorized && headerLoggedIn}
          <ul className="sidebar-menu text-white pl-3" onMouseDown={() => {
            if (this.state.isMobile)
              this.collapse()
          }}>
            <li className="treeview mt-3">
              <Link to="/dashboard">
                <img src="assets/img/sidebar/home.svg" alt="home" className="mr-2" /> <span>Home</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="/dashboard/orders/route">
              <img src="assets/img/sidebar/buatorder.svg" alt="buatorder" className="mr-2" /> <span>Buat Order</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="/dashboard/negotiation">
              <img src="assets/img/sidebar/penawaran.svg" alt="penawaran" className="mr-2" /> <span>Minta Penawaran</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="/npct1/order-container">
              <img src="assets/img/sidebar/gatepass.svg" alt="gatepass" className="mr-2" /> <span>NPCT-1 Gate Pass</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="/dashboard/npct1">
              <img src="assets/img/sidebar/npcttruckorder.svg" alt="npcttruckorder" className="mr-2" /> <span>Trailer Truck Order</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="/dashboard/apk">
              <img src="assets/img/sidebar/npcttruckorder.svg" alt="apkorder" className="mr-2" /> <span>Angkasa Pura Kargo Order</span>
              </Link>
            </li>
            {auth.check() ? historyMenu : null}
            {/* {auth.check() ? doneMenu : null} */}

            <li className="treeview">
              <a href="https://ritase.zendesk.com/hc/id/requests/new" target="_blank" rel="noopener noreferrer">
              <img src="assets/img/sidebar/bantuan.svg" alt="bantuan" className="mr-2" /> <span>Bantuan</span>
              </a>
            </li>
            <li className="treeview">
              <a href="https://ritase.com" target="_blank" rel="noopener noreferrer">
              <img src="assets/img/sidebar/tentangkami.svg" alt="tentangkami" className="mr-2" /> <span>Tentang Kami</span>
              </a>
            </li>
          </ul>
          <footer className={`footer${!auth.check() ? ' footer-absolute' : ''}`}>
            <h6 style={{color: 'white'}}>
              Metode Pembayaran
            </h6>
            <div className='cc-container' style={{
              borderRadius: '0.2em 0.2em 0 0 ',
            }}>
              <img
                className='cc-logo'
                src='/assets/img/logo/midtrans.png'
                alt='midtrans'
              />
            </div>
            <div className='cc-container' style={{
              borderRadius: '0 0 0.2em 0.2em',
            }}>
              <img
                className='cc-logo'
                src='/assets/img/logo/bca.png'
                alt='bca'
              />
              <img
                className='cc-logo'
                src='/assets/img/logo/visa.png'
                alt='visa'
              />
              <img
                className='cc-logo'
                src='/assets/img/logo/mastercard.png'
                alt='mastercard'
              />
              <img
                className='cc-logo'
                src='/assets/img/logo/jcb.png'
                alt='jcb'
              />
              <img
                className='cc-logo'
                src='/assets/img/logo/amex.png'
                alt='amex'
              />
            </div>
          </footer>
        </section>
      </aside>
    )
  }
}


Sidebar.propTypes = {
  isAuthorized: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state.auth
});

export default connect(mapStateToProps)(Sidebar)