import Route from "../components/wizard/Routes";
import Contacts from "../components/wizard/Contacts";
import Summary from "../components/wizard/Summary";

const routes = [
  {
    path: '/dashboard/orders/route',
    component: Route
  },
  {
    path: '/dashboard/orders/contact',
    component: Contacts
  },
  {
    path: '/dashboard/orders/pay',
    component: Summary
  }
];

export default routes;