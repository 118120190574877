import React from 'react'
import PropTypes from 'prop-types'
import * as Swal from "sweetalert2";
import {connect} from "react-redux";
import './Contact.css'

class Contact extends React.Component {
  render() {
    const location = this.props.locations[this.props.idx];
    return (
      <div className="form-group card p-2">
        <div className="card-header mb-2 bg-white pl-0 pr-0" style={{
          display: 'flex',
          position: 'relative',
        }}>
          <div className="pl-2 pr-2" style={{
            display: 'inline',
            margin: 'auto',
            flex: 1,
            textAlign: 'center',
            fontWeight: 800,
            fontSize: '32px',
          }}>
            {this.props.idx + 1}
          </div>
          <div className="pl-2 pr-2" style={{
            display: 'inline',
            flex: 11,
            borderLeft: '1px solid',
          }}>
            <b>{this.props.address}</b>
          </div>
        </div>
        <label>
          Detail alamat
          <input
            placeholder='Contoh: nomor rumah'
            type="text"
            className="form-control mb-1 form-control-sm"
            value={location.detail}
            onChange={this.props.handleChange('detail')}
          />
        </label>
        <label>
          {this.props.idx === 0 ? 'Nama Pengirim' : 'Nama Penerima'}

          <i className="s-18 text-danger cur-help" onMouseDown={() => {
            Swal.fire('', 'Bagian ini harus diisi', 'info');
          }}>*</i>
          <input
            placeholder={this.props.idx === 0 ? 'Nama Pengirim' : 'Nama Penerima'}
            type="text"
            className="form-control mb-1 form-control-sm"
            value={location.name}
            onChange={this.props.handleChange('name')}
          />
        </label>
        <label>
          {this.props.idx === 0 ? 'Kontak Pengirim' : 'Kontak Penerima'}
          <i className="s-18 text-danger cur-help" onMouseDown={() => {
            Swal.fire('', 'Bagian ini harus diisi', 'info');
          }}>*</i>
          <input
            placeholder='Contoh : 08123456789'
            onWheel={(e) => {
              e.target.blur()
            }}
            type="number"
            className="form-control no-scroll form-control-sm"
            name="contact-number"
            value={location.phone_number}
            onKeyDown={handleKeyPress}
            onChange={this.props.handleChange('phone_number')}
          />
        </label>
        {(this.props.idx !== 0 && !this.props.hideDetailPaket) &&
            <label>
              Detail paket
              <input
                placeholder='Contoh: kardus dan bata'
                type="text"
                className="form-control mb-1 form-control-sm"
                value={location.remarks}
                onChange={this.props.handleChange('remarks')}
              />
            </label>
        }
      </div>
    )
  }
}

const handleKeyPress = (evt) => {
  if ((evt.keyCode !== 8 && evt.keyCode !== 9 && evt.keyCode !== 0 && evt.keyCode < 48) || evt.keyCode > 57 )
    evt.preventDefault();
};

const mapStateToProps = (state) => ({
  remarks: state.orders.remarks,
  addons: state.orders.addons,
  locations: state.orders.locations,
});

Contact.propTypes = {
  address: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    detail: PropTypes.string,
    phone_number: PropTypes.string,
  })),
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  idx: PropTypes.number,
};

export default connect(mapStateToProps) (Contact);
