import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import network from '../../../lib/network';
import * as Swal from "sweetalert2";
import {createMuiTheme} from '@material-ui/core';
import {format, parse} from 'date-fns';
import _ from 'lodash';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import Content from '../../Content';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import auth from '../../../lib/auth';
import {Link} from 'react-router-dom';
import NpwpChecker from "../NpwpChecker";

class ExportGatepass extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentStep: -1,
      showForm: false,
      isLoading: true,
      npwp: '',
      user_id: '',
    };
  }

    componentDidMount = async () => {
      if (auth.check()) {
        const me = await network.GET('/users/me', {}, {}, true);
        this.setState({
          npwp: me.company.npwp_number,
          isLoading: false,
          user_id: me.id,
        });
      } else {
        network.logout('Silakan login terlebih dahulu', true);
      }
    };

    componentWillUnmount() {
      this.props.resetExportGatepass()
    }


    setStep = (step) => {
      this.setState({
        currentStep: step
      });
    };

    parseToDate = (date, type) => {
      if (!date) return '';
      let obj = {};
      if (type === 'dateTime') {
        obj.currentParser = 'yyyyMMddHHmmss';
        obj.formatParser = 'yyyy-MM-dd HH:mm:ss';
      } else {
        obj.currentParser = 'yyyy-MM-dd';
        obj.formatParser = 'dd-MM-yyyy';
      }

      let parsedDate = parse(date, obj.currentParser, new Date());
      return format(new Date(parsedDate), obj.formatParser);
    }

    handleNpwpValid = (data) => {
      this.props.setExportGatepass(data);
      this.setState({currentStep: 0});
    }

    render() {
      const {currentStep, showForm, isLoading} = this.state;
      const ritaseTheme = createMuiTheme({
        palette: {
          primary: {
            light: '#27A397',
            main: '#27A397',
            dark: '#27A397',
            contrastText: '#ffffff',
          },
          secondary: {
            light: '#27A397',
            main: '#27A397',
            dark: '#27A397',
            contrastText: '#ffffff',
          },
          error: {
            light: '#27A397',
            main: '#27A397',
            dark: '#27A397',
            contrastText: '#27A397',
          },
        },
        overrides: {
          MuiPickersToolbar: {
            toolbar: {
              backgroundColor: '#27A397',
            },
          },
          MuiPickersDay: {
            day: {
              color: '#2d2d2d',
            },
            daySelected: {
              backgroundColor: '#27A397',
            },
            dayDisabled: {
              color: '#d4d4d4',
            },
            current: {
              color: '#f24d07',
            },
          },
        },
      });

      const Steps = () => {
        switch (currentStep) {
        case 0:
          return <Step0
            ritaseTheme={ritaseTheme}
            setStep={(step) => this.setStep(step)}
          />;
        case 1:
          return <Step1
            ritaseTheme={ritaseTheme}
            setStep={(step) => this.setStep(step)}
          />;
        case 2:
          return <Step2
            setStep={(step) => this.setStep(step)}
            parseToDate={(date, type) => this.parseToDate(date, type)}
          />;
        case 3:
          return <Step3
            setStep={(step) => this.setStep(step)}
            parseToDate={(date, type) => this.parseToDate(date, type)}
          />;
        case 4:
          return <Step4
            setStep={(step) => this.setStep(step)}
            parseToDate={(date, type) => this.parseToDate(date, type)}
          />;
        default:
          return <NpwpChecker action={'export'} initialNpwp={this.state.npwp}
            userId={this.state.user_id}
            onNpwpValid={this.handleNpwpValid}/>;
        }
      }

      return (
        <Fragment>
          <Navbar/>
          <Sidebar/>
          <Content>
            <div id="primary" className="p-t-b-50 height-full">
              <div className="container">
                <div className="row">
                  {isLoading ?
                    <div>Loading...</div>
                    :
                    <div className="col-lg-10 mx-md-auto">
                      <Steps />
                    </div>
                  }
                </div>
              </div>
            </div>
          </Content>
        </Fragment>
      );
    }
}

const mapDispatchToProps = (dispatch) => ({
  setExportGatepass: (payload) => dispatch({type: 'orders.export_gatepass', payload}),
  resetExportGatepass: () => dispatch({type: 'orders.export_gatepass.reset'})
});

export default connect(null, mapDispatchToProps)(ExportGatepass);
