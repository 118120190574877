const initialState = {
  isAuthorized: false,
  user: {}
};

function auth(state = initialState, action) {
  switch (action.type) {
  case 'auth.update':
    return {
      ...state,
      ...action.data
    };
  case 'auth.append':
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };
  case 'auth.clear':
    return {
      isAuthorized: false,
      user: {}
    };
  default:
    return state;
  }
}

export default auth;
