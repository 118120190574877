const initialState = null;

function vehicle_type(state = initialState, action) {
  if (action.type === 'orders.vehicle_type') {
    return action.payload;
  } else {
    return state
  }
}

export default vehicle_type;
