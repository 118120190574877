import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import './vrow.css'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import orders from '../../lib/orders'
import utils from "../../lib/utils";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * @return {string}
 */
export default function VehicleRow(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let show = true;
  let origin, dest;
  
  if (props.area && orders[props.area]) {
    origin = orders[props.area](props.locations) ? props.area.substr(3) : false;
    dest = orders.destination(props.locations)
  } else {
    origin = orders.origin(props.locations);
    dest = orders.destination(props.locations);
  }


  if (origin && dest) {
    show = props.routes[origin] ? props.routes[origin].includes(dest) : false
  }

  return (
    <div>
      <label className="truck m-0">
        <input disabled={!show} type="radio" name="radioname" value={props.id} checked={props.checked} onChange={props.onChange}/>
        <div
          className={props.last ? "p-2 bottom-item" : props.first ? "p-2 top-item border-bottom" : "p-2 border-bottom"}
          style={{
            backgroundColor: show ? '' : '#00000026',
          }}
        >
          <img src={props.checked ? props.checked_image_url : props.image_url} alt={props.name}
            style={{maxWidth: '35px', width: '35px'}}/><span
            className="ml-3">{props.name}</span>
          <InfoOutlinedIcon onClick={handleClickOpen} className="float-right s-18 cur-help"/>
        </div>
      </label>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle className='text-center' id="customized-dialog-title" onClose={handleClose}>
          <div className='text-center w-100'>
            <h2 className="mb-lg-2 font-weight-bold"><strong>{props.name}</strong></h2>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div className="row">
              <div className="col-lg-12 col-sm-12" style={{margin: 'auto'}}>
                <div className="row">
                  <div className="col-12">

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12" style={{margin: 'auto'}}>
                <div className="row">
                  <div className="col-12">
                    <img src={props.tooltip_url} alt=""/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-3">
                      <div className="card-header bg-ritase text-white">Harga</div>
                      {Object.keys(props.rate).map((key) => {
                        return (props.rate[key][props.id] ? <div key={key} className="card-body">
                          <h6>Harga Untuk Wilayah {key}</h6>
                          <table style={{width: '100%'}}>
                            <tr>
                              <td className="text-right font-weight-bold">Harga dasar</td>
                              <td className="font-weight-bold">:</td>
                              <td
                                className="text-right font-weight-normal">
                                {utils.formatRupiah(props.rate[key][props.id].base)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right font-weight-bold">1-60 KM</td>
                              <td className="font-weight-bold">:</td>
                              <td className="text-right font-weight-normal">
                                {utils.formatRupiah(props.rate[key][props.id].short)} /km
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right font-weight-bold">60+ KM</td>
                              <td className="font-weight-bold">:</td>
                              <td
                                className="text-right font-weight-normal">{utils.formatRupiah(props.rate[key][props.id].long)} /km
                              </td>
                            </tr>
                          </table>
                        </div> : '');
                      })}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card mb-3">
                      <div className="card-header bg-ritase text-white">Kapasitas</div>
                      <div className="card-body">
                        <table style={{width: '100%'}}>
                          <tr>
                            <td className="text-right font-weight-bold">Panjang</td>
                            <td className="font-weight-bold">:</td>
                            <td className="text-right font-weight-normal">{props.dimension.length} cm</td>
                          </tr>
                          <tr>
                            <td className="text-right font-weight-bold">Lebar</td>
                            <td className="font-weight-bold">:</td>
                            <td className="text-right font-weight-normal">{props.dimension.width} cm</td>
                          </tr>
                          <tr>
                            <td className="text-right font-weight-bold">Tinggi</td>
                            <td className="font-weight-bold">:</td>
                            <td className="text-right font-weight-normal">{props.dimension.height} cm</td>
                          </tr>
                          <tr>
                            <td className="text-right font-weight-bold">CBM</td>
                            <td className="font-weight-bold">:</td>
                            <td className="text-right font-weight-normal">{props.dimension.cbm} m<sup>3</sup></td>
                          </tr>
                        </table>
                        <hr/>
                        <table style={{width: '100%'}}>
                          <tr>
                            <td className="text-right font-weight-bold">Berat Max</td>
                            <td className="font-weight-bold">:</td>
                            <td className="text-right font-weight-normal">{props.max_load} kg</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='text-center w-100'>
            <button className='btn btn-primary pl-4 pr-4' onClick={handleClose}>Tutup</button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VehicleRow.propTypes = {
  id: PropTypes.number.isRequired,
  info: PropTypes.object.isRequired,
  last: PropTypes.bool.isRequired,
  first: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  routes: PropTypes.object.isRequired,
  dropable: PropTypes.array.isRequired,
  locations: PropTypes.object.isRequired,
  area: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  checked_image_url: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dimension: PropTypes.object.isRequired,
  max_load: PropTypes.string.isRequired,
  tooltip_url: PropTypes.string.isRequired,
  rate: PropTypes.object.isRequired,
  vehicle_type: PropTypes.number.isRequired
};
