import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../../spinner/Pulse";
import _ from 'lodash';
import ExportVerificationModal from './ExportVerificationModal';
import network from '../../../lib/network';

class Step4 extends Component {

  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        search: '',
        openModal: false,
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };


  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true
    });
    const { customerVgm } = this.props
    const { selectedContainer } = this.state;
    if (selectedContainer.length > 0) {
        if (customerVgm) {
            let selectedContainer = [...this.state.selectedContainer];
            selectedContainer = _.filter(selectedContainer, con => parseInt(con.grossWeight) < 1000);

            if (selectedContainer.length > 0) {
                Swal.fire('', 'Semua nilai VGM untuk container yang dipilih minimal bernilai 1000', 'error');
            }
        }

        this.props.setExportGatepass({ selectedContainer });
        this.props.setStep(4);
    } else {
      Swal.fire('', 'Anda harus memilih container', 'error');
    }

    this.setState({
      loading: false
    });
  };

  handleSubmit = async (event) => {
    if (event) event.preventDefault();
    const { customerVgm } = this.props;
    this.setState({
      loading: true
    });
    try {
      let body = {
        action: "export",
        messageId: this.props.messageId,
        containers: {}
      };

      _.each(this.props.selectedContainer, container => {
        body.containers[container.noContainer] = {
            contNo: container.noContainer
        }
        if (customerVgm) body.containers[container.noContainer].grossWeight = container.grossWeight
      })

      const response = await network.POST(`/econ/confirmation`, body, {}, true);
      
      if (parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        body.step4 = response.collections;
        this.props.setExportGatepass(body);
        this.setState({
          openModal: true
        })
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      if (!errorMessage) errorMessage = 'Order gagal diproses'
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  filterContainer = () => {
    let containers = _.get(this.props, 'selectedContainer', []);
    const { search } = this.state;
    if (search.length > 0) {
      return _.filter(containers, container => _.get(container, 'noContainer', '').toLowerCase().includes(search.toLowerCase()));
    }

    return containers;
  }

  closeModal = () => {
    this.setState({
      openModal: false
    })
  }

  render() {
    const { step2, customerName, parseToDate, customerVgm, taxId } = this.props;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Konfirmasi Data</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
        <div className="row mt-5">
            <div className="col-3">
              <h4>Data Tujuan</h4>
              <div className="form-group">
                <label>Via / Transit</label>
                <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>{ _.get(step2, 'orders.spod', '').split('|')[0] } - { _.get(step2, 'orders.spod', '').split('|')[1] }</p>
              </div>
              <div className="form-group">
                <label>Tujuan</label>
                <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>{ _.get(step2, 'orders.pod', '').split('|')[0] } - { _.get(step2, 'orders.pod', '').split('|')[1] }</p>
              </div>
            </div>

            <div className="col-3">
              <h4>Data Pelanggan</h4>
              <div className="form-group">
                <label>NPWP</label>
                <p>{ taxId }</p>
              </div>
              <div className="form-group">
                <label>Nama Pelanggan</label>
                <p>{ customerName }</p>
              </div>
            </div>

            <div className="col-3">
              <h4>Data Vessel</h4>
              <div className="form-group">
                <label>Vessel / Voyage</label>
                <p>{ _.get(step2, 'orders.vesselName', '') } / { _.get(step2, 'orders.voyageOut', '') }</p>
              </div>
              <div className="form-group">
                <label>Agent</label>
                <p>{ _.get(step2, 'orders.agent', '').split('|')[0] }</p>
              </div>
            </div>
            
            <div className="col-3">
              <h4>Data Bea Cukai</h4>
              <div className="form-group">
                <label>Dokumen Bea Cukai</label>
                <p>{ _.get(step2, 'orders.responseType', '') }</p>
              </div>
              <div className="form-group">
                <label>Nomor Dokumen Bea Cukai</label>
                <p>{ _.get(step2, 'orders.responseNumber', '') }</p>
              </div>
              <div className="form-group">
                <label>Tanggal Dokumen Bea Cukai</label>
                <p>{ parseToDate(_.get(step2, 'orders.responseDate', ''), 'date') }</p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <h4>List Container</h4>
              <div className="mt-3 d-flex align-items-center">
                <div className="mr-3">Pencarian</div>
                <input 
                  id="search"
                  type="text" 
                  className="form-control" 
                  placeholder="Kata Kunci" 
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="mt-4">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>KONTAINER</th>
                      <th>ISOCODE</th>
                      <th>UKURAN</th>
                      <th>TIPE</th>
                      <th>F/E</th>
                      <th>VGM (KG)</th>
                      <th>IMDG</th>
                      <th>SUHU</th>
                      <th>OR</th>
                      <th>OH</th>
                      <th>OL</th>
                      <th>RFR.SHIFT</th>
                      <th>BILLING</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.filterContainer().length > 0 ?
                      this.filterContainer().map((container, i) => (
                        <tr key={i}>
                          <td>{ container.noContainer }</td>
                          <td>{ container.isocode }</td>
                          <td>{ container.size }</td>
                          <td>{ container.contType }</td>
                          <td>{ container.fullEmpty }</td>
                          <td>{ customerVgm ? container.grossWeight : '' }</td>
                          <td>{ container.imdgValue }</td>
                          <td>{ container.temperature }</td>
                          <td>{ container.OR }</td>
                          <td>{ container.OH }</td>
                          <td>{ container.OL }</td>
                          <td>{ container.reefer }</td>
                          <td>{ container.billing || '-' }</td>
                        </tr>
                      ))
                    :
                      <tr>
                        <td colSpan={15} className="text-center">
                          Not found
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-3">
            <div className="col-sm-6">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => this.props.setStep(3)}>{this.state.loading ? <Pulse/> : 'Sebelumnya'}</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary btn-lg btn-block">
                {this.state.loading ? <Pulse/> : 'Selanjutnya'}
              </button>
            </div>
          </div>
        </form>

        {this.state.openModal &&
          <ExportVerificationModal 
            openModal={this.state.openModal}
            closeModal={() => this.closeModal()}
            resend={() => this.handleSubmit()}
          />
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.export_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setExportGatepass: (payload) => dispatch({type: 'orders.export_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
