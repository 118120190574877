import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers';

const logger = store => next => action => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dispatching', action);
  }
  const result = next(action);
  if (process.env.NODE_ENV !== 'production') {
    console.log('state', store.getState());
  }
  return result;
};

const store = createStore(
  reducer,
  applyMiddleware(logger),
);

export default store;