import React, {Component} from 'react'
import PropTypes from "prop-types";

export default class Toggle extends Component {
  render() {
    const props = this.props;
    return (
      <li className="list-group-item" key={props.name}>
        {props.label}
        <i className={`icon icon-info2 ml-2 s-18 cur-help`}
          onMouseDown={this.props.handleTooltip.bind(this, props)}/>

        <div className="material-switch float-right">
          <input id={props.name} name={props.name} type="checkbox" onChange={this.props.handleChange} checked={this.props.checked}/>
          <label htmlFor={props.name} className="bg-ritase"/>
        </div>
      </li>
    )
  }
}

Toggle.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  note: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  handleTooltip: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};
