import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {geocodeByAddress} from 'react-places-autocomplete'
import LocationInput from './LocationInput'
import Swal from 'sweetalert2'
import gmaps from '../lib/gmaps';
import 'jquery-ui-touch-punch'
import * as jabodetabek from "../lib/geojson/jabodetabek";
import * as jawabali from "../lib/geojson/jawabali";

const filterJabodetabekByString = {
  // province: ['Daerah Khusus Ibukota Jakarta', 'Jawa Barat', 'Banten'],
  city: ['Jakarta', 'Bogor', 'Depok', 'Tangerang', 'Bekasi']
};

class LocationsJabodetabek extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allowed_origin: jabodetabek.default,
      allowed_destination: jawabali.default,

    };
    this.sortable = React.createRef();
  }

  componentDidMount = () => {
    window.$('#sortable').sortable({
      handle: '.drag-handle',
      stop: (event) => {
        let newLocations = [this.props.locations[0]];
        let el;
        // FIXME: black magic
        for(let item of event.target.children) {
          el = this.props.locations.filter(loc => loc.id === Number(item.id))[0];
          newLocations.push(el);
        }
        this.props.handleReplaceLocation(newLocations);
      }
    }).disableSelection();

    /*for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({
          [key]: snap.val(),
        })
      });
    }*/
  };

  removeLocation(locationId) {
    return async () => {
      this.props.handleButton({
        add_drop: true,
      });

      if (this.props.locations.length <= 3) {
        this.props.handleButton({
          remove_drop: false,
          optimize: false,
        });
      }

      await this.props.handleRemoveLocation(locationId);
      gmaps.drawRoute(this.props.locations);

      return this.props.handleRecenter(this.props.locations);
    }
  }

  addLocation() {
    this.props.handleButton({
      remove_drop: true,
      optimize: true,
    });
    if (this.props.locations.length > 4) {
      this.props.handleButton({
        add_drop: false,
      });
    }

    return this.props.handleAddLocation()
  }

  selectLocation(locationId, state, locations) {
    return async (suggestion) => {
      const allowed = await this.props.handleAutocompleteLocation(locationId, state, locations)(suggestion);
      if (allowed) {
        this.props.handleRecenter(this.props.locations);
      }
      gmaps.drawRoute(this.props.locations)
    }
  }

  render() {
    const origin = this.props.locations[0];
    const destinations = this.props.locations.slice(1);
    return (
      <div>
        <div className="alert alert-danger alert-jabodetabek" role="alert">
          Untuk layanan <strong>Order Cepat</strong> saat ini kami hanya melayani rute untuk area <strong>Jabodetabek</strong>
        </div>
        <LocationInput
          onChange={this.props.handleChangeContact(origin.id)}
          onSelect={this.selectLocation(origin.id, this.state, this.props.locations)}
          address={origin.address}
          placeholder='Asal...'
          origin={true}
          filter={filterJabodetabekByString}
        />
        <ul ref={this.sortable} id="sortable">
          {destinations.map((location, idx, array) => (
            <li key={location.id} id={location.id}>
              <LocationInput
                index={idx + 1} // + 1 because destinations start from locations[1]
                onChange={this.props.handleChangeContact(location.id)}
                onSelect={this.selectLocation(location.id, this.state, this.props.locations)}
                address={location.address}
                placeholder={idx === array.length - 1 ? 'Tujuan...' : 'Tambah drop point'}
                origin={false}
                filter={filterJabodetabekByString}
                isLast={idx === array.length - 1}>
                {this.props.button.remove_drop ? removeButton(this, location.id) : null}
              </LocationInput>
            </li>
          ))}
        </ul>
        {this.props.button.add_drop ? addButton(this) : null}
        {this.props.button.optimize ? optimize(this.props) : null}
      </div>
    )
  }
}

const addButton = (p) => (
  <button onClick={() => p.addLocation(p)} className="btn-block btn btn-outline-ritase btn-sm pr-3"
    style={{cursor: 'pointer'}}><i className="icon icon-plus ml-2"/>Tambah Drop
  </button>
);

const adjustDataToOptimize = (locations) => {
  const firstPoint = locations[0];
  return locations.filter((location, key) => {
    return key > 0 && location.address !== firstPoint.address;
  });
}

const optimize = (p) => (
  <button className="btn btn-success btn-sm pr-3 btn-block" onClick={async () => {
    const firstPoint = p.locations[0];
    const lastPoint = p.locations.filter((location, key) => {
      return key > 0 && location.address === firstPoint.address;
    });
    const dataToOptimize = adjustDataToOptimize(p.locations);
    let route = dataToOptimize;
    if (dataToOptimize.length > 1) {
      route = await gmaps.drawRoute(dataToOptimize, true);
    }
    route = [firstPoint, ...route, ...lastPoint];
    p.handleReplaceLocation(route);
    Swal.fire(
      '',
      'Sukses! Rute kamu berhasil dioptimalkan',
      'success'
    )
  }}
  style={{cursor: 'pointer'}}><i className="icon icon-road ml-2"/>Optimasi Rute
  </button>
);

const removeButton = (p, id) => (<i
  onClick={p.removeLocation(id)}
  className="icon-minus-square-o text-danger"
  style={{cursor: 'pointer'}}
/>);

const mapStateToProps = (state) => ({
  locations: state.orders.locations,
  button: state.orders.button
});

const mapDispatchToProps = (dispatch) => ({
  handleButton: (btn) => dispatch({
    type: 'orders.button',
    payload: btn
  }),
  handleSortLocation: (event) => dispatch({type: 'orders.location.reorder', payload: event}),
  handleAddLocation: () => dispatch({type: 'orders.location.add'}),
  handleReplaceLocation: (payload) => dispatch({type: 'orders.location.replace', payload: payload}),
  handleRemoveLocation: (id) => dispatch({type: 'orders.location.remove', payload: id}),
  handleChangeContact: (id) => (address) => {
    gmaps.clearRoute();
    dispatch({
      type: 'orders.location.update',
      payload: {
        id: id,
        address: address,
        latitude: null,
        longitude: null,
        city: null,
        zipcode: null,
      }
    })
  },
  handleAutocompleteLocation: (id, state, locations) => (async (suggestion) => {
    try {
      const results = await geocodeByAddress(suggestion);
      if (results.length < 1)
        throw new Error('no response');
      let result = results[0];

      const allowed_areas = (
        id === locations[0].id
          ? state.allowed_origin
          : state.allowed_destination
      );

      let area = gmaps.lookupArea([
        result.geometry.location.lng(),
        result.geometry.location.lat(),
      ], allowed_areas);

      if (!area) {
        dispatch({
          type: 'orders.location.update',
          payload: {
            id: id,
            address: '',
            isJabodetabek: false,
          }
        });
        return false;
      }

      dispatch({
        type: 'orders.location.update',
        payload: {
          id: id,
          address: suggestion,
          latitude: result.geometry.location.lat(),
          longitude: result.geometry.location.lng(),
          city: gmaps.getCity(results),
          zipcode: gmaps.getZipcode(results),
          area: area,
          isJabodetabek: true,
        },
      });
      return true
    } catch (error) {
      // eslint-disable-next-line no-unused-vars
      Swal.fire('Terjadi Kesalahan', 'Mohon maaf kami tidak dapat memproses pengiriman dari Alamat ini');
      this.props.updateMarker(Number(this.state.startDragging.id), this.state.startDragging.lat, this.state.startDragging.lng);
      gmaps.drawRoute(this.props.locations);
      return false;
    }
  }),
  handleRecenter: (locations) => {
    dispatch({type: 'orders.maps.recenter', payload: gmaps.getBounds(locations)});
  },
});

LocationsJabodetabek.propTypes = {
  button: PropTypes.shape({
    add_drop: PropTypes.bool,
    remove_drop: PropTypes.bool,
    optimize: PropTypes.bool,
  }).isRequired,
  handleButton: PropTypes.func.isRequired,
  handleSortLocation: PropTypes.func.isRequired,
  handleAddLocation: PropTypes.func.isRequired,
  handleRemoveLocation: PropTypes.func.isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleAutocompleteLocation: PropTypes.func.isRequired,
  handleRecenter: PropTypes.func.isRequired,
  handleReplaceLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
    contact: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
      remarks: PropTypes.string,
    }),
  })),
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsJabodetabek);
