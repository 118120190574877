const initialState = {
  center: {
    lat: -6.301900,
    lng: 106.825422
  },
  zoom: 11,
};

function maps(state = initialState, action) {
  switch (action.type) {
  case 'orders.maps.recenter':
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state
  }
}

export default maps;
