import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CardOrder.css';
import utils from '../lib/utils';
import Swal from 'sweetalert2';
import network from '../lib/network';
import * as origins from "../lib/geojson/origins";
import * as destinations from "../lib/geojson/destinations";
import ChoosePayment from './ChoosePayment';
import OrderContactModal from './OrderContactModal';
import gmaps from '../lib/gmaps';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import _ from 'lodash';

class CardOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowed_origin: origins.default,
      allowed_destination: destinations.default,
      eta: null,
      nextDropoff: {},
      showPaymentModal: false,
      showContactModal: false,
      response: null,
    }
    this.paynow = this.paynow.bind(this);
  }

  async paynow() {
    try {
      let modal = _.get(this.props.item, 'retail_quotation.id', false) ? 'contact' : 'payment';
      this.setState({
        showPaymentModal: modal === 'payment',
        showContactModal: modal === 'contact',
        response: {
          trip_id: this.props.item.id,
          price: this.props.item.fee,
        },
      });
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
    }
  }

  async componentDidMount() {
    if (['accepted', 'inprogress'].includes(this.props.item.status)) {
      let truck = await network.GET(`/tracking/public/${this.props.item.id}`);

      if (!_.isNil(truck) && !truck.errorMessage && _.keys(truck).length > 0) {
        const tasks = [...this.props.item.tasks];
        const sortedTasks = tasks.sort((a, b) => a.sequence < b.sequence);
        const dropoffs = sortedTasks.filter(task => task.type_id === '30')
        const nextDropoff = dropoffs.filter(task => task.is_completed === 0)[0];
  
        let eta;
        switch (this.props.item.status) {
        case 'accepted':
          eta = await utils.eta({
            lat: truck.location.coordinates[1],
            lng: truck.location.coordinates[0]
          }, {
            lat: this.props.item.orders[0].origin.latitude,
            lng: this.props.item.orders[0].origin.longitude
          });
          this.setState({
            eta,
          });
          break;
        case 'inprogress':
          eta = await utils.eta({
            lat: truck.location.coordinates[1],
            lng: truck.location.coordinates[0],
          }, {
            lat: Number(nextDropoff.location.latitude),
            lng: Number(nextDropoff.location.longitude),
          });
          this.setState({
            eta,
            nextDropoff,
          });
          break;
        default:
          break;
        }
      }
    }
  }

  getTds(id) {
    let tdsArr = id.split('-');
    tdsArr[2] = tdsArr[2].substr(-6);
    return tdsArr.join('-');
    // return `TDS-${id.split('-')[2].substr(-6)}`
  }

  getAction() {
    const status = utils.statusOrder(this.props.item);
    // if (['booked'].includes(status.name)) {
    //   return <div className='badge status-orange  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 pt-2 pb-2 mb-3' title={status.name}>
    //     <img src="/assets/img/icon/status/booked.svg" style={{ height: '16px' }} className='mr-2'
    //       alt="" />{status.label.toUpperCase()}</div>;
    // } else if (['confirmed'].includes(status.name)) {
    //   return <div className='badge status-orange  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 pt-2 pb-2 mb-3' title={status.name}>
    //     <img src="/assets/img/icon/status/confirmed.svg" style={{ height: '16px' }} className='mr-2'
    //       alt="" />{status.label.toUpperCase()}</div>;
    // } else if (['accepted', 'pickup', 'loading', 'load', 'dropoff', 'unload', 'inprogress'].includes(status.name)) {
    //   return <div className='badge status-blue  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 mb-3' title={status.name}>
    //     <img src="/assets/img/icon/status/accepted.svg" style={{ height: '16px' }} className='mr-2'
    //       alt="" />{status.label.toUpperCase()}</div>;
    // } else 
    if (['done'].includes(status.name)) {
      return <button type='button' className='btn badge-primary btn-sm  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 mb-3' onClick={() => this.reorder()}>
        Pesan Lagi
      </button>;
    } else if (['prebooked', 'approved', 'draft', 'broadcasted'].includes(status.name)) {
      return (
        <button
          type="button"
          disabled={["draft", "broadcasted"].includes(status.name)}
          className="btn badge-primary btn-sm  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 mb-3"
          onClick={() =>
            ["prebooked", "approved"].includes(status.name) && this.paynow()
          }
        >
          <i className="icon icon-payment" />
          Bayar Pesanan
        </button>
      );
    } 
    // } else if (['canceled'].includes(status.name)) {
    //   return <div className='badge status-red  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 mb-3 font-weight-normal'
    //     title={status.name}>
    //     <img src="/assets/img/icon/status/canceled.svg" style={{ height: '16px' }} className='mr-2'
    //       alt="" />{status.label.toUpperCase()}</div>;
    // } else {
    //   return <div className='badge status-gray  r-10 pl-4 pr-4 pt-2 pb-2 pt-2 pb-2 mb-3' title={status.name}>
    //     <img src="/assets/img/icon/status/prebooked.svg" style={{ height: '16px' }} className='mr-2' alt="" />Tidak di
    //     Ketahui</div>;
    // }
  }

  penalty = () => {
    switch (this.props.item.status) {
    case 'prebooked':
      return 0;
    case 'booked':
      return 0;
    case 'confirmed':
      return 0;
    case 'accepted':
      return this.props.item.fee * 0.1;
    case 'pickup':
      return this.props.item.fee * 0.3;
    default:
      return this.props.item.fee * 0.3;
    }
  };

  reorder = async () => {
    const trip = await network.GET(`/trips/${this.props.item.id}`, {
      populate: [
        'orders',
        'orders.origin',
        'orders.origin_contact',
        'orders.destination',
        'orders.destination_contact',
        'surcharge_fees',
      ].join(','),
    }, {}, true);
    const locations = [];
    const origin = trip.orders[0].origin;
    const origin_contact = trip.orders[0].origin_contact;
    let area = gmaps.lookupArea([Number(origin.longitude), Number(origin.latitude)], this.state.allowed_origin);
    locations.push({
      id: 0,
      area: area,
      address: origin.address,
      city: origin.city,
      latitude: Number(origin.latitude),
      longitude: Number(origin.longitude),
      zipcode: origin.zipcode,
      detail: origin.detail,
      name: origin_contact.name,
      phone_number: origin_contact.phone_number,
    });

    trip.orders.map((order, idx) => {
      const { destination, destination_contact } = order;
      let area = gmaps.lookupArea([Number(origin.longitude), Number(origin.latitude)], this.state.allowed_destination);
      locations.push({
        id: idx + 1,
        area: area,
        address: destination.address,
        city: destination.city,
        latitude: Number(destination.latitude),
        longitude: Number(destination.longitude),
        zipcode: destination.zipcode,
        detail: destination.detail,
        name: destination_contact.name,
        phone_number: destination_contact.phone_number,
      });

      return null;
    });

    const { surcharge_fees } = trip;
    const addons = {};
    for (let surcharge of surcharge_fees) {
      const addon = surcharge.surcharge_type.replace('retail_', '');

      //FIXME: magic because of the representation used in db
      switch (addon) {
      case 'promo':
        continue;
      case 'POD':
        addons[addon] = surcharge.description === '1';
        break;
      default:
        addons[addon] = surcharge.description;
        break;
      }
    }

    const { remarks } = trip.orders[0];
    this.props.handleReorder({
      locations,
      /*vehicle_type: trip.vehicle_type_id,*/
      pickup_time: null,
      pickup_type: {
        pType: null,
        fast: 0,
      },
      addons,
      remarks,
    });

    window.location.href = '#/';
  };

  cancelOrder = async () => {
    Swal.fire({
      title: 'Pembatalan Order',
      text: `Pembatalan order akan dikenakan potongan sesuai S&K yang berlaku yaitu sebesar ${utils.formatRupiah(this.penalty())}`,
      input: 'text',
      inputPlaceholder: 'Alasan Pembatalan',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      cancelButtonText: 'Kembali',
      confirmButtonText: 'Batalkan',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage(
            'Alasan Pembatalan Harus diisi'
          );
        } else {
          try {
            await network.POST(`/trips/${this.props.item.id}/cancel`, {
              cancel_reason: reason
            }, {}, true);
          } catch (err) {
            Swal.showValidationMessage(err.message)
          }
        }
        return 'success'
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value === 'success') {
        Swal.fire({
          title: 'Pesanan berhasil dibatalkan',
          showCloseButton: true
        }).then(
          this.props.populate()
        );
      }
    })
  };

  render() {
    const { item, openDetail } = this.props;
    const status = utils.statusOrder(item);
    return (
      <div className="card card-history-item card-sm mb-4">
        <div className="card-header clearfix">
          <strong>{this.getTds(item.id)}</strong>
          <strong className="float-right">
            {new Date(item.created_at).toDateString()}
          </strong>
        </div>
        <div className="card-body grid">
          <div className="item type">
            {_.get(item, 'vehicle_type.name') &&
              <div className="mb-4 list">
                <img src="assets/img/icon/land.svg" alt="Kendaraan" />
                <div className="">
                  <label>Kendaraan</label>
                  <strong>{_.get(item, 'vehicle_type.name', '')}</strong>
                </div>
              </div>
            }
            <div className="mb-4 list">
              <img
                src="assets/img/icon/calendar.svg"
                alt="Jadwal Penjemputan"
              />
              <div className="">
                <label>Jadwal Penjemputan</label>
                <strong>
                  {item.ready_time
                    ? `${new Date(item.ready_time).toDateString()} ${format(
                      new Date(item.ready_time),
                      "HH:mm"
                    )}`
                    : "-"}
                </strong>
              </div>
            </div>
            <div className="mb-4 list">
              <img src="assets/img/icon/package.svg" alt="Muatan" />
              <div className="">
                <label>Muatan</label>
                <strong>{item.remarks || "-"}</strong>
              </div>
            </div>
          </div>
          <div className="item location" style={{ flexGrow: 2 }}>
            <ul className="list-group location">
              <li className="list-group-item">
                <label>Origin</label>
                <strong>
                  {_.get(item, 'orders[0].origin_contact_name')} - {_.get(item, 'orders[0].origin_contact_number')}
                </strong>
                <label className="ellipsis">
                  {_.get(item, 'orders[0].origin.address')}
                </label>
              </li>
              <li className="list-group-item">
                <label>Destination</label>
                <strong>
                  {_.get(item, 'orders[0].destination_contact_name')} - {_.get(item, 'orders[0].destination_contact_number')}
                </strong>
                <label className="ellipsis">
                  {
                    _.get(item, 'orders[item.orders.length - 1]["destination"]["address"]')
                  }
                </label>
                {item.orders && item.orders.length > 1 && (
                  <label>
                    <small>
                      <i className="icon icon-circle text-primary pr-1"></i>{" "}
                      Terdapat {item.orders.length - 1} destinasi
                    </small>
                  </label>
                )}
              </li>
            </ul>
          </div>
          <div className="item status" style={{ flexGrow: 1.5 }}>
            <label>Status:</label>
            <strong style={{ textTransform: "capitalize" }}>
              {status.label}
            </strong>
            <hr />
            <div className="d-flex justify-content-between">
              {_.get(item, 'retail_quotation.is_negotiated') ? (
                <React.Fragment>
                  <div>
                    <label>Harga Penawaran:</label>
                    <strong>{utils.formatRupiah(item.retail_quotation.shipper_price.previous_price.price)}</strong>
                  </div>
                  <div>
                    <label>Harga yang disetujui:</label>
                    <strong className="price">
                      {utils.formatRupiah(
                        item.fee
                      )}
                    </strong>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <label>Total Biaya:</label>
                  <strong className="price">
                    {utils.formatRupiah(item.fee)}
                  </strong>
                </div>
              )}
            </div>
          </div>
          <div className="item action">{this.getAction()}</div>
        </div>
        <div className="card-footer">
          <button className="btn btn-link text-ritase" onClick={() => openDetail({ cancelOrder: this.cancelOrder, payOrder: this.paynow })}>
            <img src="assets/img/watch.svg" alt="Detail" /> Lihat Detail
          </button>
          {['prebooked', 'broadcasted', 'approved'].includes(status.name) && <span className="mr-1 ml-1">|</span>}
          {['prebooked', 'broadcasted', 'approved'].includes(status.name) && <button
            className="btn btn-link"
            style={{ border: 0 }}
            onClick={this.cancelOrder}
          >
            <img src="assets/img/reject-copy.svg" alt="Tolak" /> Tolak Penawaran
          </button>}
          {this.state.showPaymentModal &&
            <ChoosePayment
              open={this.state.showPaymentModal}
              response={this.state.response}
              closeModal={() => {
                this.setState({ showPaymentModal: false });
              }}
              onFinish={() => window.location.reload()}
            />
          }

          {this.state.showContactModal &&
            <OrderContactModal
              open={this.state.showContactModal}
              item={item}
              closeModal={() => this.setState({ showContactModal: false })}
              continueToPayment={() => {
                this.setState({ 
                  showPaymentModal: true,
                  showContactModal: false
                })
              }}
              onFinish={() => window.location.reload()}
            />
          }
        </div>
      </div>
    );
  }
}

CardOrder.propTypes = {
  item: PropTypes.object,
  populate: PropTypes.func.isRequired,
  handleReorder: PropTypes.func.isRequired,
  openDetail: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleReorder: (payload) => dispatch({ type: 'orders.reorder', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardOrder);