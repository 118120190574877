import {addHours, addDays, addMinutes} from 'date-fns';
import orders from './orders';
import _ from 'lodash';

const utils = {};

/**
 * format rupiah with dot thousand separator, e.g. "Rp 1.000.000"
 */
utils.formatRupiah = function (x) {
  x = Math.floor(x);  // remove decimal
  // use dot as thousand separator
  // regex from here: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  let formatted = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return "Rp " + formatted;
};

utils.distance = function (src_lat, src_lng, dst_lat, dst_lng) {
  if ((src_lat === dst_lat) && (src_lng === dst_lng)) {
    return 0;
  }
  let src_rad = Math.PI * src_lat / 180;
  let dst_rad = Math.PI * dst_lat / 180;
  let theta_rad = Math.PI * (src_lng - dst_lng) / 180;
  let dist = Math.sin(src_rad) * Math.sin(dst_rad) + Math.cos(src_rad) * Math.cos(dst_rad) * Math.cos(theta_rad);
  if (dist > 1) {
    dist = 1;
  }
  return Math.acos(dist) * 180 / Math.PI * 60 * 1.1515 * 1.609344;
};

utils.eta = async function (ori, dest) {
  /*global google*/
  let origin = new google.maps.LatLng(ori.lat, ori.lng);
  let destination = new google.maps.LatLng(dest.lat, dest.lng);
  let service = new google.maps.DistanceMatrixService();

  /*global Promise*/
  return new Promise((resolve) => service.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.IMPERIAL,
    }, (response, status) => {
      resolve(response.rows[0].elements[0].status === "OK" ? response.rows[0].elements[0].duration.text : false);
    }));
};

utils.isValidEmail = function (email) {
  const filter = /\S[0-9a-zA-Z/._-]+@\S{1,}\.\S{2,}/;
  return filter.test(email);
};

utils.isValidPhoneNumber = function (phoneNumber) {
  const filter = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im;
  const isValid = filter.test(phoneNumber);
  return isValid;
};

utils.checkPickupTime = (pickupTime) => {
  return ((pickupTime.getUTCHours() < 0)
    || (pickupTime.getUTCHours() > 13)
    || ((pickupTime.getUTCHours() === 13) && (pickupTime.getUTCMinutes() > 0))
    || ((pickupTime.getUTCHours() === 13) && (pickupTime.getUTCMinutes() === 0) && (pickupTime.getUTCSeconds() > 0)))
};

const validateFirstStep = (order) => {

  if (!order.vehicle_type)
    throw new Error('Pastikan kamu sudah memilih tipe kendaraan');
  else {
    let origin = orders.origin(order.locations);
    let destination = orders.destination(order.locations);
    if (origin && destination) {
      if (!(order.vehicle_area
        && order.vehicle_area.routes
        && order.vehicle_area.routes[origin]
        && order.vehicle_area.routes[origin].includes(destination)))
        throw new Error('Pastikan kamu sudah memilih ulang tipe kendaraan');
    }
  }

  if (!order.pickup_type.pType)
    throw new Error('Silakan pilih jenis penjemputan');

  if (order.isNPCT) {
    if (!order.delivery_type)
      throw new Error('Silakan pilih tipe pengiriman');

    if (order.reference_no || order.gatepass_no) {
      // make reference_no or gatepass_no required if ones of this field is filled (default to not required)
      if (!order.reference_no)
        throw new Error('Silakan isi reference number');

      if (!order.gatepass_no)
        throw new Error('Silakan isi nomor GatePass');
    }

    if (order.is_depo && !order.depo)
      throw new Error('Silakan pilih depo');
  }

  if (order.pickup_type.pType === 'SCHEDULED') {
    const pickupTime = new Date(order.pickup_time);
    const tomorrow = addDays((new Date()).setHours(0, 0, 0), 1);
    const today = (new Date());
    /*check is today ?*/
    if (pickupTime.toDateString() === today.toDateString()) {
      if (pickupTime < addHours(new Date(), order.scheduledPickupTime || 6))
        throw new Error('Waktu penjemputan terjadwal minimal ' + (order.scheduledPickupTime || 6) + ' jam dari sekarang');

      if (utils.checkPickupTime(pickupTime))
        throw new Error('Silakan pilih waktu dari pukul 7:00 sampai pukul 20:00');
    }

    if (pickupTime.toDateString() === tomorrow.toDateString()) {
      if (pickupTime < addMinutes(addHours(tomorrow, 7), -1))
        throw new Error('Waktu penjemputan terjadwal besok minimal jam 7:00 pagi');
    }

  } else {
    if (order.pickup_type.fast !== 2)
      throw new Error('Anda Belum memilih Waktu Penjemputan');

    const pickupTime = addHours(new Date(), 6);
    if (utils.checkPickupTime(pickupTime))
      throw new Error('EXPRESS hanya dapat dipesan mulai pukul 01.00 sampai 14.00.');
  }

  if (new Date(order.pickupTime) > addHours(new Date(), 168))
    throw new Error('Waktu penjemputan maksimal adalah 7 hari');

  for (let location of order.locations) {
    if (location.address === '')
      throw new Error('Pastikan kamu sudah mengisi seluruh alamat');
    if (!location.latitude || !location.longitude)
      throw new Error('Silahkan isi alamat dengan benar.');
  }

  if (order.locations.length < 2)
    throw new Error('Lokasi penjemputan dan pengiriman belum dipilih');

  if (order.locations.length > 11)
    throw new Error('Terlalu banyak lokasi pengiriman');

  return true;
};

const validateSecondStep = (order) => {
  const someFieldsEmpty = order.locations.some((location) => !(location.name && location.phone_number));

  if (someFieldsEmpty)
    throw new Error('Kamu belum melengkapi data kontak!');

  return true;
};

const validateThirdStep = () => {
  return true;
};

const orderValidators = [
  validateFirstStep,
  validateSecondStep,
  validateThirdStep,
];

utils.validateOrder = (order, step) => {
  let currentStep = 0;
  for (let validator of orderValidators) {
    if (currentStep > step)
      break;

    validator(order);
    currentStep++;
  }
  return true;
};

utils.validateNegotiation = (order, step) => {
  let currentStep = 0;
  const validators = [
    validateFirstStep,
    validateThirdStep,
  ];
  for (let validator of validators) {
    if (currentStep > step)
      break;

    validator(order);
    currentStep++;
  }
  return true;
};

const validateFirstStepApk = (order) => {
  if (!order.smuno && !_.get(order, 'apk.smuno'))
    throw new Error('Silakan isi Nomor SMU');

  for (let location of order.locations) {
    if (location.address === '')
      throw new Error('Pastikan kamu sudah mengisi seluruh alamat');
    if (!location.latitude || !location.longitude)
      throw new Error('Silahkan isi alamat dengan benar.');
  }

  if (order.locations.length < 2)
    throw new Error('Lokasi penjemputan dan pengiriman belum dipilih');
  // console.log("........", order.locations)
  if (_.get(order, 'locations[0].area', '') !== _.get(order, 'locations[1].area', ''))
    throw new Error('Lokasi penjemputan dan pengiriman harus dalam satu pulau');

  if (order.locations.length > 11)
    throw new Error('Terlalu banyak lokasi pengiriman');

  return true;
};

const orderApkValidators = [
  validateFirstStepApk,
  validateSecondStep,
  validateThirdStep,
];

utils.validateApkOrder = (order, step) => {
  let currentStep = 0;
  for (let validator of orderApkValidators) {
    if (currentStep > step)
      break;

    validator(order);
    currentStep++;
  }
  return true;
};

utils.getLastGoodStep = (order) => {
  let lastGoodStep = -1;

  try {
    for (let validator of orderValidators) {
      validator(order);
      lastGoodStep++;
    }
  } catch (err) {
    return lastGoodStep;
  }

  return lastGoodStep;
};

utils.getLastGoodStepApk = (order) => {
  let lastGoodStep = -1;

  try {
    for (let validator of orderApkValidators) {
      validator(order);
      lastGoodStep++;
    }
  } catch (err) {
    return lastGoodStep;
  }

  return lastGoodStep;
};

utils.scf = (text) => {
  return text.split(',')[0]
};

// translate url params to object
utils.getParams = (string) => string.charAt(0) === '?' ? new URLSearchParams(string.substr(1)) : new URLSearchParams(string);

utils.titleCase = (str) => {
  if (!str) return "";
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

utils.statusOrder = item => {
  if ((item.status && _.lowerCase(item.status) === "prebooked") && item.retail_quotation) {
    if (["draft", "broadcasted"].includes(item.retail_quotation.status)) {
      return {
        name: item.retail_quotation.status,
        label: "Negosiasi",
      };
    }
    if (["approved"].includes(item.retail_quotation.status)) {
      return {
        name: item.retail_quotation.status,
        label: "Negosiasi disetujui",
      };
    }
    if (["canceled"].includes(item.retail_quotation.status)) {
      return {
        name: item.retail_quotation.status,
        label: "Negosiasi ditolak",
      };
    }
  }
  return {
    name: item.status,
    // label: utils.titleCase(item.status),
    label: _.startCase(item.status),
  };
}

const isProduction = !window.location.host.includes('local') && !window.location.host.includes('stg');
utils.npct_vehicles_id = isProduction ? [76, 86] : [7, 8];
utils.ltl_vehicles_id = isProduction ? [100] : [21];


export default utils;
