const initialState = {
  branch: '',
  smuno: '',
  ba_date: new Date(),
  fee: {
    paymentItems: [],
    price: ''
  },
  vehicle_type: {},
  details: []
};

function apk(state = initialState, action) {
  if (action.type === 'orders.branch') {
    return {
      ...state,
      branch: action.payload,
    };
  } else if (action.type === 'orders.smuno') {
    return {
      ...state,
      smuno: action.payload,
    };
  } else if (action.type === 'orders.ba_date') {
    return {
      ...state,
      ba_date: action.payload,
    };
  } else if (action.type === 'orders.apk.fee') {
    return {
      ...state,
      fee: action.payload,
    };
  } else if (action.type === 'orders.apk.vehicle_type') {
    return {
      ...state,
      vehicle_type: action.payload,
    };
  } else if (action.type === 'orders.apk.details') {
    return {
      ...state,
      details: action.payload,
    };
  } else {
    return state
  }
}

export default apk;