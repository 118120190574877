import React from 'react';
import { Redirect } from "react-router-dom";
import { GUEST, LOGGED } from "../config";


const auth = {};

auth.check = () => {
  return !!localStorage.getItem('token');
};

auth.set = (token) => {
  return localStorage.setItem('token', token);
};

auth.get = () => {
  return localStorage.getItem('token');
};

auth.checkRoute = (route) => (props) => {
  if (
    route.when === undefined ||
    route.when === null ||
    (auth.check() === false && route.when === GUEST) ||
    (auth.check() === true && route.when === LOGGED)
  ) {
    return <route.component {...props} />;
  } else if (auth.check() === true && route.when === GUEST) {
    return <Redirect to="/dashboard" />;
  } else {
    return <Redirect to={`/login?next=${props.location.pathname}${props.location.search}`} />;
  }
}

export default auth;