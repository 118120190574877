import bca from './bca';
import midtrans from './midtrans';

const paymentMethods = [
  {
    id: 'midtrans',
    name: 'Midtrans',
    description: 'Bayar dengan Midtrans',
    logo: '/assets/img/logo/midtrans.png',
    handler: midtrans,
    enabled: true,
  },
  {
    id: 'bca',
    name: 'BCA Virtual Account',
    description: 'Bayar dengan BCA Virtual Account',
    logo: '/assets/img/logo/bca.png',
    handler: bca,
    enabled: true,
  },
  //{
  //  id: 'dana',
  //  name: 'DANA',
  //  description: 'Bayar pakai DANA',
  //  handler: dana,
  //},
]

export {bca, midtrans, paymentMethods}
