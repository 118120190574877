import NegotiationRoute from "../components/wizard/NegotiationRoutes";
import NegotiationSubmit from "../components/wizard/NegotiationSubmit";

const routes = [
  {
    path: '/dashboard/negotiation/route',
    component: NegotiationRoute
  },
  {
    path: '/dashboard/negotiation/submit',
    component: NegotiationSubmit
  },
];

export default routes;