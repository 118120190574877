const initialState = {
  pType: null,
  fast: 0
};

function pickup_type(state = initialState, action) {
  if (action.type === 'orders.pickup_type') {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state
  }
}

export default pickup_type;
