import React from 'react'
import auth from '../lib/auth';
import './Navbar.css'

export default class Navbar extends React.Component {

  render() {
    return (
      <div className="has-sidebar-left">
        <div className="pos-f-t">
          <div className="collapse" id="navbarToggleExternalContent">
          </div>
        </div>
        <div className="top">
          <div
            className="navbar navbar-expand navbar-dark d-flex justify-content-between bd-navbar ritase accent-3">
            <div className="relative">
              <a href="#/" data-toggle="offcanvas" className="paper-nav-toggle pp-nav-toggle">
                <i></i>
              </a>
            </div>

            <img src="/assets/img/icon/ritase-logo-sm.png" alt="" className="logo-nav hidden-md hidden-lg hidden-sm" style={{maxHeight: '50px'}}/>
            <img src="/assets/img/icon/ritase-logo.png" alt="" className="logo-nav hidden-xs" style={{maxHeight: '50px'}}/>

            {
              auth.check()
                ? null
                :
                <div className="row" style={{position: 'absolute', right: '20px'}}>
                  <a href='/#/login' className='btn bg-white text-ritase btn-login btn-sm mr-2 pl-4 pr-4 r-5'>Masuk</a>
                  <a href='/#/register' className='btn bg-ritase btn-register btn-outline-light btn-sm pl-4 pr-4 r-5 text-white mr-2'>Daftar</a>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
