export const DB_CONFIG = {
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
};

export const baseURL = process.env.REACT_APP_BACKEND_URL;

export const gmapsKey = process.env.REACT_APP_GMAPS_KEY;

export const captchaSiteKey = process.env.REACT_APP_SITE_CAPTCHA_KEY;

export const BCAVACompanyCode = process.env.REACT_APP_BCA_VA_COMPANY_CODE;

export const LOGGED = 'LOGGED';
export const GUEST = 'GUEST';
