const initialState = false;

function depo(state = initialState, action) {
  if (action.type === 'orders.is_depo') {
    return action.payload;
  } else {
    return state
  }
}

export default depo;