import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../../spinner/Pulse";
import _ from 'lodash';

class Step1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      check: {}
    };
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleDateChange = (type, value) => {
    this.setState({
      [type]: value
    }, this.check);
  };

  check() {
    this.setState({
      check: {}
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      this.props.setStep(2);
      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  render() {
    const { ritaseTheme, step0 } = this.props;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Data Kapal</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="voyage">
                    Kapal / Voyage
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.voyage ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.vesselName', '') + ' / ' +  _.get(step0, 'orders.voyageOut', '') }
                      disabled
                      id="voyage"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="agent">
                    Agent
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.agent ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.agent', '').replace('|', ' - ') }
                      disabled
                      id="agent"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="spod">
                    Pelabuhan Transit
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.spod ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.pod', '').replace('|', ' - ') }
                      disabled
                      id="spod"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="pod_code">
                    Pelabuhan Tujuan
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.pod_code ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.pod', '').split('|')[0] }
                      disabled
                      id="pod_code"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="pod_val">
                    &nbsp;
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.pod_val ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.pod', '').split('|')[1] }
                      disabled
                      id="pod_val"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="eta">
                    ETA
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.eta ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.eta', '') }
                      disabled
                      id="eta"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="etd">
                    ETD
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.etd ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.etd', '') }
                      disabled
                      id="etd"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="yot">
                    YOT
                  </label>
                  <div className="form-group">
                    <input type="text"
                      className={this.state.validate && !this.state.check.yot ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.yot', '') }
                      disabled
                      id="yot"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="yct">
                    YCT
                  </label>
                  <div className="form-group">
                    <input type="text"
                      className={this.state.validate && !this.state.check.yct ? "form-control is-invalid" : "form-control"}
                      value={ _.get(step0, 'orders.yct', '') }
                      disabled
                      id="yct"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
          </div>

          
          </div>
          <div className="row mt-5 mb-3">
            <div className="col-sm-6">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => this.props.setStep(0)}>{this.state.loading ? <Pulse/> : 'Sebelumnya'}</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={() => {
                this.setState({
                  validate: true
                })
              }}>{this.state.loading ? <Pulse/> : 'Selanjutnya'}</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.export_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setExportGatepass: (payload) => dispatch({type: 'orders.export_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
