import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import auth from '../../lib/auth';
import network from '../../lib/network';
import utils from '../../lib/utils';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

class Invoice extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      item: [],
      listItem: null
    }
  }

  componentDidMount = async () => {
    if (auth.check()) {
      var proformaNo = decodeURIComponent(this.props.match.params.proformaNo);
      const item = await network.POST('/econ/invoice', { proformaNumber: proformaNo }, {}, true);
      
      if (item.errorMessage || parseInt(item.status, 10) !== 200) {
        let errorMessage = item && (item.errorMessage || item.messages);
        if (!errorMessage) errorMessage = '';
        await Swal.fire({
          type: 'error',
          text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
        });
      } else if (_.get(item, 'collections.header.invoiceDate', false)) {
        item.collections.header.invoiceDate = new Date(item.collections.header.invoiceDate);
      }
      
      const list = await network.GET('/econ/list', { proforma_number: proformaNo }, {}, true);
      
      var listItem = null;
      if (_.get(list, 'rows.length', 0) > 0) {
        listItem = list.rows[0];
        if (listItem.invoice_due_date) listItem.invoice_due_date = new Date(listItem.invoice_due_date);
      }

      this.setState({
        item, listItem
      })
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  }

  render() {
    const { item, listItem } = this.state;

    return (
      <Fragment>
        <Navbar/>
        <Sidebar/>
        <Content>
          <div id="primary" className="p-t-b-50 height-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mx-md-auto">
                  <div className="row" id="header-logo">
                    <div className="col-md-12">
                      <img src="/assets/img/logo-npct1.png" alt="npct1" />
                    </div>
                  </div>
                  <div className="row mt-3" id="header-text">
                    <div className="col-md-6">
                      <div>PT NEW PRIOK CONTAINER TERMINAL ONE</div>
                      <div>Jl. Terminal Kalibaru Raya Kav B No. 1</div>
                      <div>Kel. Kalibaru Kec. Cilincing</div>
                      <div>Jakarta Utara DKI Jakarta 14110</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div>{ _.get(item, 'collections.header.customerName', '') }</div>
                      <div>{ _.get(listItem, 'company_address', '') }</div>
                      <div>ID Pelanggan, { _.get(item, 'collections.header.customerId', '') }</div>
                    </div>
                  </div>
                  <div className="row mt-3" id="header-date">
                    <div className="col-md-6">
                      <div>Invoice</div>
                      <div>{ _.get(item, 'collections.header.invoiceDate', false) && format(new Date(item.collections.header.invoiceDate), 'dd MMMM yyyy') }</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <span>Status : { _.upperCase(_.get(item, 'collections.header.paymentStatus', '')) }</span>
                      {_.get(item, 'collections.header.paymentStatus', '').toLowerCase() === 'paid' &&
                        <div>
                          <div>
                            <Link to={`/npct1/gatepass/${_.get(listItem, 'reference_number', '')}`}>
                              <i className="icon-ticket"></i>&nbsp; Gate Pass
                            </Link>
                          </div>
                          <div>
                            <a href={_.get(item, 'collections.link', '')} target="blank">
                              <i className="icon-download" style={{background: 'none'}}></i>&nbsp; Download Invoice
                            </a>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2">
                      <div>Invoice #</div>
                      <div>{ _.get(item, 'collections.header.invoiceNo', '') }</div>
                    </div>
                    <div className="col-md-10">
                      <table className="table bg-white black-text">
                        <thead className="bg-ritase white-text">
                          <tr>
                            <th>Deskripsi</th>
                            <th>Jumlah</th>
                            <th>UOM</th>
                            <th>Tarif</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        {_.get(item, 'collections.detail', []).map((dt, i) => (
                          <tbody key={i}>
                            <tr>
                              <td colSpan={5} className="text-left">{ dt.description }</td>
                            </tr>
                            <tr>
                              <td className="text-left">{dt.item}</td>
                              <td>{ dt.qty ? Number(dt.qty) : 0 }</td>
                              <td>{ dt.uom }</td>
                              <td>{ utils.formatRupiah(dt.tarif) }</td>
                              <td>{ utils.formatRupiah(dt.total) }</td>
                            </tr>
                          </tbody>
                        ))}
                        <tbody>
                          <tr>
                            <td colSpan={4} className="text-left">Biaya Administrasi</td>
                            <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.adminCharges', '')) }</td>
                          </tr>
                          <tr>
                            <td colSpan={4} className="text-left">Dasar Pengenaan Pajak</td>
                            <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.basicTax', '')) }</td>
                          </tr>
                          <tr>
                            <td colSpan={4} className="text-left">Jumlah PPN</td>
                            <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.tax', '')) }</td>
                          </tr>
                          <tr>
                            <td colSpan={4} className="text-left">Bea Materai Lunas</td>
                            <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.stamp', '')) }</td>
                          </tr>
                          <tr className="grey">
                            <td colSpan={4} className="text-left">Total Jumlah Bayar</td>
                            <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.totalPayment', '')) }</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <span><img src="/assets/img/logo-npct1.png" width="16" height="16" alt="npct1" /> &nbsp;PT NEW PRIOK CONTAINER TERMINAL ONE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

export default Invoice;