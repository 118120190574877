import React, {Component} from 'react'
import PropTypes from "prop-types";

export default class Select extends Component {
  render() {
    const props = this.props;
    return (
      <li className="list-group-item" key={props.name}>
        <div className="row">
          <div className="col-8">
            <div style={{lineHeight: 'normal'}}>{props.label} <i
              className="icon icon-info2 ml-2 s-18 cur-help"
              onMouseDown={this.props.handleTooltip.bind(this, props)}/>
            </div>
            <div className="align-top">
              <span style={{fontSize: '10px'}} className="mt-0 text-success align-top">{props.note}</span>
            </div>
          </div>
          <div className="material-switch col-4">
            <select className="form-control form-control-sm" name={this.props.name} onChange={this.props.handleChange}>
              {props.option.map(item => (
                <option key={item.id} value={item.id} selected={item.id === this.props.selected}>{item.option}</option>
              ))}
            </select>
          </div>
        </div>
      </li>
    )
  }
}

Select.propTypes = {
  label: PropTypes.string,
  note: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.string.isRequired,
  option: PropTypes.array,
  description: PropTypes.string.isRequired,
  handleTooltip: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};
