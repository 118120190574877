import NPCTRoute from "../components/wizard/NPCTRoute";
import NPCTSubmit from "../components/wizard/NPCTSubmit";

const routes = [
  {
    path: '/dashboard/npct1/route',
    component: NPCTRoute
  },
  {
    path: '/dashboard/npct1/submit',
    component: NPCTSubmit
  },
];

export default routes;