import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'
import './Routes.css'

import LocationsApk from '../LocationsApk'

import firebase from 'firebase/app'
import {DB_CONFIG} from "../../config";

import Swal from 'sweetalert2'
import { format, parse } from 'date-fns';
import DateFnsUtils from "@date-io/date-fns";
import idLocale from "date-fns/locale/id";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Pulse from "../spinner/Pulse";
import auth from '../../lib/auth';
import utils from '../../lib/utils';
import network from '../../lib/network';
import gmaps from '../../lib/gmaps';

let fb = firebase;

class APKRoute extends Component {
  constructor(props) {
    super(props);

    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }
    this.database = {
      rates: fb.database().ref('/rates'),
    };

    this.state = {
      rates: [],
      ports: [],
      isLoadingPorts: true,
    }
  }
  
  getPort = async (params) => {
    try {
      const response = await network.GET('/ports', {
        format: 'airplane',
        is_apk: true,
      }, {}, auth.check());

      if (response && response.errorMessage) {
        throw response
      } else {
        let rows = response.rows;
        _.each(rows, item => {
          item.value = item.code;
          item.label = item.name;
        })
        if (params && params.get('branch')) {
          let selectedBranch = _.find(rows, item => _.lowerCase(item.code) === _.lowerCase(params.get('branch')));
          if (selectedBranch) {
            this.onOriginChange(selectedBranch)
          }
        }

        let areas = _.map(rows, item => _.get(item, 'location.address'))
        if(!_.includes(areas, _.get(this.props, 'locations[0].address', ''))) {
          this.props.updateOrigin(0, null, null, '', '', '', '', '', null);
        }
        this.setState({
          ports: rows,
          isLoadingPorts: false
        })
      }
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({
        isLoadingPorts: false,
      });
      this.props.updateOrigin(0, null, null, '', '', '', '', '', null);
    }
  }

  processResponse = (response, callback) => {
    if (response.errorMessage) {
      Swal.fire('', _.get(response, 'errorMessage', 'Maaf, kami tidak dapat membuat order anda untuk saat ini. Mohon coba kembali dalam beberapa saat.').split('\n')[0], 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }

    if (!response.valid && response.status !== 200) {
      Swal.fire('', 'Order anda tidak valid. Mohon pastikan anda telah mengisi detail dengan benar.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    
    if (callback) {
      callback(response);
    } else {
      let kargoPrice = _.find(response.paymentItems, item => item.type === 'angkasa_pura_kargo');
      let basePrice = _.find(response.paymentItems, item => item.type === 'base');
      let distancePrice = _.find(response.paymentItems, item => item.type === 'distance_and_weight');
      let deliveryPrice = {
        type: 'delivery',
        price: _.get(basePrice, 'price', 0) + _.get(distancePrice, 'price', 0)
      };
      let tkbmPrice = {
        type: 'tkbm',
        price: _.reduce(response.paymentItems, (result, item) => {
          if (item.type === 'koli') {
            return result + item.price
          }
          return result
        }, 0)
      }
      let paymentItems = [
        deliveryPrice, kargoPrice, tkbmPrice
      ];
  
      this.props.setPaymentItems({
        price: response.price,
        paymentItems
      })
      this.setState({
        loading: false
      })
    }
  };

  checkPrice = async (callback) => {
    this.setState({loading: true});
    let error = false;

    try {
      utils.validateApkOrder({
        locations: this.props.orders.locations,
        smuno: this.props.smuno,
        ba_date: this.props.ba_date
      }, 0);
    } catch (e) {
      error = true;
      Swal.fire({
        type: 'error',
        text: e.message,
      })
    }

    if (!error) {
      let locations = this.props.orders.locations;
      let post_locations = [];
      let origin = {...locations[0]};
      let destination = {...locations[1]};

      post_locations.push({
        id: 0,
        address: origin.address,
        name: origin.label,
        detail: origin.label,
        phone_number: '0813371337',
        area: '',
        remarks: '',
        latitude: origin.latitude,
        longitude: origin.longitude,
        city: origin.city,
        zipcode: origin.zipcode,
        port: {
          id: _.get(origin, 'port.id'),
          code: _.get(origin, 'port.code'),
          name: origin.label,
          location: {
            latitude: origin.latitude,
            longitude: origin.longitude
          }
        }
      });
      
      post_locations.push({
        ...destination,
        detail: '',
        name: destination.address,
        phone_number: '0813371337',
      });
  
      const body = {
        locations: post_locations,
        smuno: this.props.smuno,
        ba_date: this.props.ba_date,
        addons: {
          POD: false,
          driverhelp: false,
          helper: false
        },
        pickupType: 'CONSOLIDATION',
        vehicleType: this.props.ltlVehicleType.id,
        is_apk: 1,
      };
      
      const endpoint = callback ? '/orders/retail/cargo_detail' : '/orders/retail/check_fee';

      try {
        const response = await network.POST(endpoint, body, {}, auth.check());
        this.processResponse(response, callback);
        return;
      } catch (err) {
        Swal.fire({
          type: 'error',
          text: err.message,
        });
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false
      })
    }
  };

  getVehicleType = async () => {
    try {
      const response = await network.GET('/vehicle_type', {}, {}, auth.check());

      if (response && response.errorMessage) {
        throw response
      } else {
        let ltlVehicleType = _.find(response.rows, item => item.code === 'LTL');
        
        if (ltlVehicleType) {
          this.props.setVehicleType(ltlVehicleType)
        }
      }
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
    }
  }

  componentDidMount() {
    this.props.scrollToTop();
    this.props.setStep();
    for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({[key]: snap.val()});
      });
    }
    this.props.handleButton({
      add_drop: false,
    });
    
    if (auth.check()) {
      this.getVehicleType();
      if (this.props.location.search) {
        const params = utils.getParams(this.props.location.search);
        this.getPort(params);
      
        if (params.get('smuno')) {
          this.props.setSmuNo(params.get('smuno'))
        }
        
        if (params.get('date')) {
          let date = parse(params.get('date'), 'yyyy-MM-dd', new Date())
          this.props.setBaDate(new Date(date))
        }
      } else {
        this.getPort();
      }
    } else {
      Swal.fire({
        type: "error",
        text: "Silakan login terlebih dahulu.",
      });
      window.location.href = `#/login?next=${encodeURIComponent(window.location.hash.substr(1))}`;
      return;
    }
  }

  componentWillUnmount() {
    this.props.handleButton({
      add_drop: true,
    });
  }

  onOriginChange = (selectedOption) => {
    if (selectedOption) {
      const { latitude, longitude, zipcode, city, address } = selectedOption.location;
      const area = selectedOption.region;
      const { label, id, code, name } = selectedOption;
      const port = {
        id,
        code,
        name,
        location: {
          latitude: _.toNumber(latitude),
          longitude: _.toNumber(longitude)
        }
      }
      this.props.updateOrigin(0, _.toNumber(latitude), _.toNumber(longitude), address, zipcode, area, city, label, port).then(() => {
        this.props.handleRecenter(this.props.locations);
        gmaps.drawRoute(this.props.locations);
      });
    }
  };

  render() {
    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        error: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#27A397',
        },
      },
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#27A397',
          },
        },
        MuiPickersDay: {
          day: {
            color: '#2d2d2d',
          },
          daySelected: {
            backgroundColor: '#27A397',
          },
          dayDisabled: {
            color: '#d4d4d4',
          },
          current: {
            color: '#f24d07',
          },
        },
      },
    });

    const datePicker = (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <ThemeProvider theme={ritaseTheme}>
        <DatePicker
          className='form-control bg-white'
          theme={ritaseTheme}
          format="dd MMM yyyy"
          onChange={this.props.setBaDate}
          value={new Date(this.props.ba_date)}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>);

    const paymentItemsDict = {
      'delivery': 'Jasa Kirim',
      'angkasa_pura_kargo': 'Jasa Kargo',
      'tkbm': 'Jasa TKBM'
    }
    return (
      <div id="info">
        <h6 className="mb-2"><b>Nomor SMU</b></h6>
        <input
          type="number"
          placeholder="Nomor SMU"
          className="mb-4 s-14 form-control"
          value={this.props.smuno}
          onChange={(e) => this.props.setSmuNo(e.target.value)}
        />
        
        <h6><b>BA Date</b></h6>
        <div className="row">
          <div className="pl-3 input-group pt-2 pb-2 col-12 pl-0 input-scheduled">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="icon icon-calendar" />
              </span>
            </div>
            {datePicker}
          </div>
        </div>

        <h6 className="mb-2 mt-4"><b>Asal dan Tujuan</b></h6>
        <div className="bg-white">
          <LocationsApk 
            ports={this.state.ports}
            isLoadingPorts={this.state.isLoadingPorts}
            onOriginChange={(selected) => this.onOriginChange(selected)}
          />
        </div>

        {this.props.fee.paymentItems.length > 0 &&
          <div>
            <h6 className="mb-2 mt-4"><b>Kargo Detail</b></h6>
            <div>
              {this.props.fee.paymentItems.map((item, i) => {
                if (item.price <= 0) return <div></div>;
                return (
                  <div key={i} className="d-flex justify-content-between mb-1">
                    <div>{ paymentItemsDict[item.type] }</div>
                    <div className="text-right">{utils.formatRupiah(item.price)}</div>
                  </div>
                )
              })}
              <hr className="my-2" />
              <div className="d-flex justify-content-between font-weight-bold">
                <div>Total</div>
                <div className="text-right">{utils.formatRupiah(this.props.fee.price)}</div>
              </div>
            </div>
          </div>
        }

        <div className="mt-2 mb-3">
          <button 
            className='btn btn-xs btn-outline-ritase mr-3 r-5'
            onClick={() => this.checkPrice()}
            disabled={this.state.loading}
          >
              {this.state.loading ?
                <Pulse size={10} color={'#27A397'}/> : 
                'Hitung Estimasi'
              }
          </button>
          {/* <span>{utils.formatRupiah(this.state.est_price)}</span> */}
        </div>

        <div className="mt-2">
          <button className="btn btn-primary" onClick={() => this.nextStep(this.props)} style={{width: '100%'}} disabled={this.state.loading}>
            {this.state.loading ?
              <Pulse size={10} color={'#fff'}/> 
            : 'Selanjutnya'}
          </button>
        </div>
      </div>
    )
  }

  async nextStep(props) {
    try {
      utils.validateApkOrder({
        locations: this.props.orders.locations,
        smuno: this.props.smuno,
        ba_date: this.props.ba_date
      }, 0);

      await this.checkPrice((response) => {
        let details = _.get(response, 'data[0].details', []);
        this.props.setApkDetails(details);
        window.location.href = '#/dashboard/apk/contact';
        props.handleNext();
      });
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      })
    }
  }
}

APKRoute.propTypes = {
  orders: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  setStep: PropTypes.func,
  scrollToTop: PropTypes.func,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  locations: state.orders.locations,
  smuno: state.orders.apk.smuno,
  ba_date: state.orders.apk.ba_date,
  me: state.auth.user,
  fee: state.orders.apk.fee,
  ltlVehicleType: state.orders.apk.vehicle_type
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch({type: 'orders.step.goto', payload: 0}),
  handleNext: () => dispatch({type: 'orders.step.next'}),
  handleRemoveLocation: (id) => dispatch({type: 'orders.location.remove', payload: id}),
  setLocation: (payload) => {
    dispatch({
      type: 'orders.location.update',
      payload
    })
  },
  handleButton: (btn) => dispatch({
    type: 'orders.button',
    payload: btn
  }),
  setSmuNo: (value) => dispatch({
    type: 'orders.smuno',
    payload: value
  }),
  setBaDate: (value) => dispatch({
    type: 'orders.ba_date',
    payload: format(value, 'yyyy-MM-dd')
  }),
  setPaymentItems: (value) => dispatch({
    type: 'orders.apk.fee',
    payload: value
  }),
  setVehicleType: (value) => dispatch({
    type: 'orders.apk.vehicle_type',
    payload: value
  }),
  setApkDetails: (value) => dispatch({
    type: 'orders.apk.details',
    payload: value
  }),
  handleRecenter: (locations) => {
    dispatch({type: 'orders.maps.recenter', payload: gmaps.getBounds(locations)});
  },
  updateOrigin(id, latitude, longitude, address, zipcode, area, city, label, port) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: 'orders.location.update',
        payload: {
          id,
          latitude,
          longitude,
          address,
          zipcode,
          area,
          city,
          label,
          port
        },
      });

      resolve()
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(APKRoute);
