import network from '../network';

const midtrans = {};

midtrans.pay = async (trip_id) => {
  const response = await network.GET('/midtrans/retail', {
    trip_id,
  }, {}, true);
  const {token} = response;
  if (!token) {
    throw new Error('Pembayaran kamu tidak sukses, mohon dicoba lagi!');
  }

  /* global Promise */
  return new Promise((resolve, reject) => {
    /* global snap */
    return snap.pay(token, {
      onSuccess: resolve,
      onPending: resolve,
      onError: reject,
      onClose: resolve,
    });
  });
}

export default midtrans;
