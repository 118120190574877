import React, { Component } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Pulse from '../spinner/Pulse';
import utils from '../../lib/utils';
import LocationInput from '../LocationInput';
import { geocodeByAddress } from 'react-places-autocomplete';
import gmaps from '../../lib/gmaps';
import network from '../../lib/network';

class CreateDepoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      validate: false,
      is_edit: false,
      name: '',
      phone_number: '',
      selected_depo: {},
      location: {
        address: '',
        city: '',
        latitude: null,
        longitude: null,
        zipcode: null
      },
      check: {
        name: false,
        phone_number: false,
        location: false,
        selected_depo: false
      }
    }
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  check() {
    const { name, phone_number, location } = this.state;
    this.setState({
      check: {
        name: /^([a-zA-Z0-9\s+]){4,32}$/.test(name),
        phone: utils.isValidPhoneNumber(phone_number),
        location: location.address.length > 0 && !_.isNil(location.latitude) && !_.isNil(location.longitude),
        selected_depo: !this.state.is_edit || this.state.selected_depo.id
      }
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true,
      validate: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      const { name, phone_number, location, selected_depo, is_edit } = this.state;
      let body = {
        contact_type: "container_depot",
        name,
        phone_number,
        location
      };

      var response;
      if (is_edit) {
        response = await network.PUT(`/contacts/${selected_depo.id}`, body, {}, true);
      } else {
        response = await network.POST(`/contacts`, body, {}, true);
      }
      
      if (response.errorMessage) {
        throw response;
      } else {
        this.props.closeModal();
        Swal.fire('', `Depo berhasil ${is_edit ? 'diperbarui' : 'ditambahkan'}`, 'success');
        this.props.callback();
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  handleLocationChange = (address) => {
    this.setState({
      location: {
        address
      }
    })
  }

  selectLocation() {
    return async (suggestion) => {
      try {
        const results = await geocodeByAddress(suggestion);
        if (results.length < 1)
          throw new Error('no response');
        let result = results[0];
  
        this.setState({
          location: {
            address: suggestion,
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng(),
            city: gmaps.getCity(results),
            zipcode: gmaps.getZipcode(results),
          }
        })
        return true
      } catch (error) {
        // eslint-disable-next-line no-unused-vars
        Swal.fire('Terjadi Kesalahan', 'Mohon maaf kami tidak dapat memproses pengiriman dari Alamat ini');
        return false;
      }
    }
  }

  selectDepo = e => {
    let idx = e.target.value;
    let selected_depo = this.props.depo[idx];
    this.setState({
      selected_depo,
      name: _.get(selected_depo, 'name', ''),
      phone_number: _.get(selected_depo, 'phone_number', ''),
      location: _.get(selected_depo, 'location', ''),
    })
  }
  
  render() {
    const { open, closeModal } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => closeModal()}
        fullWidth
      >
        <DialogTitle onClose={() => closeModal()} className="border-bottom">
          Tambah Depo
          <i
            className="icon icon-close2 mt-2 float-right"
            style={{ paddingRight: 0, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </DialogTitle>
        <DialogContent>
          <form action="/" onSubmit={this.handleSubmit}>
            <div className="mt-2 mb-2">
              <input 
                className='pointer pt-5 noselect' 
                type="checkbox" 
                checked={this.state.is_edit} 
                onChange={(e) => this.setState({ is_edit: e.target.checked })}
                style={{ verticalAlign: 'middle' }}
              />
              <span>Edit depo</span>
            </div>

            {this.state.is_edit &&
              <div>
                <label htmlFor="name">
                  Pilih Depo
                </label>
                <div className="form-group">
                  <select 
                    className={this.state.validate && !this.state.check.selected_depo ? "form-control is-invalid" : "form-control"}
                    name="depo" 
                    onChange={this.selectDepo}
                    defaultValue=''
                    id="selected_depo"
                  >
                    <option disabled value=''></option>
                    {this.props.depo.map((item, i) => (
                      <option 
                        key={item.id} 
                        value={i} 
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Pilih depo terlebih dahulu
                  </div>
                </div>
              </div>
            }
            
            <label htmlFor="name">
              Nama
            </label>
            <div className="form-group">
              <input type="text"
                placeholder='Nama'
                className={this.state.validate && !this.state.check.name ? "form-control is-invalid" : "form-control"}
                value={this.state.name}
                onChange={this.handleChange}
                id="name"
              />
              <div className="invalid-feedback">
                Nama wajib 4-32 karakter
              </div>
            </div>

            <label htmlFor="phone_number">
              Nomor Handphone
            </label>
            <div className="form-group">
              <input type="number"
                placeholder='Nomor Handphone'
                className={this.state.validate && !this.state.check.phone ? "form-control is-invalid" : "form-control"}
                value={this.state.phone_number}
                onChange={this.handleChange}
                id="phone_number"
              />
              <div className="invalid-feedback">
                Nomor wajib angka dan setidaknya 10-13 karakter
              </div>
            </div>
            
            <label htmlFor="phone_number">
              Alamat
            </label>
            <div className="form-group">
              <LocationInput
                onChange={this.handleLocationChange}
                onSelect={this.selectLocation()}
                address={this.state.location.address}
                placeholder='Depo'
                hideHandle={true}
                disabled={false}
              />
            </div>

            <div className='mt-3 pt-4 mb-4 text-right border-top'>
              <button
                type="button"
                className="btn btn-lg btn-outline-secondary mr-4"
                onClick={() => closeModal()}
              >
                Close
              </button>
              <button 
                type="submit" 
                className="btn btn-primary btn-lg" 
              >
                {this.state.loading ? <Pulse/> : 'Simpan'}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default CreateDepoModal;