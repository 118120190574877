import arrayMove from 'array-move';

const initialState = [
  {
    id: 0,
    address: '',
    name: '',
    detail: '',
    phone_number: '',
    area: '',
    remarks: '',
  },
  {
    id: 1,
    address: '',
    name: '',
    detail: '',
    phone_number: '',
    area: '',
    remarks: '',
  },
];

function locations(state = initialState, action) {
  switch (action.type) {
  case 'orders.location.add': {
    const nextId = Math.max(...state.map(loc => loc.id)) + 1;
    return [
      ...state,
      {
        id: nextId,
        address: '',
        name: '',
        detail: '',
        phone_number: '',
        area: '',
        remarks: '',
      },
    ]
  }

  case 'orders.location.replace' : {
    return action.payload;
  }

  case 'orders.location.update': {
    const stateCopy = [...state];
    const id = stateCopy.findIndex((location) => location.id === action.payload.id);
    stateCopy[id] = {
      ...stateCopy[id],
      ...action.payload,
    };
    return stateCopy
  }

  case 'orders.location.remove':
    return state.filter(location => (location.id !== action.payload));

  case 'orders.location.reorder':
    return arrayMove(state, action.payload.oldIndex, action.payload.newIndex);

  default:
    return state
  }
}

export default locations;
