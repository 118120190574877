import React, {Component, Fragment} from 'react'
import Sidebar from "./components/Sidebar";
import Content from "./components/Content";
import Navbar from "./components/Navbar";
import routes from "./routes/dashboard";
import {Switch, Route} from 'react-router-dom'
import ScrollToTop from "react-scroll-up";
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import auth from './lib/auth';

class Dashboard extends Component {

  render() {
    return (
      <Fragment>
        <Navbar/>
        <Sidebar/>
        <Content>
          <Switch>
            {routes.map(route =>
              <Route
                key={route.path}
                path={route.path}
                render={auth.checkRoute(route)}
              />
            )}
          </Switch>

          {this.props.step === 2 ? <ScrollToTop showUnder={300} style={
            {
              position: 'fixed',
              bottom: 23,
              left: 15,
              cursor: 'pointer',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'linear',
              transitionDelay: '0s'
            }
          }>
            <span className='btn btn-sm btn-primary'><i className='icon icon-arrow-up'/>Kembali ke Form Pemesanan</span>
          </ScrollToTop> : null}
        </Content>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  step: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  step: state.orders.step,
});

export default connect(mapStateToProps)(Dashboard)
