import Home from "../components/Home";
import Order from "../components/Order";
import History from "../components/History"
// import Done from "../components/Done"
import Negotiation from "../components/Negotiation";
import { LOGGED } from "../config";
import NPCT from "../components/NPCT";
import APK from "../components/APK";

const routes = [
  {
    path: '/dashboard/negotiation',
    component: Negotiation
  },
  {
    path: '/dashboard/orders',
    component: Order
  },
  {
    path: '/dashboard/history',
    component: History,
    when: LOGGED,
  },
  {
    path: '/dashboard/npct1',
    component: NPCT
  },
  {
    path: '/dashboard/apk',
    component: APK
  },
  // {
  //   path: '/dashboard/done',
  //   component: Done,
  // },
  {
    path: '/dashboard',
    component: Home
  },
];

export default routes;
