/**
 * Utility functions for Angkasa Pura specific implementation
 */

import gmaps from "./gmaps";
import destinations_sumatera from "./geojson/sumatera_mainland";
import destinations_kalimantan from "../lib/geojson/kalimantan";
import destinations_jawa from "./geojson/jawa";
import destinations_pulau_bangka from "./geojson/pulau_bangka";
import destinations_pulau_belitung from "./geojson/pulau_belitung";
import destinations_pulau_bintan from "./geojson/pulau_bintan";
import destinations_papua from "./geojson/papua";

class ApkGeojson {
  constructor() {
    this.mapGeojson = {
      'Sumatera': destinations_sumatera,
      'Kalimantan': destinations_kalimantan,
      'Jawa': destinations_jawa,
      'Pulau Bangka': destinations_pulau_bangka,
      'Pulau Belitung': destinations_pulau_belitung,
      'Pulau Bintan': destinations_pulau_bintan,
      'Papua': destinations_papua,
    };
  }

  /**
   * Lookup destination area
   * @param {*} latlong       destination latlong as array [long,lat]
   * @param {*} origin_area   area of origin
   * @returns 
   */
  lookupDestinationArea(latlong, origin_area){
    let searchAreas = this.mapGeojson[origin_area];
    let area = gmaps.lookupArea(latlong, searchAreas);
    return area;
  }
}

export const apkGeojson = new ApkGeojson();