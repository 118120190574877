import React from 'react'
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import Swal from 'sweetalert2';

import utils from '../lib/utils';
import {paymentMethods} from '../lib/payments';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({children, classes, onClose}) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const orderSummary = (trip_id, price) => (
  <>
  <div className='row p-1 ml-1 mr-1 mt-1 mb-1' style={{ backgroundColor: '#f0f0f0', borderRadius: '.3em' }}>
    <h6
      className='col-3'
      style={{
        fontWeight: 500,
        color: 'black',
      }}
    >
      Order ID
    </h6>
    <div
      className='col-9'
      style={{
        textAlign: 'right',
        fontWeight: 500,
        color: 'black',
        opacity: 0.45,
      }}
    >
      {trip_id}
    </div>
  </div>
  <div className='row p-1 ml-1 mr-1 mt-1 mb-1'>
    <h6
      className='col-3'
      style={{
        fontWeight: 500,
        color: 'black',
      }}
    >
      Jumlah
    </h6>
    <div
      className='col-9'
      style={{
        textAlign: 'right',
        color: '#3caf9c',
        fontWeight: 500,
      }}
    >
      {utils.formatRupiah(price)}
    </div>
  </div>
  </>
);

const ChoosePayment = ({response, open = false, closeModal, onFinish}) => {
  if (!open || !response) {
    return null;
  }

  const {trip_id, price} = response;

  const handlePayment = (handler) => async () => {
    try {
      closeModal();
      /* global snap */
      snap.show();
      await handler.pay(trip_id, { finishLoading: snap.hide })
      onFinish();
    } catch (err) {
      console.error(err);
      snap.hide();
      closeModal()
      Swal.fire({
        type: 'error',
        text: err.message,
      });
    }
  }

  return (
    <Dialog open={open} onClose={closeModal} maxWidth='xs' fullWidth>
      <DialogTitle className='text-center' id="customized-dialog-title" onClose={closeModal}>
        Pembayaran
      </DialogTitle>
      <DialogContent dividers>
        {orderSummary(trip_id, price)}
      </DialogContent>
      <DialogContent
        style={{
          backgroundColor: '#e8e8e8',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        {paymentMethods.map(paymentMethod => (
          <div
            key={paymentMethod.name}
            className='row p-2 ml-1 mr-1 mt-3 mb-3'
            onClick={paymentMethod.enabled && handlePayment(paymentMethod.handler)}
            style={{
              backgroundColor: 'white',
              borderRadius: '.6em',
              filter: paymentMethod.enabled ? 'grayscale(0%)' : 'saturate(0) opacity(0.8)',
            }}
          >
            <div
              className='col-3'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={paymentMethod.logo} alt={paymentMethod.name} />
            </div>
            <div className='col-9' style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{ flex: 1 }}>
                <h5 style={{ fontWeight: 500, color: 'black' }}>{paymentMethod.name}</h5>
              </div>
              <div style={{ flex: 1 }}>
                <small style={{ color: '#999999' }}>{paymentMethod.description}</small>
              </div>
            </div>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
}

ChoosePayment.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  response: PropTypes.object,
  onFinish: PropTypes.func,
}

export default ChoosePayment;
