import React, {Component} from 'react';
import network from "../lib/network";
import * as Swal from "sweetalert2";

class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      password: "",
      confirmation_password: "",
      email: "",
      error: "",
    };
  }

  validateForm() {
    return this.state.password === this.state.confirmation_password;
  }

  componentWillMount() {
    const url = new URL(document.location.href);
    const searchParams = new URLSearchParams(url.search);

    this.setState({
      token: searchParams.get('token'),
      email: searchParams.get('email'),
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    network.POST('/users/retail/reset', {
      password: this.state.password,
      token: this.state.token,
      email: this.state.email,
    }).then((response) => {
      if (response.errorMessage !== undefined) {
        Swal.fire({
          type: 'error',
          text: 'Mohon maaf, penggantian password anda gagal. Mohon dicoba kembali.',
        });
        return;
      }
      Swal.fire({
        type: 'success',
        text: 'Password anda telah diganti.',
      }).then(function () {
        document.location.href = '/#/login'
      });
    });
  };

  render() {
    return (
      <main>
        <div id="primary" className="p-t-b-100 height-full ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-md-auto">
                <div className="text-center">
                  <img src="/assets/img/logo.png" alt="" style={{maxWidth: '75%'}}/>
                  <h3 className="mt-2">Reset Password</h3>
                </div>
                <form action="/" onSubmit={this.handleSubmit}>
                  <label htmlFor="password">
                      Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-key2"/>
                    <input type="password"
                      className="form-control form-control-lg"
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                      placeholder="Password baru"
                    />
                  </div>
                  <label htmlFor="confirmation_password">
                      Konfirmasi Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-key2"/>
                    <input type="password"
                      className="form-control form-control-lg"
                      value={this.state.password_confirmation}
                      onChange={this.handleChange}
                      id="confirmation_password"
                      placeholder="Konfirmasi password baru"
                    />
                  </div>
                  <div>
                    <input type="submit" className="btn btn-primary btn-lg btn-block" value="Simpan"
                      disabled={!this.validateForm()}/>
                    <br/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Reset;
