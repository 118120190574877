import React, {Component} from 'react';
import Loader from './components/Loader'
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom'
import routes from './routes'

import {Provider} from 'react-redux';
import auth from './lib/auth';
import network from './lib/network';
import store from './store';



class App extends Component {

  async componentDidMount() {
    if (auth.check()) {
      const me = await network.getMe();
      store.dispatch({ type: 'auth.update', data: { isAuthorized: true, user: me } })
    }
  }
  
  render() {
    return (
      <div className="App">
        <Loader/>
        <Provider store={store}>
          <HashRouter>
            <Switch>
              {routes.map(route =>
                <Route
                  key={route.path}
                  path={route.path}
                  render={auth.checkRoute(route)}
                />
              )}
              <Redirect from='/' to='/dashboard'/>
            </Switch>
          </HashRouter>
        </Provider>
        <div id="modal" />
      </div>
    );
  }
}

export default App;
