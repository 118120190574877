import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import auth from '../../lib/auth';
import network from '../../lib/network';
import { Link } from 'react-router-dom';
import utils from "../../lib/utils";

class ListGatepass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedListData: [],
      isLoading: true
    }
  }

  componentDidMount = async () => {
    if (auth.check()) {
      var referenceNumber = this.props.match.params.reffNumber;
      const response = await network.POST('/econ/gatepass', { referenceNumber }, {}, true);
      const list = await network.GET('/econ/list', { reference_number : referenceNumber }, {}, true);
      
      var data = [];
      let selectedListData = [];
      if (response.errorMessage || parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        data = _.get(response, 'collections.gatepass', []);
        selectedListData = _.get(list, 'rows', []);
      }

      this.setState({
        data,
        selectedListData,
        isLoading: false
      })
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  }

  render() {
    const { isLoading, data, selectedListData } = this.state;

    return (
      <Fragment>
        <Navbar />
        <Sidebar />
        <Content>
          <div id="primary" className="p-t-b-50 height-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mx-md-auto">
                  <div className="text-center">
                    <h3 className="mt-2">List Gate Pass</h3>
                  </div>
                  <table className={`mt-5 bg-white black-text table ${data.length > 0 ? 'table-responsive' : ''}`}>
                    <thead className="bg-ritase white-text">
                      <tr>
                        <th>Container Number</th>
                        <th>Customer Name</th>
                        <th>POD</th>
                        <th>SPOD</th>
                        <th>Tar</th>
                        <th>Vessel Name</th>
                        <th>Voyage</th>
                        <th>Status</th>
                        <th style={{width: '80px'}}></th>
                      </tr>
                    </thead>
                    { isLoading ?
                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center">Loading ...</td>
                        </tr>
                      </tbody>
                      : data.length === 0 ?
                        <tbody>
                          <tr>
                            <td colSpan={8} className="text-center">No Data Found</td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                          {data.map((item, i) => (
                            <tr key={i}>
                              <td>{ item.containerNo }</td>
                              <td>{ item.customerName }</td>
                              <td>{ item.pod }</td>
                              <td>{ item.spod }</td>
                              <td>{ item.tar }</td>
                              <td>{ item.vesselName }</td>
                              <td>{ item.voyage }</td>
                              <td>{ utils.statusOrder(item).label }</td>
                              <td style={{width: '80px'}}>
                                <div>
                                  <Link
                                    to={`/npct1/gatepass/${this.props.match.params.reffNumber}/print/${encodeURIComponent(item.tar)}`}
                                    className="btn btn-block btn-primary s-14"
                                  >
                                    <i className="icon-print">&nbsp;&nbsp;</i>Cetak Gate Pass
                                  </Link>
                                </div>
                                <div>
                                  { 
                                    selectedListData && selectedListData.length > 0 && _.get(selectedListData, '[0].is_extension') == 0 ?
                                    <Link
                                      to={`/dashboard/npct1/route?reference_no=${this.props.match.params.reffNumber}&gatepass_no=${encodeURIComponent(item.tar)}`}
                                      className="btn btn-block btn-ritase mt-3 s-14"
                                    >
                                      <i className="icon-truck">&nbsp;&nbsp;</i>Truck Order
                                    </Link>
                                    : ''
                                  }
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

export default ListGatepass;
