import React, {Component} from 'react'
import { css } from '@emotion/core';
import { BarLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export default class Bar extends Component {
  render() {
    return (
      <BarLoader
        css={override}
        sizeUnit={"px"}
        size={14}
        width={100}
        widthUnit='%'
        color={'#27A397'}
      />
    )
  }
}
