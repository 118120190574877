import React, {useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import network from "../../lib/network";
import _ from 'lodash';
import Swal from 'sweetalert2';
import Pulse from "../spinner/Pulse";

const NpwpChecker = (props) => {
  const {initialNpwp} = props;
  const [state, setState] = useState({
    npwp: '',
    isValid: false,
    loading: false,
  });

  useEffect(() => {
    setState((s) => ({
      ...s,
      npwp: initialNpwp || '',
    }));
  }, [initialNpwp]);

  const checkNpwp = async (taxId) => {
    setState((s) => ({...s, loading: true}));
    const profile = await network.POST('/econ/profile', {
      taxId: taxId, action: props.action || "import"
    }, {}, true);
    setState((s) => ({...s, loading: false}));
    if (profile.status === 404) {
      Swal.fire('', 'NPWP tidak dikenali, silakan periksa NPWP anda terlebih dahulu', 'error');
    } else {
      // await updateProfileNpwp(props.userId, state.npwp);
      setState((s) => ({...s,
        isLoading: false
      }));
      if (typeof props.onNpwpValid === 'function') {
        props.onNpwpValid({
          messageId: profile.messageId,
          customerName: _.get(profile, 'objects.company.customerName', '')
        });
      }
    }
  }

  const updateProfileNpwp = async (user_id, npwp_number) => {
    try {
      const response = await network.PUT(`/users/${user_id}`, {
        npwp_number,
      }, {}, true);

      if ('errorMessage' in response) {
        if (response.errorMessage.includes('ER_DUP_ENTRY'))
          throw new Error('Mohon maaf, data yang Anda masukkan telah terdaftar di sistem kami');
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      await Swal.fire({
        type: 'error',
        text: err,
      });
    }
  }

  return (
        <>
            <h3 className="text-center text-black font-weight-bold mb-3">NPWP Verification</h3>
            <div className="form-group has-icon"><i className="icon-id-card-o"/>
              <NumberFormat
                id="npwp"
                value={state.npwp}
                className={state.npwp.length < 15 ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                placeholder="NPWP"
                disabled={state.loading}
                format="##.###.###.#-###.###"
                thousandSeparator={false}
                allowLeadingZeros={true}
                allowNegative={false}
                onValueChange={(values) => {
                  const {value} = values;
                  setState((s) => ({...s, npwp: value}));
                }}/>
              <div className="invalid-feedback">
                    NPWP harus 15 angka
              </div>
            </div>
            <button onClick={() => checkNpwp(state.npwp)} disabled={state.loading || state.npwp.length < 15} className="btn btn-primary btn-block">
              {state.loading ? <Pulse/> : 'Selanjutnya'}
            </button>
        </>
  )
}

export default NpwpChecker;
