import {fitBounds} from 'google-map-react/utils';
import utils from "./utils";
import GeoJSON from "geojson-geometries-lookup";

const gmaps = {
  mMaps: null,
  mMap: null,
  mService: null,
  mDisplay: null,
};

gmaps.setObject = (map, maps) => {
  gmaps.mMap = map;
  gmaps.mMaps = maps;
};

gmaps.drawRoute = async (locations, optimize = false) => {
  // eslint-disable-next-line no-undef
  return new Promise(function (resolve) {
    if (gmaps.mMaps) {
      gmaps.mService = gmaps.mService || new gmaps.mMaps.DirectionsService();
      gmaps.mDisplay = gmaps.mDisplay || new gmaps.mMaps.DirectionsRenderer({
        suppressMarkers: true
      });
    }

    let drop = [...locations];
    let origin = drop.shift();
    let dest = drop[drop.length - 1];

    if (optimize) {
      let max = {
        id: 0,
        idx: 0,
        dist: 0,
        val: null
      };

      drop.map((i, idx) => {
        if (i['latitude'] && i['longitude']) {
          let dist = utils.distance(origin['latitude'], origin['longitude'], i['latitude'], i['longitude']);
          if (dist > max['dist']) {
            max = {
              id: i['id'],
              idx: idx,
              dist: dist,
              val: i
            };
          }
        }
        return true
      });


      dest = drop.splice(max['idx'], 1)[0];
    } else {
      drop.pop()
    }

    let waypoints = [];
    drop.map((item) => {
      waypoints.push({
        location: new gmaps.mMaps.LatLng(item['latitude'], item['longitude']),
        stopover: true
      });
      return true
    });

    if (gmaps.mMap) {
      gmaps.mDisplay.setMap(gmaps.mMap);
    }

    let optimized = [...locations];
    if (gmaps.mService) {
      gmaps.mService.route({
        origin: new gmaps.mMaps.LatLng(origin.latitude, origin.longitude),
        destination: new gmaps.mMaps.LatLng(dest.latitude, dest.longitude),
        travelMode: "DRIVING",
        optimizeWaypoints: optimize,
        waypoints: waypoints
      }, (result, status) => {
        if (status === gmaps.mMaps.DirectionsStatus.OK) {
          if (optimize) {
            let route = [];
            route.push(origin);
            result['routes'][0]['waypoint_order'].map((v) => {
              route.push(drop[v]);
              return true
            });
            route.push(dest);
            optimized = route;
            resolve(optimized)
          }
        }
        gmaps.mDisplay.setDirections(result);
      });
    }
  })
};

gmaps.lookupArea = (point, areas) => {
  for (let i = 0; i < areas.length; i++) {
    let origin = areas[i];
    let lookup = new GeoJSON(origin.geojson);
    if (lookup.hasContainers({
      type: 'Point',
      coordinates: [point[0], point[1]],
    }))
      return origin.name;
  }
  return false
};

gmaps.clearRoute = () => {
  gmaps.mDisplay.setMap(null);
};

gmaps.getCountry = (results) => {
  return results[0].address_components.filter(function (comp) {
    return comp.types.includes('country');
  })[0].long_name
};

gmaps.getLocationType = (results) => {
  return results[0].geometry.location_type;
};

gmaps.getCity = (results) => {
  let adm = results[0].address_components.filter(function (comp) {
    return comp.types.includes('administrative_area_level_2');
  });

  return adm.length > 0 ? adm[0].long_name : 'NOT FOUND';
};

gmaps.getZipcode = (results) => {
  const zipcodes = results[0].address_components.filter((component) => component.types.includes('postal_code'));

  return zipcodes.length > 0 ? zipcodes[0].long_name : 'ZipTod';
};

gmaps.getBounds = (locations) => {
  const initialState = {
    center: {
      lat: -6.225900,
      lng: 106.825322
    },
    zoom: 15,
  };

  const maxLat = Math.max(...locations.map((location) => location.latitude));
  const maxLng = Math.max(...locations.map((location) => location.longitude));
  const minLat = Math.min(...locations.map((location) => location.latitude));
  const minLng = Math.min(...locations.map((location) => location.longitude));
  const bounds = {
    ne: {
      lat: maxLat,
      lng: maxLng,
    },
    sw: {
      lat: minLat,
      lng: minLng,
    }
  };

  const {center, zoom} = fitBounds(bounds, {width: 320, height: 568});

  if (isNaN(zoom)) {
    const loc = locations.filter((loc) => (loc.latitude && loc.longitude))[0];

    if (loc)
      return {
        center: {
          lat: loc.latitude,
          lng: loc.longitude,
        },
        zoom: 15,
      };
    else
      return initialState
  }

  return {center: center, zoom: zoom}
};

export default gmaps;
