import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format';
import network from '../../lib/network';
import auth from '../../lib/auth';
import utils from '../../lib/utils';

import {DB_CONFIG} from "../../config";
import firebase from 'firebase/app'

import routeNPCT from '../../routes/npct1';
import Pulse from '../spinner/Pulse';

class NPCTSubmit extends Component {

  scrollToTop = () => {
    this.scrollRef.current && this.scrollRef.current.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      timestamp: null,
      validOrder: false,
      loading: false,
      vehicleName: '',
      response: null,
      itemType: '',
      bargainPrice: 0,
      maxPrice: 0,
      minPrice: 0,
      tnc: false
    }
  }

  componentDidMount = () => {
    this.props.scrollToTop();
    this.scrollToTop();
    this.props.setStep();
    const lastGoodStep = utils.getLastGoodStep(this.props.state.orders);
    if (lastGoodStep < 0) {
      window.location.href = '#' + routeNPCT[lastGoodStep + 1].path;
      return;
    }
    this.checkPrice();
  };

  componentWillMount() {
    let fb = firebase;
    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }

    fb.database().ref('/vehicle_type/').once('value', (snap) => {
      const vehicle = snap.val().filter((vehicle) => (
        vehicle.id === this.props.state.orders.vehicle_type
      ));

      this.setState({
        vehicleName: vehicle[0] ? vehicle[0].name : 'CDE',
      });
    });
  }

  async submitForm(state) {
    if (!auth.check()) {
      await Swal.fire({
        type: "error",
        text: "Silahkan login terlebih dahulu untuk membuat penawaran.",
      });
      window.location.href = `#/login?next=${window.location.hash.substr(1)}`;
      return;
    }

    if (!this.state.tnc) {
      return Swal.fire('', 'Anda harus menyetujui Syarat dan Ketentuan yang berlaku', "warning");
    };

    if (this.state.itemType === '') {
      return Swal.fire('', 'Deskripsi muatan harus diisi', 'info');
    }

    this.setState({ loading: true })
    try {
      utils.validateOrder(state.orders, 2);
      const body = {
        locations: this.props.state.orders.locations,
        pickupType: this.props.state.orders.pickup_type.pType,
        vehicleType: this.props.state.orders.vehicle_type,
        remarks: this.props.state.orders.remarks,
        addons: {
          ...this.props.state.orders.addons,
          promo: this.props.state.orders.promo || undefined,
        },
        bargainPrice: this.state.bargainPrice,
        itemType: this.state.itemType,
        is_npct: 1,
        reference_number: this.props.reference_no,
        gatepass: this.props.gatepass_no,
        is_depo: this.props.is_depo ? 1 : 0,
        shipment_type: this.props.state.orders.delivery_type
      };

      if (this.props.state.orders.pickup_type.pType === 'SCHEDULED')
        body.pickupTime = this.props.state.orders.pickup_time.toISOString();
      
      if (this.props.is_depo) {
        body.depot_contact_id = this.props.state.orders.depo
      }

      const response = await network.POST('/orders/retail/negotiation_order', body, {}, true);

      if (response.message && response.message.includes('authorized')) {
        network.logout('Sesi anda telah berakhir. Mohon login kembali.', true);
        return;
      }

      if (response.errorMessage) {
        Swal.fire('', response.errorMessage.split('\n')[0], 'error');
        this.setState({ loading: false })
        return;
      }

      if (!(response.success !== undefined && response.success)) {
        Swal.fire('', 'Pengajuan penawaran harga gagal. Mohon coba kembali.', 'error');
        this.setState({ loading: false })
        return;
      }

      await Swal.fire('Selamat! Penawaran berhasil diajukan', `Selamat, penawaran anda berhasil diajukan. Kami membutuhkan waktu paling lama 1x24 jam untuk memproses penawaran anda. Anda bisa melihat status penawaran anda di Riwayat Order.`, 'success');
      document.location.href = '/#/dashboard/history';
      window.location.reload();
      return;

    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({ loading: false })
    }
  }

  checkPrice = async () => {
    this.setState({loading: true});

    const endpoint = '/orders/retail/negotiate_fee';

    const body = {
      locations: this.props.state.orders.locations.map(loc => {
        loc.detail = 'ritase';
        loc.name = 'ritase';
        loc.phone_number = '0813371337';
        return loc;
      }),
      pickupType: this.props.state.orders.pickup_type.pType,
      vehicleType: this.props.state.orders.vehicle_type,
      remarks: this.props.state.orders.remarks,
      addons: {
        check_fee: true
      },
    };

    if (this.props.state.orders.pickup_type.pType === 'SCHEDULED')
      body.pickupTime = this.props.state.orders.pickup_time.toISOString();

    try {
      let now = (new Date()).getTime();
      this.setState({
        timestamp: now
      });
      const response = await network.POST(endpoint, {
        timestamp: now,
        ...body
      }, {}, auth.check());
      this.processResponse(response);
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({
        validOrder: false,
        loading: false,
      });
    }
  };

  processResponse = (response) => {
    if (response.message) {
      if (response.message.includes('authorized')) {
        network.logout('Silahkan login terlebih dahulu untuk membuat penawaran.');
        return;
      }
    }
    if (!response.valid) {
      Swal.fire('', 'Order anda tidak valid. Mohon pastikan anda telah mengisi detail dengan benar.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    if (response.errorMessage) {
      Swal.fire('', 'Maaf, kami tidak dapat membuat order anda untuk saat ini. Mohon coba kembali dalam beberapa saat.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }

    if (this.state.timestamp > response.timestamp){
      return;
    }
    this.setState({ validOrder: true, loading: false, maxPrice: response.maxPrice, minPrice: response.minPrice});
  };

  agree() {
    this.setState({
      tnc: !this.state.tnc
    })
  }

  render() {
    return (
      <div ref={this.scrollRef} id="submitNegotiation">
        <div className="mt-2">
          <div className="form-group">
            <label className="font-weight-bold" htmlFor="jumlah_penawaran">Jumlah Penawaran</label>
            <NumberFormat disabled={this.state.loading} 
              value={this.state.bargainPrice} 
              className="form-control mb-2" 
              placeholder="Rp. 1.000.000"
              thousandSeparator={true} 
              prefix={'Rp. '} 
              allowLeadingZeros={false}
              allowNegative={false}
              onValueChange={(values) => {
                const { value } = values;
                this.setState({ bargainPrice: parseInt(value) })
              }} />
          </div>
          <div className="form-group">
            <label className="font-weight-bold" htmlFor="item_type">
              Deskripsi Muatan
              <i className="s-18 text-danger cur-help" onMouseDown={() => {
                Swal.fire('', 'Bagian ini harus diisi', 'info');
              }}>*</i>
            </label>
            <input disabled={this.state.loading} required type="text" id='item_type' className="form-control mb-2" placeholder="Masukkan deskripsi muatan"
              value={this.state.itemType} onChange={(e) => {
                this.setState({
                  itemType: e.target.value
                })
              }} />
          </div>
        </div>
        <div className="mt-2">
          <div className="mb-4">
            <label className="font-weight-bold" htmlFor="item_type">Estimasi Biaya</label>
            {this.state.loading && this.state.maxPrice === 0 && this.state.minPrice === 0 ? <Pulse /> : <h4 className="est-price">{utils.formatRupiah(this.state.minPrice)} - {utils.formatRupiah(this.state.maxPrice)}</h4>}
          </div>
          <div className='mt-2 mb-2'>
            <input className='pointer pt-5' type="checkbox" checked={this.state.tnc} onChange={() => this.agree()}/>
            <span onMouseDown={() => this.agree()} className='pointer noselect'>Saya menyetujui <a href="/#/tnc"> Syarat
            dan Ketentuan</a> yang berlaku</span>
          </div>
          <button
            onClick={() => this.submitForm(this.props.state)}
            className="btn btn-primary btn-block"
            disabled={ !this.state.validOrder || this.state.loading}>
            {this.state.loading ? <Pulse /> : 'Ajukan penawaran harga'}
          </button>
        </div>
      </div>
    )
  }
}

NPCTSubmit.propTypes = {
  state: PropTypes.object,
  handleBack: PropTypes.func,
  removePromo: PropTypes.func,
  handleTooltip: PropTypes.func,
  vType: PropTypes.number,
  scrollToTop: PropTypes.func,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({
    area: PropTypes.string,
  }))
};

const mapStateToProps = (state) => ({
  vType: state.orders.vehicle_type,
  state: state,
  locations: state.orders.locations,
  step: state.orders.step,
  gatepass_no: state.orders.gatepass_no,
  reference_no: state.orders.reference_no,
  is_depo: state.orders.is_depo,
  depo: state.orders.depo,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch ({type: 'orders.step.goto', payload: 1}),
  handleBack: () => dispatch({type: 'orders.step.back'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(NPCTSubmit);
