import React from 'react'
import './tnc.css'

export default class TnC extends React.Component {
  render() {
    return (
      <div className="col-12 mt-3">
        <div className="card">
          <div className="card-header white mt-2">
            <h4 className='font-weight-bold'>Syarat dan Ketentuan</h4>
          </div>
          <div className="card-body p-5 text-justify">
            <p>Pada situs ini anda akan menemukan syarat dan ketentuan yang berlaku pada saat menggunakan
              Layanan Ritase (<b>“Ritase”</b> atau <b>“Kami”</b>) yang disediakan oleh Kami (sebagaimana masing-masing
              didefinisikan dibawah ini).</p>
            <p>
              Dengan mendaftar dan/atau menggunakan situs Ritase dan/atau aplikasi online Ritase (Aplikasi), anda
              menyatakan bahwa anda telah membaca, mengerti dan setuju untuk terikat secara hukum oleh syarat
              dan ketentuan ini dan dokumen-dokumen sebagaimana dimaksud didalamnya.
            </p>
            <dl className="dl-horizontal">
              <ol>
                <li className='font-weight-bold'>Definisi
                  <ol className='num'>
                    <li className='font-weight-light num'>&quot;Ritase&quot; adalah PT Digital Truk Indonesia suatu badan hukum
                      yang
                      didirikan dan tunduk berdasarkan hukum Negara Republik Indonesia yang bedomisili di Jakarta
                      Selatan.
                    </li>
                    <li className='font-weight-light num'>&quot;Layanan Ritase&quot; adalah keseluruhan produk dan/atau jasa
                      pengiriman yang diberikan oleh Ritase termasuk namun tidak terbatas pada layanan-layanan tambahan
                      melalui website dan atau Aplikasi.
                    </li>
                    <li className='font-weight-light num'>&quot;Aplikasi&quot; adalah aplikasi milik Ritase yang digunakan untuk
                      mengakses dan menggunakan Layanan Ritase melalui jaringan internet.
                    </li>
                    <li className='font-weight-light num'>&quot;Website&quot; adalah www.ritase.com dan www.retail.ritase.com
                      untuk
                      mengakses dan menggunakan Layanan Ritase melalui jaringan internet.
                    </li>
                    <li className='font-weight-light num'>&quot;Pelanggan&quot; adalah setiap pengguna Layanan Ritase melalui
                      Website
                      dan/atau Aplikasi.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Penggunaan Website Ritase
                  <ol className='num'>
                    <li className='font-weight-light num'>Ketentuan-ketentuan ini hanya berlaku untuk bagian-bagian
                      dari
                      situs Website yang tidak memerlukan sertifikat digital atau perangkat keamanan lainnya atau
                      tindakan untuk akses.
                    </li>
                    <li className='font-weight-light num'>Pengunduhan dan/atau penggunaan Website dan Aplikasi terbebas
                      dari biaya. Ritase tidak memungut biaya pendaftaran dan/atau berlangganan kepada Pelanggan.
                    </li>
                    <li className='font-weight-light num'>Pelanggan yang telah mendaftar dapat bertindak sebagai
                      pengirim.
                    </li>
                    <li className='font-weight-light num'>Pendaftaran diri menjadi Pelanggan dilakukan Website dan/atau
                      Aplikasi.
                    </li>
                    <li className='font-weight-light num'>Pelanggan mengisi dan melengkapi data diri dan/atau seluruh
                      dokumen-dokumen yang diperlukan pada saat mendaftarkan diri. Pelanggan menjamin bahwa seluruh data
                      diri dan informasi yang disampaikan kepada Ritase, termasuk namun tidak terbatas pada nomor
                      telepon dan alamat surel adalah akurat berada di dalam penguasaan Pelanggan.
                    </li>
                    <li className='font-weight-light num'>Dalam hal terdapat perubahan data-data Pelanggan, maka
                      Pengguna
                      wajib melakukan perubahan dalam Webiste dan/atau Aplikasi Ritase. Kami tidak bertanggung jawab
                      atas kerugian yang terjadi dalam hal terdapat perubahan yang tidak diberitahukan kepada Kami.
                    </li>
                    <li className='font-weight-light num'>Pelanggan dapat mendaftarkan permintaan pengiriman <strong>(“Order
                      Pengiriman”)</strong> melalui Layanan Ritase.
                    </li>
                    <li className='font-weight-light num'>Pelanggan mengakui dan mengetahui bahwa internet yang
                      digunakan
                      oleh Pelanggan bukan merupakan sarana yang aman dan informasi yang dikirimkan ke Layanan Ritase
                      yang diterima baik melalui Website dan/atau Aplikasi Ritase dapat diakses oleh pihak ketiga.
                      Ritase tidak bertanggung jawab dalam hal apapun terkait situasi ini.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Syarat dan Ketentuan Pengiriman oleh Pelanggan
                  <ol className='num'>
                    <li className='font-weight-light num'>Setiap Order Pengiriman dan juga perinciannya yang dilakukan
                      oleh Pelanggan akan didaftarkan melalui Website dan/atau Aplikasi Ritase.
                    </li>
                    <li className='font-weight-light num'>Pelanggan dapat membatalkan Order Pengiriman setiap saat
                      sebelum diterima oleh Ritase. Apabila Order Pengiriman sudah diterima oleh Ritase, Ritase akan
                      memberitahu Pelanggan melalui Surel dan Website bahwa Order Pengiriman sudah diterima.
                    </li>
                    <li className='font-weight-light num'>Dalam hal Order Pengiriman sudah diterima dan dikonfirmasi
                      oleh pengemudi Ritase, maka Pelanggan dapat melakukan pembatalan dengan menghubungi nomor Call Centre yang tersedia.
                    </li>
                    <li className='font-weight-light num'>Pelanggan harus memastikan bahwa barang-barang yang akan
                      dikirimkan adalah bukan merupakan barang-barang “frozen food” dan barang-barang yang tidak sah,
                      berbahaya, rawan, tidak aman, tidak menyenangkan, dapat ditolak atau barang apapun yang dilarang
                      untuk dibawa berdasarkan peraturan perundang-undangan yang berlaku di Indonesia.
                    </li>
                    <li className='font-weight-light num'>Ritase berhak menolak mengirimkan barang sesuai dengan
                      ketentuan pengiriman barang yang tertulis pada Undang-Undang Republik Indonesia
                    </li>
                    <li className='font-weight-light num'>Sebelum pengiriman dilakukan, Pelanggan harus memberitahu
                      Kami setiap tindakan pencegahan yang harus diterapkan untuk menangani muatan sesuai dengan
                      sifatnya.
                    </li>
                    <li className='font-weight-light num'>Pelanggan bertanggung jawab sendiri atas setiap dan semua
                      kewajiban yang terjadi atau diduga terjadi sebagai akibat barang-barang yang dikirim, termasuk
                      namun tidak terbatas pada kerusakan harta benda, cedera badan atau kematian.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Batasan / Keterbatasan / Pembatasan / Penahanan
                  <ol className='num'>
                    <li className='font-weight-bold'>Pekerjaan yang tidak dapat dilakukan
                      <ul>
                        <li className='no-num'>Dalam Syarat dan Ketentuan ini tidak ada ketentuan yang mengharuskan
                          setiap kendaraan melakukan layanan pengambilan atau pengiriman barang ke tempat yang tidak
                          memungkinkan untuk melakukan proses pengambilan atau pengiriman seperti kondisi jalan,
                          fasilitas pemuatan/pembongkaran yang tidak memadai dan/atau kejadian Force Majeure sebagaimana
                          dimaksud dalam point 11 dibawah ini.
                        </li>
                      </ul>
                    </li>
                    <li className='font-weight-bold'>Larangan Pengangkutan Pengiriman
                      <h6 className='font-weight-light lola'>Terdapat sejumlah larangan perihal pengangkutan pengiriman dalam Layanan Ritase. Larangan
                        tersebut yaitu secara khusus dilarang sebagai berikut:</h6>
                      <ol>
                        <li className='font-weight-light lola'>Tidak diperbolehkan mengangkut orang perorangan didalam
                          ruang muatan barang;
                        </li>
                        <li className='font-weight-light lola'>Tidak diperbolehkan mengangkut hewan jenis apapun
                          di dalam ruang muatan barang;
                        </li>
                        <li className='font-weight-light lola'>Tidak diperbolehkan mengangkut bahan-bahan yang
                          mengandung unsur berbahaya seperti zat beracun, gas, cairan yang mudah terbakar, bahan
                          peledak, bahan radioaktif, senjata, amunisi, dan bahan-bahan berbahaya lainnnya;
                        </li>
                        <li className='font-weight-light lola'>Tidak diperbolehkan mengangkut barang-barang berharga
                          termasuk namun tidak terbatas pada perhiasan, benda-benda seni, mata uang, dokumen dan/atau
                          barang-barang dengan nilai yang sangat tinggi. Kecuali Pelanggan melakukan asuransi atas
                          barang-barang berharga tersebut;
                        </li>
                        <li className='font-weight-light lola'>Tidak diperbolehkan mengirimkan barang-barang yang
                          memelurkan perlakuan khusus seperti suhu mobil (frozen food)
                        </li>
                      </ol>
                    </li>
                    <li className='font-weight-bold'>Waktu Layanan Ritase
                      <ul>
                        <li className='no-num'>Dalam hal Order Pengiriman telah dikonfirmasi oleh Ritase maka waktu
                          pengiriman dalam Layanan Ritase akan dilakukan pada pukul 07.00 sampai dengan pukul 20.00 dan
                          akan tiba sesuai dengan jarak tujuan pada lokasi pengantaran pengiriman yang akan disepakati.
                        </li>
                      </ul>
                    </li>
                    <li className='font-weight-bold'>Tarif dan Batasan Wilayah Layanan Ritase
                      <ol>
                        <li className='font-weight-light lola'>Batasan wilayah Layanan Ritase yaitu:
                          <ol>
                            <li className='font-weight-light lola'>
                              Titik Penjemputan berada dalam wilayah Jabodetabek.
                            </li>
                            <li className='font-weight-light lola'>
                              Titik Pengiriman berada dalah wilayah Pulau Jawa dan Pulau Bali.
                            </li>
                          </ol>
                          Tarif dasar yang akan dikenakan dalam Layanan Ritase bergantung pada
                          lokasi pengiriman dalam Layanan Ritase yang akan ditentukan dalam Website dan/atau Aplikasi
                          Ritase
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Ketentuan Kerahasiaan Data Pribadi
                  <ol className='num'>
                    <li className='font-weight-light num'>Setiap informasi yang terkandung di dalam Website dan/atau
                      Aplikasi Ritase adalah milik Ritase sepenuhnya.
                    </li>
                    <li className='font-weight-light num'>Ritase akan menjaga kerahasiaan seluruh data pribadi
                      Pelanggan yang diperoleh melalui penggunaan Layanan Ritase.
                    </li>
                    <li className='font-weight-light num'>Setiap data pribadi yang diberikan oleh Pelanggan akan
                      digunakan oleh Ritase untuk kepentingan verifikasi identitas pada saat penggunaan Layanan Ritase
                      melalui Website dan/atau Aplikasi Ritase, memantau penyalahgunaan Website Ritase, serta
                      menginformasikan kepada Pelanggan terkait produk, layanan, promosi dan lain-lain, baik melalui
                      Email maupun melalui media lainnya.
                    </li>
                    <li className='font-weight-light num'>Setiap data pribadi yang dipelihara oleh Ritase dilindungi
                      dan tunduk kepada peraturan perundang-undangan yang berlaku.
                    </li>
                    <li className='font-weight-light num'>Setiap data dan informasi tidak akan diberikan kepada pihak
                      ketiga tanpa persetujuan dari Pengguna, kecuali apabila diperbolehkan oleh hukum atau otoritas
                      yang berwenang.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Keamanan
                  <ol className='num'>
                    <li className='font-weight-light num'>Ritase telah melakukan segala langkah keamanan yang dilakukan
                      untuk memastikan keamanan sistem dan data, baik secara teknis, organisasi, maupun operasional.
                    </li>
                    <li className='font-weight-light num'>Ritase dapat memutus akses ke Layanan Ritase untuk sementara
                      jika ada dugaan kebocoran atau pelanggaran keamanan.
                    </li>
                    <li className='font-weight-light num'>Jika dimungkinkan, dalam hal terjadi kebocoran atau
                      pelanggaran, Ritase akan memberitahu Pengguna melalui Website dan/atau Aplikasi mengenai
                      langkah-langkah yang akan atau telah dilakukan.
                    </li>
                    <li className='font-weight-light num'>Apabila Pengguna merasa ada kebocoran atau pelanggaran,
                      Pengguna wajib melaporkan hal tersebut kepada Ritase, dan Ritase akan segera memblokir sementara
                      akses pihak yang bersangkutan ke Website dan/atau Aplikasi.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Syarat-Syarat Pembayaran, Penerapan Biaya Tambahan dan Biaya
                  Pembatalan
                <ol className='num'>
                  <li className='font-weight-light num'>Pada saat Pelanggan melakukan Order Pengiriman maka Pelanggan
                      harus melakukan pembayaran terebih dahulu ke Nomor Rekening yang ada dalam Website dan/atau
                      Aplikasi Ritase.
                  </li>
                  <li className='font-weight-light num'>Ritase akan melakukan konfirmasi apakah Order Pengiriman
                      diterima oleh pengemudi atau tidak. Dalam hal Order Pengiriman tidak dapat dilakukan oleh Ritase
                      maka pembayaran akan dilakukan pengembalian (refund) ke rekening Pelanggan.
                  </li>
                  <li className='font-weight-light num'>
                    Selain tarif dasar sebagaimana dimaksud dalam point 4.4, biaya tambahan berikut akan berlaku
                        dan harus sesuai pada rincian pemesanan yang telah diubah dan bukti tanda terima wajib diterima
                        oleh Pelanggan yaitu sebagai berikut:
                    <ol>
                      <li className='font-weight-light lola'>Tambahan bantuan pengemudi</li>
                      <li className='font-weight-light lola'>Tambahan bantuan <i>helper</i></li>
                      <li className='font-weight-light lola'>Pengembalian Surat Jalan berbentuk fisik</li>
                    </ol>
                  </li>
                  <li className='font-weight-light num'>
                    Ritase dapat membebankan biaya pembatalan dalam hal sebagai berikut:
                    <ol>
                      <li className='font-weight-light lola'>Dalam hal Pelanggan melakukan pembatalan ketika status
                        Order Pengiriman di konfirmasi oleh Ritase maka Pelanggan tidak dibebankan biaya pembatalan
                        dan pembayaran yang dilakukan oleh Pelanggan dikembalikan seluruhnya.
                      </li>
                      <li className='font-weight-light lola'>Dalam hal Pelanggan melakukan pembatalan ketika status
                        Order Pengiriman diterima oleh Pengemudi maka Pelanggan dibebankan biaya pembatalan 10%
                        (Sepuluh persen) dari pembayaran yang dilakukan oleh Pelanggan.
                      </li>
                      <li className='font-weight-light lola'>Dalam hal Pelanggan melakukan pembatalan ketika
                        Pengemudi telah menjemput Order Pengiriman di tempat Pelanggan maka Pelanggan dibebankan
                        biaya pembatalan 30% (tiga puluh persen) dari pembayaran yang dilakukan oleh Pelanggan.
                      </li>
                    </ol>
                  </li>
                </ol>
                </li>

                <li className='font-weight-bold'>Asuransi
                  <ol className='num'>
                    <li className='font-weight-light num'>Ritase menyediakan pilihan kepada Pelanggan untuk menggunakan
                      jasa asuransi pengiriman dari perusahaan asuransi mitra Ritase, yang besarannya ditetapkan
                      kemudian.
                    </li>
                    <li className='font-weight-light num'>Apabila Pelanggan tidak mengasuransikan barang yang akan
                      dikirim, maka Pelanggan bersedia untuk menanggung resiko sendiri dan setuju untuk mengganti
                      kerugian yang timbul.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Disclaimer
                  <ol className='num'>
                    <li className='font-weight-light num'>Segala bentuk kejadian tak terduga dan/atau kerugian yang
                      yang timbul selama pengangkutan menjadi tanggung jawab Pelanggan sepenuhnya.
                    </li>
                    <li className='font-weight-light num'>Ritase tidak bertanggungjawab terhadap segala bentuk
                      kerusakan/kehilangan/kerugian/kematian yang diderita oleh Pelanggan selama pengangkutan
                      berlangsung dan/atau disebabkan oleh Pelanggan.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Hak Kekayaan Intelektual
                  <ol className='num'>
                    <li className='font-weight-light num'>Semua hak kekayaan intelektual, termasuk hak cipta, paten,
                      merek dagang dan rahasia dagang dalam Website dan Layanan Ritase dimiliki oleh Ritase atau pemberi
                      lisensi Ritase.
                    </li>
                    <li className='font-weight-light num'>Pemberian jasa ini tidak mengalihkan kepada anda atau pihak
                      ketiga manapun, setiap hak, hak milik atau kepentingan dalam atau untuk hak kekayaan intelektual
                      tersebut.
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Force Majeure
                  <ol className='num'>
                    <li className='font-weight-light num'>Pelanggan akan membebaskan Ritase dari segala tuntutan
                      apapun, dalam hal Ritase tidak dapat melaksanakan perintah dari Pelanggan baik sebagian maupun
                      seluruhnya karena kejadian-kejadian atau sebab-sebab di luar kekuasaan atau kemampuan Ritase
                      termasuk namun tidak terbatas pada segala gangguan virus komputer atau sistem Trojan Horses atau
                      komponen membahayakan yang dapat menggangu Layanan Ritase, web browser atau komputer sistem
                      Ritase, karena bencana alam, perang, huru-hara, keadaan peralatan, sistem atau transmisi yang
                      tidak berfungsi, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, serta
                      kejadian-kejadian atau sebab-sebab lain di luar kekuasaan atau kemampuan Ritase
                    </li>
                  </ol>
                </li>

                <li className='font-weight-bold'>Lain-lain
                  <ol className='num'>
                    <li className='font-weight-light num'>Ritase dapat mengubah Syarat dan Ketentuan ini dari waktu ke
                      waktu dan berhak untuk melakukannya tanpa memberikan pemberitahuan terlebih dahulu. Segala
                      perubahan Syarat dan Ketentuan akan ditampilkan di Website Ritase.
                    </li>
                    <li className='font-weight-light num'>Syarat dan Ketentuan ini tunduk kepada hukum Negara Republik
                      Indonesia.
                    </li>
                    <li className='font-weight-light num'>Setiap dan semua perbedaan pendapat, perselisihan,
                      permintaan, tuntutan atau dasar gugatan apapun antara yang berhubungan dengan Syarat dan Ketentuan
                      ini akan diselesaikan melalui musyawarah mufakat. Namun apabila musyawarah tidak mencapai mufakat
                      maka harus diselesaikan melalui arbitrase yang mengikat dan rahasia. Arbitrase tunduk pada
                      ketentuan BANI.
                    </li>
                  </ol>
                </li>
              </ol>
            </dl>
            <button className='btn btn-sm btn-primary' onClick={() => {
              window.history.back()
            }}>Kembali</button>
          </div>
        </div>
      </div>
    )
  }
}
