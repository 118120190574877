const initialState = ''

function depo(state = initialState, action) {
  if (action.type === 'orders.depo') {
    return action.payload;
  } else {
    return state
  }
}

export default depo;