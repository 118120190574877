import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
// import {sortableElement, sortableHandle} from 'react-sortable-hoc';

import PropTypes from 'prop-types';

// need improvement next time
// filter contain string location
const filterSuggestionPlaces = location => (data, i) => {
  const termLocation = data.terms;
  const provinceIndex = termLocation[data.terms.length-1];
  const cityIndex = termLocation[data.terms.length-2];
  let exist = true;
  if (location.province && Array.isArray(location.province) && provinceIndex) {
    if (location.province.some(s => provinceIndex.value.indexOf(s) > -1)) {
      exist = true;
    } else {
      exist = false;
    }
  }
  if (location.city && Array.isArray(location.city) && cityIndex) {
    if (location.city.some(s => (cityIndex.value.indexOf(s) > -1))) {
      exist = true;
    } else {
      exist = false;
    }
  }
  return exist;
}

class LocationInput extends React.Component {
  render() {
    const AutocompleteComponent = ({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
      const loader = (loading && <div className="p-2 bg-white border">Loading...</div>);
      const suggestionRender = (suggestion, idx) => {
        const className = suggestion.active
          ? 'suggestion-item--active p-2 border suggestion text-success'
          : 'suggestion-item p-2';
        // inline style for demonstration purpose
        const style = suggestion.active
          ? {backgroundColor: '#e1e1e1', cursor: 'pointer'}
          : {backgroundColor: '#ffffff', cursor: 'pointer'};

        return (
          <div
            {...getSuggestionItemProps(suggestion, {className, style})}
            key={idx}>
            <h6>{suggestion.description}</h6>
          </div>
        );
      };
      const suggestionsFiltered = this.props.filter ? suggestions.filter(filterSuggestionPlaces(this.props.filter)) : suggestions;
      const suggestionItems = suggestionsFiltered.map(suggestionRender);
      const OriginHandle = () => <div><img src="/assets/img/marker/start-point.svg" alt='start'
        style={{maxWidth: "10px"}}/></div>;
      const DragHandle =() => (<div>
        {!this.props.hideDragHandle &&
          <img src="/assets/img/icon/dragable.svg" alt="" style={{height: '15px', marginRight: '10px', cursor: 'move'}}/>
        }
        <img
        src={this.props.isLast ? "/assets/img/marker/end-point.svg" : "/assets/img/marker/drop-point.svg"}
        style={{maxWidth: "10px", cursor: this.props.hideDragHandle ? 'default' : 'move'}} alt='marker'/>
      </div>);
      const Handle = this.props.origin ? OriginHandle : DragHandle;

      return (
        <div className="form-row" style={{margin: 0}}>
          <div className="form-group input-group border rounded mb-2">
            {!this.props.hideHandle &&
              <span className="input-group-append drag-handle">
                <span className="input-group-text border-0 transparent">
                  <Handle/>
                </span>
              </span>
            }
            <input
              {...getInputProps({
                placeholder: this.props.placeholder,
                className: 'form-control form-control border-0 s-14',
                disabled: this.props.disabled
              })}
            />
            <span className="input-group-append">
              <span className="input-group-text add-on transparent border-0">
                {this.props.children}
              </span>
            </span>
          </div>
          <div className="col-12">
            <div className="autocomplete-dropdown-container"
              style={{marginTop: '-2%', width: '97%', position: 'absolute', display: 'block', zIndex: '1'}}>
              {loader}
              {suggestionItems}
            </div>
          </div>
        </div>
      )
    };
    return (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.props.onChange}
        onSelect={this.props.onSelect}
      >
        {AutocompleteComponent}
      </PlacesAutocomplete>
    );
  }
}

LocationInput.propTypes = {
  children: PropTypes.element,
  address: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  origin: PropTypes.bool,
  isLast: PropTypes.bool,
  filter: PropTypes.object,
};

export default LocationInput;
