import React from 'react';
import PropTypes from 'prop-types'
import {Link, withRouter} from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import './Stepper.css'

const style = {
  colorPrimary: '#27a397',
  colorSecondary: '#f27130',
};


const Step = ({ label, icon, selected, idx, currentIdx, path, disabled, handleClick, lastItem}) => {
  const content = (
    <>
      <div className={selected || idx < currentIdx ? 'bg-step center text-ritase' : 'bg-step center' }>
        <img src={selected || idx < currentIdx ? icon[0] : icon[1]} alt={label}></img>
      </div>
      <div className='mt-1'>{idx + 1}. {label}</div>
    </>
  );
  if (disabled) {
    return (
      <div
        className="stepper-item"
        disabled={disabled}
        style={{ color: selected ? style.colorPrimary : '#9e9e9e' }}
      >
        {content}
        {!lastItem && <ArrowForwardIcon className="arrow"/>}
      </div>
    );
  }
  return (
    <Link
      to={path}
      onClick={handleClick}
      className="stepper-item"
      style={{ color: selected || idx < currentIdx ? style.colorPrimary : '#9e9e9e' }}
    >
      {content}
      {!lastItem && <ArrowForwardIcon className="arrow" />}
    </Link>
  );
};

const Stepper = ({model, location, onClick}) => {
  const current = model.steps.findIndex(s => s.path === location.pathname);
  return (
    <div className="stepper-container">
      {model.steps.map((step, idx) => (
        <Step
          key={step.label}
          label={step.label}
          icon={step.icon}
          selected={location.pathname === step.path}
          path={step.path}
          currentIdx={current}
          idx={idx}
          lastItem={idx + 1 === model.steps.length}
          disabled={idx > model.lastGoodStep}
          handleClick={() => idx < model.lastGoodStep ? onClick(idx) : null}
        />
      ))}
    </div>
  )
};

Step.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.array,
  selected: PropTypes.bool,
  handleClick: PropTypes.func,
  idx: PropTypes.number,
  currentIdx: PropTypes.number,
  lastItem: PropTypes.bool,
  disabled: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

Stepper.propTypes = {
  model: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    lastGoodStep: PropTypes.number.isRequired,
  }),
  onClick: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Stepper);
