import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import auth from '../../lib/auth';
import network from '../../lib/network';
import utils from '../../lib/utils';
import Swal from 'sweetalert2';
import Pulse from "../spinner/Pulse";

class OrderContainer extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      isLoading: true,
      search: '',
      isDownload: false
    }
  }
  
  componentDidMount () {
    this.getList()
  }

  getList = async (referenceNumber) => {
    if (auth.check()) {
      let params = {
        populate: 'gatepass'
      };
      if (referenceNumber) {
        params.reference_number = referenceNumber
      }

      const response = await network.GET('/econ/list', params, {}, true);

      var data = [];
      if (response.errorMessage) {
        Swal.fire({
          type: 'error',
          text: 'Error: ' + (response.errorMessage && response.errorMessage.length > 100 ? `${response.errorMessage.substring(0, 100)} ...` : response.errorMessage),
        });
      } else {
        data = _.get(response, 'rows', []);
      }

      this.setState({
        data,
        isLoading: false
      })
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  search = e => {
    if (!e || !e.keyCode || e.keyCode === 13) {
      this.getList(this.state.search);
    }
  }

  onDownloadProforma = async (referenceNumber) => {
    this.setState({
      isDownload: true
    });
    const response = await network.GET('/econ/proforma/calculation/export', { referenceNumber: referenceNumber }, {}, true);
    this.setState({
      isDownload: false
    });
    window.open(response.url, 'blank');
  }

  render() {
    const { isLoading, data, isDownload } = this.state;

    return (
      <Fragment>
        <Navbar />
        <Sidebar />
        <Content>
          <div id="primary" className="p-t-b-50 height-full">
            <div className="container">
              <div className="row" style={{overflowX: 'scroll'}}>
                <div className="col-lg-12 mx-md-auto">
                  <div className="text-center">
                    <h3 className="mt-2">NPCT-1 Gate Pass</h3>
                  </div>
                  <div className="mt-5 row">
                    <div className="col-5">
                      <div class="dropdown d-inline-block">
                        <button class="btn btn-primary" type="button" id="importGroupButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="icon-sign-in"></i>&nbsp; Import
                        </button>
                        <div class="dropdown-menu" aria-labelledby="importGroupButton" style={{ padding: '0px'}}>
                          <Link
                            to='/npct1/delivery-order/import'
                          >
                            <button type="button" className="btn btn-primary btn-block">
                              Gate Pass Order
                            </button>
                          </Link>
                          <Link
                            to='/npct1/delivery-order/extension'
                          >
                            <button type="button"  className="btn btn-primary btn-block">
                              Gate Pass Extension
                            </button>
                          </Link>
                        </div>
                      </div>
                    
                      <Link 
                        to='/npct1/delivery-order-export'
                        className="btn btn-primary s-14 ml-4"
                      >
                        <i className="icon-sign-out"></i>&nbsp; Export
                      </Link>
                    </div>
                    <div className="col-4 offset-3 text-right">
                      <div className="form-group">
                        <div className="input-group input-scheduled">
                          <input 
                            id="search"
                            type="text" 
                            className="form-control" 
                            placeholder="Cari Reference Number" 
                            value={this.state.search}
                            onChange={this.handleChange}
                            onKeyUp={(e) => this.search(e)}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="icon icon-search" />
                            </span>
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          Kolom ini harus diisi
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="mt-2 bg-white black-text table">
                    <thead className="bg-ritase white-text">
                      <tr>
                        <th>Reference Number</th>
                        <th>Proforma Number</th>
                        <th>Paid Through</th>
                        <th>Type</th>
                        <th>Total Payment</th>
                        <th>Status Payment</th>
                        <th style={{width: '80px'}}></th>
                      </tr>
                    </thead>
                    { isLoading ?
                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center">Loading ...</td>
                        </tr>
                      </tbody>
                    : data.length === 0 ?
                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center">No Data Found</td>
                        </tr>
                      </tbody>
                    : isDownload ? 
                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center">
                            <Pulse color="#27A397" />
                          </td>
                        </tr>
                      </tbody>
                    : <tbody>
                        {data.map((item, i) => (
                          <tr key={i}>
                            <td>{ item.reference_number }</td>
                            <td>
                              <Link to={`/npct1/proforma-calculation/${item.reference_number}`}>
                                { item.proforma_number }
                              </Link>
                              &nbsp;&nbsp;
                              <a onClick={ () => { this.onDownloadProforma(_.get(item, 'reference_number')) } } style={{ 'cursor': 'pointer' }}>
                                <i className="icon-download" style={{ 'background': 'none', 'color': '#f27130' }}></i>
                              </a>
                            </td>
                            <td>{ _.get(item, 'invoice_due_date', false) && format(new Date(item.invoice_due_date), 'dd MMMM yyyy') }</td>
                            <td>{ _.get(item, 'gatepass[0].gatepass_type', '') } { _.get(item, 'gatepass[0].gatepass_type') && _.get(item, 'is_extension') == 1 ? 'EXTENSION' : '' }</td>
                            <td>{ _.get(item, 'total_payment', false) ? utils.formatRupiah(item.total_payment) : '' }</td>
                            <td>{ _.upperCase(_.get(item, 'status_payment', '-')) }</td>
                            <td style={{width: '80px'}}>
                              {_.get(item, 'status_payment', '').toLowerCase() === 'paid' &&
                                <div>
                                  <Link 
                                    to={`/npct1/gatepass/${_.get(item, 'reference_number', '')}`}
                                    className="btn btn-primary btn-block s-14 bg-ritase mb-2"
                                  >
                                    <i className="icon-ticket"></i>&nbsp; Gate Pass
                                  </Link>
                                </div>
                              }
                              <div>
                                <Link 
                                  to={`/npct1/invoice/${encodeURIComponent(_.get(item, 'proforma_number', ''))}`}
                                  className="btn btn-primary btn-block s-14 mb-2"
                                >
                                  <i className="icon-book"></i>&nbsp; View Invoice
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>  
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

export default OrderContainer;