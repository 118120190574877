import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../spinner/Pulse";
import _ from 'lodash';

class Step2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: '',
      selectedContainer: this.props.selectedContainer || []
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleCheckboxChange = (e, container) => {
    let selectedContainer = [...this.state.selectedContainer];
    container.checked = e.target.checked;

    if (e.target.checked) {
      selectedContainer.push(container);
    } else {
      selectedContainer = _.filter(selectedContainer, con => con.contNo !== container.contNo);
    }

    this.setState({ selectedContainer });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true
    });
    
    const { selectedContainer } = this.state;
    if (selectedContainer.length > 0) {
      this.props.setImportGatepass({ selectedContainer });
      this.props.setStep(3);
    } else {
      Swal.fire('', 'Anda harus memilih container', 'error');
    }

    this.setState({
      loading: false
    });
  };

  filterContainer = () => {
    let containers = _.get(this.props, 'step1.containers', []);
    const { search } = this.state;
    if (search.length > 0) {
      return _.filter(containers, container => _.get(container, 'contNo', '').toLowerCase().includes(search.toLowerCase()));
    }

    return containers;
  }

  render() {
    const { step1, parseToDate } = this.props;
    const { selectedContainer } = this.state;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">List Container</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <div className="row mt-5">
            <div className="col-12">
              <h4>Data Vessel</h4>
              <div className="form-group">
                <label>Vessel / Voyage</label>
                <p>{ _.get(step1, 'orders.vesselName', '') } / { _.get(step1, 'orders.voyageIn', '') }</p>
              </div>
              <div className="form-group">
                <label>POD / SPOD</label>
                <p>{ _.get(step1, 'orders.pod', '').split('|')[0] } / { _.get(step1, 'orders.spod', '').split('|')[0] }</p>
              </div>
              <div className="form-group">
                <label>Agent</label>
                <p>{ _.get(step1, 'orders.agent', '').split('|')[0] }</p>
              </div>
            </div>
          </div>
          
          <div className="row mt-5">
            <div className="col-12">
              <h4>List Container</h4>
              <div className="mt-3 d-flex align-items-center">
                <div className="mr-3">Pencarian</div>
                <input 
                  id="search"
                  type="text" 
                  className="form-control" 
                  placeholder="Kata Kunci" 
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="mt-4">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      {/* <th><input type="checkbox" className="form-control"/></th> */}
                      <th></th>
                      <th>CONTAINER NO</th>
                      <th>ISO CODE</th>
                      <th>SIZE</th>
                      <th>F/E</th>
                      <th>DG</th>
                      <th>OOG</th>
                      <th>REEFER</th>
                      <th>DISCHARGE</th>
                      <th>PLUG IN</th>
                      <th>PLUG OUT</th>
                      <th>PAID THROUGH</th>
                      <th>BILLING</th>
                      <th>BEHANDLE</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.filterContainer().length > 0 ?
                      this.filterContainer().map((container, i) => (
                        <tr key={i}>
                          <td>
                            <input 
                              type="checkbox" 
                              className="form-control"
                              onChange={e => this.handleCheckboxChange(e, container)} 
                              checked={!!_.find(selectedContainer, con => con.contNo === container.contNo)}
                            /></td>
                          <td>{ container.contNo }</td>
                          <td>{ container.isocode }</td>
                          <td>{ container.size }</td>
                          <td>{ container.fullEmpty }</td>
                          <td>{ container.dg }</td>
                          <td>{ container.oogs }</td>
                          <td>{ container.reefer }</td>
                          <td>{ parseToDate(container.inTime, 'dateTime') }</td>
                          <td></td>
                          <td></td>
                          <td>{ parseToDate(container.paidThroughDate, 'dateTime') }</td>
                          <td>{ container.billing || '-' }</td>
                          <td>{ container.behandle || '-' }</td>
                          <td>{ container.status }</td>
                        </tr>
                      ))
                    :
                      <tr>
                        <td colSpan={15} className="text-center">
                          Not found
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-3">
            <div className="col-sm-6">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => this.props.setStep(1)}>{this.state.loading ? <Pulse/> : 'Sebelumnya'}</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary btn-lg btn-block">
                {this.state.loading ? <Pulse/> : 'Selanjutnya'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.import_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setImportGatepass: (payload) => dispatch({type: 'orders.import_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
