import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'
import './Routes.css'

import VehicleRow from './VehicleRow'
import Locations from "../LocationsJabodetabek";

import firebase from 'firebase/app'
import {DB_CONFIG} from "../../config";
import {addHours} from 'date-fns';

import Swal from 'sweetalert2'
import Bar from '../spinner/Bar'
import utils from '../../lib/utils';

import $ from 'jquery'

import DateFnsUtils from "@date-io/date-fns";
import idLocale from "date-fns/locale/id";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import auth from "../../lib/auth";
import network from "../../lib/network";
import Pulse from "../spinner/Pulse";

let fb = firebase;

class Routes extends Component {
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      fb = firebase.initializeApp(DB_CONFIG);
    }
    this.database = {
      addon_form: fb.database().ref('/addon_form'),
      rates: fb.database().ref('/rates'),
      vehicle_type: fb.database().ref('/vehicle_type'),
    };

    this.state = {
      vehicle_type: [],
      addon_form: [],
      rates: [],
      est_price: 0
    }
  }

  processResponse = (response) => {
    if (!response.valid) {
      Swal.fire('', 'Order anda tidak valid. Mohon pastikan anda telah mengisi detail dengan benar.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    if (response.errorMessage) {
      Swal.fire('', 'Maaf, kami tidak dapat membuat order anda untuk saat ini. Mohon coba kembali dalam beberapa saat.', 'error');
      this.setState({validOrder: false, loading: false});
      return;
    }
    this.setState({
      est_price: response.price,
      loading: false
    })
  };

  checkPrice = async () => {
    this.setState({loading: true});

    try {
      utils.validateOrder({
        locations: this.props.orders.locations,
        pickup_time: this.props.pickupTime,
        pickup_type: this.props.pickup_type,
        vehicle_type: this.props.vehicle_type,
        vehicle_area: this.props.vehicle_area,
      }, 0);
    } catch (e) {
      Swal.fire({
        type: 'error',
        text: e.message,
      })
    }

    let locations = this.props.orders.locations;
    let post_locations = [];
    locations.map((i) => {
      return post_locations.push({
        ...i,
        detail: 'ritase',
        name: 'ritase',
        phone_number: '0813371337',
      })
    });

    const body = {
      locations: post_locations,
      pickupType: this.props.orders.pickup_type.pType,
      vehicleType: this.props.orders.vehicle_type,
      remarks: this.props.orders.remarks,
      addons: {
        check_fee: true
      },
    };
    if (this.props.orders.pickup_type.pType === 'SCHEDULED')
      body.pickupTime = this.props.orders.pickup_time.toISOString();

    try {
      this.setState({
        timestamp: (new Date()).getTime()
      });
      const response = await network.POST('/orders/retail/check_fee', {
        timestamp: this.state.timestamp,
        ...body
      }, {}, auth.check());
      this.processResponse(response, body.addons.promo);
      return;
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
      this.setState({
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.props.scrollToTop();
    this.props.setStep();
    for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({[key]: snap.val()});
      });
    }
  }

  render() {

    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#ffffff',
        },
        error: {
          light: '#27A397',
          main: '#27A397',
          dark: '#27A397',
          contrastText: '#27A397',
        },
      },
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#27A397',
          },
        },
        MuiPickersDay: {
          day: {
            color: '#2d2d2d',
          },
          daySelected: {
            backgroundColor: '#27A397',
          },
          dayDisabled: {
            color: '#d4d4d4',
          },
          current: {
            color: '#f24d07',
          },
        },
      },
    });

    const datePicker = (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <ThemeProvider theme={ritaseTheme}>
        <DateTimePicker
          className='form-control bg-white'
          theme={ritaseTheme}
          format="dd MMM yyyy HH:mm"
          ampm={false}
          minutesStep={5}
          onChange={this.props.pickPickupTime}
          value={this.props.pickupTime}
          minDate={this.timeUtils(1)}
          maxDate={this.timeUtils(4)}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>);
    
    const notAllowedVehicleType = [...utils.npct_vehicles_id, ...utils.ltl_vehicles_id]

    return (
      <div id="info">
        <h6 className="mb-2"><b>Jenis Truk</b></h6>
        <div className="mb-4 bg-white border rounded">
          {this.state.vehicle_type.length > 0 ? this.state.vehicle_type.filter(vehicle => !notAllowedVehicleType.includes(vehicle.id)).map((row, idx, arr) => (
            <VehicleRow
              locations={this.props.orders.locations}
              key={row.id}
              checked={this.props.vehicle_type === row.id}
              onChange={this.props.pickVehicle(row)}
              tooltip_url={row.tooltip_url}
              area="hasJabodetabek"
              rate={
                /*orders.hasSumatera(this.props.orders.locations) ? this.state.rates['Sumatera'][row.id] : this.state.rates['Jawa Bali'][row.id]*/
                this.state.rates
              }
              first={idx === 0}
              last={idx === arr.length - 1}
              {...row}
            />
          )) : <Bar/>}
        </div>

        <h6 className="mb-2"><b>Waktu Penjemputan</b></h6>
        <div className="pl-3 pr-3">
          <div className="rounded row text-center bg-white">
            <label className="time-picker col-6 p-0 m-0">
              <input checked={this.props.pickup_type.pType === 'EXPRESS'}
                onChange={this.props.setPickupType({pType: 'EXPRESS'})}
                type="radio" name="pickup" value="cepat"/>
              <div className="border rounded-left p-2 s-12">
                <i className="icon icon-timer mr-3 s-14" />
                Cepat
              </div>
            </label>
            <label className="time-picker col-6 p-0 m-0 text-center">
              <input checked={this.props.pickup_type.pType === 'SCHEDULED'}
                onChange={this.props.setPickupType({pType: 'SCHEDULED'})}
                type="radio" name="pickup" value="terjadwal"/>
              <div className="border rounded-right p-2 s-12">
                <i className="icon icon-calendar mr-3 s-14"/>
                Terjadwalkan
              </div>
            </label>
          </div>
        </div>

        {this.props.pickup_type.pType === 'EXPRESS' ?
          <div className="pl-3 pr-3">
            <div className="rounded mt-2 row text-center">
              {/*<label className="time-picker col-6 p-0 m-0 bg-white">
                <input disabled checked={this.props.pickup_type.fast === 1}
                  onChange={() => this.props.pickPickupTime(new Date(this.timeUtils(2)))} type="radio"
                  name="pickupTime"/>
                <div
                  className="border rounded-left p-2"
                  style={{
                    backgroundColor: '#bfbfbf',
                    color: 'white',
                  }}
                >
                  <strong>3 Jam</strong>
                </div>
              </label>*/}
              <label className="time-picker col-12 p-0 m-0 bg-white text-center">
                <input checked={this.props.pickup_type.fast === 2}
                  onChange={() => this.props.pickPickupTime(new Date(this.timeUtils(3)))} type="radio"
                  name="pickupTime"/>
                <div className="border rounded p-2" title='Unit akan sampai maksimal 6 jam dari waktu pemesanan.'>
                  <strong>6 Jam</strong><i
                    className={`icon icon-info2 info-tooltip ml-2 s-18 ${this.props.pickup_type.fast === 2 ? 'text-white' : ''} cur-help`}
                    onClick={() => {
                      Swal.fire({
                        html: `Unit akan sampai maksimal 6 jam dari waktu pemesanan.<br>
                      Catatan: Express hanya dapat dipesan mulai pukul <br><b>01.00 sampai 14.00.</b>`,
                        onBeforeOpen: function () {
                          $('button.swal2-confirm').removeClass('swal2-confirm').removeAttr("style");
                        },
                        customClass: {
                          confirmButton: 'btn btn-primary btn-xs',
                        },
                      })
                    }}/></div>
              </label>
            </div>
          </div> : null}

        {this.props.pickup_type.pType === 'SCHEDULED' ?
          <div className="row">
            <div className="pl-3 input-group pt-2 pb-2 col-12 pl-0 input-scheduled">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="icon icon-calendar" />
                </span>
              </div>
              {datePicker}
            </div>
          </div> : null
        }

        <h6 className="mb-2 mt-4"><b>Asal dan Tujuan</b></h6>
        <div className="bg-white">
          <Locations/>
        </div>

        <div className="mt-4">
          <button className='btn btn-xs btn-outline-ritase mr-3 r-5'
            onClick={() => this.checkPrice()}>{this.state.loading ?
              <Pulse size={10} color={'#27A397'}/> : 'Hitung Estimasi'}</button>
          <span>{utils.formatRupiah(this.state.est_price)}</span>
        </div>
        <div className="mt-2">
          <button className="btn btn-primary" onClick={() => this.nextStep(this.props)} style={{width: '100%'}}>
            Selanjutnya
          </button>
        </div>
      </div>
    )
  }

  timeUtils(what) {
    let d = new Date();
    switch (what) {
    case 1: /*Terjadwal*/
      return d.setHours(d.getHours() + 7, 0, 0, 0);
    case 2: /*Cepat 3 Jam Terjadwal*/
      this.props.setPickupType({fast: 1})();
      return d.setHours(d.getHours() + 3, d.getMinutes() + 5, 0, 0);
    case 3: /*Cepat 6 Jam Terjadwal*/
      this.props.setPickupType({fast: 2})();
      return d.setHours(d.getHours() + 6, d.getMinutes() + 5, 0, 0);
    case 4: /*Max*/
      return d.setHours(d.getHours() + 168 /*7day*/, d.getMinutes() + 5, 0, 0);
    default:
      return null;
    }
  }

  nextStep(props) {
    try {
      utils.validateOrder({
        locations: props.locations,
        pickup_time: props.pickupTime,
        pickup_type: props.pickup_type,
        vehicle_type: props.vehicle_type,
        vehicle_area: props.vehicle_area
      }, 0);
      window.location.href = '#/dashboard/orders/contact';
      props.handleNext()
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      })
    }
  }

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }
}

Routes.propTypes = {
  orders: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  vehicle_type: PropTypes.number,
  vehicle_area: PropTypes.array,
  pickVehicle: PropTypes.func,
  setPickupType: PropTypes.func,
  pickupTime: PropTypes.object,
  pickPickupTime: PropTypes.func,
  pickup_type: PropTypes.shape({
    pType: PropTypes.string,
    fast: PropTypes.number,
  }).isRequired,
  setStep: PropTypes.func,
  scrollToTop: PropTypes.func,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  vehicle_area: state.orders.vehicle_area,
  vehicle_type: state.orders.vehicle_type,
  pickupTime: state.orders.pickup_time,
  locations: state.orders.locations,
  pickup_type: state.orders.pickup_type,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch({type: 'orders.step.goto', payload: 0}),
  handleNext: () => dispatch({type: 'orders.step.next'}),
  pickVehicle: (vehicle) => () => {
    dispatch({type: 'orders.vehicle_type', payload: vehicle.id});
    dispatch({type: 'orders.vehicle_area', payload: {
      routes: vehicle.routes,
    }});
  },
  setPickupType: (type) => () => {
    if (type.pType === 'SCHEDULED')
      dispatch({type: 'orders.pickup_time', payload: addHours(new Date(), 6.5)});
    else
      dispatch({type: 'orders.pickup_time', payload: null});

    dispatch({type: 'orders.pickup_type', payload: type})
  },
  pickPickupTime: (pickupTime) => {
    dispatch({type: 'orders.pickup_time', payload: pickupTime})
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
