import React, {Component} from 'react'
import network from "../lib/network";
import * as Swal from "sweetalert2";
import PropTypes from 'prop-types'
import Bar from "./spinner/Bar";
import $ from "jquery"
import './done.css'
import CardOrder from './CardOrder';
import { withRouter } from 'react-router-dom';
import utils from '../lib/utils';
import DetailOrderModal from './DetailOrderModal';
import "react-image-lightbox/style.css";
import Lightbox from 'react-image-lightbox';
import CardOrderLtl from './CardOrderLtl';
import DetailOrderLtlModal from './DetailOrderLtlModal';
import { Button, ButtonGroup } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import _ from 'lodash';

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      run: {
        bottom: "3%",
        right: "2%",
      },
      pagination: {
        count: false,
        offset: 0,
        current: 1,
        page: 0,
      },
      orders: [],
      loading: true,
      trip_id: "",
      tab: "inprogress",
      is_ltl: false,
      modalDetail: {
        open: false,
        data: {}
      },
      gallery: {
        photoIndex: 0,
        isOpen: false,
        images: []
      }
    };
  }

  handleTab = tab => this.setState({ 
    tab,
    pagination: {
      ...this.state.pagination,
      offset: 0,
      current: 1,
      page: 0,
    }
  }, function () {
    this.populate(this.state.tab, this.state.is_ltl);
  })

  clearSingleitem = () => {
    window.location.href = '/#/dashboard/history';
    this.setState({ trip_id: null }, () => {
      this.populate('inprogress', this.state.is_ltl);
    })
  }

  populate = (type = this.state.tab, is_ltl = this.state.is_ltl) => {
    this.setState({loading: true});
    let statuses = [];
    if (type === 'finished') {
      statuses = is_ltl ? ['50', '60'] : ['done', 'canceled'];
    } else if (type === 'inprogress') {
      statuses = is_ltl ? ['99', '00', '05', '07', '10', '20', '30', '40'] : ['prebooked', 'booked', 'confirmed', 'accepted', 'inprogress'];
    }

    let populate = [];
    if (is_ltl) {
      populate = [
        "pods",
        "origin",
        "destination",
        "status",
        "trip.surcharge_fees",
        "trip.vehicle_type",
        "trip.driver",
        "trip.vehicle",
        "trip.tasks",
        "trip.tasks.location",
        "trip.retail_quotation.shipper_price.previous_price",
        "trip.retail_trip_gatepass",
        "depot_contact.location"
      ]
    } else {
      populate = [
        "orders.pods",
        "orders.origin",
        "orders.destination",
        "surcharge_fees",
        "vehicle_type",
        "driver",
        "vehicle",
        "tasks",
        "tasks.location",
        "retail_quotation.shipper_price.previous_price",
        "retail_trip_gatepass",
        "orders.depot_contact.location"
      ]
    }

    const params = {
      limit: 6,
      offset: this.state.pagination.offset,
      populate: populate.join(","),
    };

    if (this.state.trip_id) {
      if (is_ltl) {
        params.trip_id = this.state.trip_id;
      } else {
        params.id = this.state.trip_id;
      }
    } else {
      if (is_ltl) {
        params.status_id = statuses.join(',');
      } else {
        params.status = statuses.join(',');
      }
    }

    params.is_ftl = is_ltl ? 0 : 1;

    const endpoint = is_ltl ? '/orders/retail' : '/trips';

    network.GET(endpoint, params, {}, true).then((data) => {
      if (data['message']) {
        if (data['message'].includes('not authorized')) {
          network.logout('Sesi anda telah berakhir. Silahkan melakukan Login Ulang.')
        }
      }
      if (data)
        if (is_ltl) {
          _.each(data.rows, item => {
            const custom_prop = JSON.parse(_.get(item, 'custom_prop', '{}') || '{}');
            const angkasa_pura_kargo_price = _.get(custom_prop, 'angkasa_pura_kargo_price', 0) || 0;
            const transport_fee = _.get(custom_prop, 'transport_fee', 0) || 0;
            const koli = _.get(custom_prop, 'koli', 0) || 0;
            // item.fee = angkasa_pura_kargo_price + transport_fee + koli;
          })
        }
        this.setState(prevState => ({
          pagination: {
            ...prevState.pagination,
            count: data.count,
            offset: data.offset,
            page: Math.ceil(data.count / 6)
          },
          orders: data['rows'],
          loading: false
        }))
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        text: err.message,
      });
    });
  };

  handlePagination = (current) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        offset: (current - 1) * 6,
        current: current,
      }
    }));
    setTimeout(() => {
      this.populate()
    }, 0)
  };

  pagination = () => {
    let l = this.state.width < 767 ? 1 : 5; /*limit*/
    let c = this.state.pagination.current - 1; /*current*/
    let len = this.state.pagination.page;
    let start = c - l >= 0 ? c - l : 0;
    let page = [...Array(len).keys()].map(i => i + 1);

    if (len > 6)
      return page.slice(start, c + l);
    return page;
  };

  setGallery = gallery => this.setState({ gallery });

  viewPod = order => {
    const images = [];
    order.pods.map(pod => {
      return images.push(pod.image_url);
    })
    if (images.length === 0) {
      return Swal.fire({
        type: "warning",
        text: "POD belum diupload.",
      });
    }
    this.setGallery({
      photoIndex: 0,
      isOpen: true,
      images,
    });
  };

  componentDidMount() {
    const params = utils.getParams(this.props.location.search);
    this.setState({trip_id: params.get('id')}, () => {
      this.populate();
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const params = utils.getParams(this.props.location.search);
      this.setState({ trip_id: params.get('id') }, () => {
        this.populate();
      });
    }
  }

  resize() {
    this.setState({width: window.innerWidth});
  }

  openDetail(data) {
    this.setState({ modalDetail: { open: true, data } });
  }

  handleLtlChange = value => this.setState({ 
    is_ltl: value,
    loading: true,
    pagination: {
      ...this.state.pagination,
      offset: 0,
      current: 1,
      page: 0,
    }
  }, function () {
    this.populate(this.state.tab, value)
  })

  render() {
    const { gallery } = this.state;
    const ritaseTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#27a397',
        },
      },
    });

    let empty = <div className="col-12 text-center">
      <div className='bg-empty'/>
      <h4>Oops.. Kamu belum memiliki order. Yuks kirim barang dengan Ritase!</h4>
      <a href='/' className="btn btn-lg btn-success mt-2">Buat Order</a>
    </div>;

    return (
      <div className="row m-4">
        <div className="col-md-12">
          <button
            className="btn pr-4 pl-4 btn-lg r-20 bg-ritase fab-left-bottom-fixed text-white"
            style={{
              bottom: this.state.run.bottom,
              right: this.state.run.right,
            }}
            /*onMouseOver={() => {
              this.setState({
                run: {
                  bottom: `${Math.floor(Math.random() * 80) + 1  }%`,
                  right: `${Math.floor(Math.random() * 80) + 1  }%`,
                }
              })
            }}*/
            onMouseDown={() =>
              Swal.fire({
                onBeforeOpen: function () {
                  $("button.swal2-confirm")
                    .removeClass("swal2-confirm")
                    .removeAttr("style");
                },
                customClass: {
                  confirmButton: "btn btn-primary btn-xs",
                },
                html:
                  '<h4 class="mt-5">Untuk bantuan lebih lanjut silakan menghubungi nomor berikut: <span class="font-weight-normal">08118899911</span></h4><a class="btn btn-lg btn-success hidden-lg" href="tel:08118899911">Hubungi</a>',
              })
            }
          >
            <i className="icon icon-phone" />
            Bantuan
          </button>
        </div>
        <div className="col-md-12">
          <div className="card tab-history">
            <div className="card-header">
              {!this.state.trip_id && (
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <button
                      disabled={this.state.loading}
                      style={{ cursor: "pointer" }}
                      className={
                        this.state.tab === "inprogress"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => this.handleTab("inprogress")}
                    >
                      On Progress
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      disabled={this.state.loading}
                      style={{ cursor: "pointer" }}
                      className={
                        this.state.tab === "finished"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => this.handleTab("finished")}
                    >
                      Finished
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div className="card-body">
              {this.state.trip_id && (
                <button
                  type="button"
                  onClick={this.clearSingleitem}
                  className="btn btn-single-item mb-4"
                >
                  <span className="mr-2">{this.state.trip_id}</span>
                  <i className="icon icon-close2" style={{ paddingRight: 0 }} />
                </button>
              )}
              <ThemeProvider theme={ritaseTheme}>
                <ButtonGroup className="mb-4" color="primary">
                  <Button 
                    variant={this.state.is_ltl ? 'outlined' : 'contained'}
                    onClick={() => this.handleLtlChange(false)}
                    style={{ outline: 'none', boxShadow: 'none' }}
                  >
                    FTL
                  </Button>
                  <Button 
                    variant={this.state.is_ltl ? 'contained' : 'outlined'}
                    onClick={() => this.handleLtlChange(true)}
                    style={{ outline: 'none', boxShadow: 'none' }}
                  >
                    LTL
                  </Button>
                </ButtonGroup>
              </ThemeProvider>
              {this.state.loading ? <Bar /> : ""}
              {this.state.orders &&
                !this.state.loading &&
                this.state.orders.map((data) => {
                  if (this.state.is_ltl) {
                    return (<CardOrderLtl
                      key={data.id}
                      item={data}
                      populate={this.populate}
                      openDetail={(moreData = {}) =>
                        this.openDetail({ ...data, ...moreData })
                      }
                    />)
                  }
                  return (
                    <CardOrder
                      key={data.id}
                      item={data}
                      populate={this.populate}
                      openDetail={(moreData = {}) =>
                        this.openDetail({ ...data, ...moreData })
                      }
                    />
                  )
                })}
              {this.state.loading
                ? ""
                : this.state.orders && this.state.orders.length > 0
                ? ""
                : empty}
            </div>
          </div>

          {this.state.pagination.count && !this.state.trip_id && (
            <div className="col-12 mt-4 mb-5">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      this.state.pagination.current === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      tabIndex="-1"
                      onClick={() => {
                        this.handlePagination(
                          this.state.pagination.current - 1
                        );
                      }}
                    >
                      Sebelumnya
                    </button>
                  </li>
                  <li
                    className={`page-item ${
                      this.state.pagination.current > 6 ? "" : "d-none"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => {
                        this.handlePagination(1);
                      }}
                    >
                      1...
                    </button>
                  </li>
                  {this.pagination().map((i) => {
                    return (
                      <li
                        key={i}
                        className={`page-item ${
                          i === this.state.pagination.current ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => {
                            this.handlePagination(i);
                          }}
                        >
                          {i}
                        </button>
                      </li>
                    );
                  })}
                  <li
                    className={`page-item ${
                      this.state.pagination.current >
                      this.state.pagination.page - 5
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => {
                        this.handlePagination(this.state.pagination.page);
                      }}
                    >
                      ...{this.state.pagination.page}
                    </button>
                  </li>
                  <li
                    className={`page-item ${
                      this.state.pagination.current ===
                      this.state.pagination.page
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => {
                        this.handlePagination(
                          this.state.pagination.current + 1
                        );
                      }}
                    >
                      Selanjutnya
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        {this.state.is_ltl ? 
          <DetailOrderLtlModal
            open={this.state.modalDetail.open}
            closeModal={() =>
              this.setState({ modalDetail: { open: false, data: {} } })
            }
            viewPod={this.viewPod}
            item={this.state.modalDetail.data}
          />
        :
          <DetailOrderModal
            open={this.state.modalDetail.open}
            closeModal={() =>
              this.setState({ modalDetail: { open: false, data: {} } })
            }
            viewPod={this.viewPod}
            item={this.state.modalDetail.data}
          />
        }
        {gallery.isOpen && (
          <Lightbox
            mainSrc={gallery.images[gallery.photoIndex]}
            nextSrc={
              gallery.images[(gallery.photoIndex + 1) % gallery.images.length]
            }
            prevSrc={
              gallery.images[
                (gallery.photoIndex + gallery.images.length - 1) %
                  gallery.images.length
              ]
            }
            onCloseRequest={() =>
              this.setGallery({ photoIndex: 0, isOpen: false, images: [] })
            }
            onMovePrevRequest={() =>
              this.setGallery({
                ...gallery,
                photoIndex:
                  (gallery.photoIndex + gallery.images.length - 1) %
                  gallery.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setGallery({
                ...gallery,
                photoIndex: (gallery.photoIndex + 1) % gallery.images.length,
              })
            }
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
          />
        )}
      </div>
    );
  }
}



History.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(History);