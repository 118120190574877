import React from 'react';
import ReactDOM from 'react-dom';
import network from '../network';
import utils from '../utils';
import {addMinutes} from 'date-fns';

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";

import {BCAVACompanyCode} from '../../config';

import './bca.css';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({children, classes, onClose}) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Content = ({response}) => (
  <div>
    <div
      className='row m-1'
      style={{
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div width={'100%'} className='total-amount p-2'>
        <span>
            Jumlah
        </span>
        <h1
          className='float-right'
          style={{
          }}
        >
          {utils.formatRupiah(response.amount)}
        </h1>
      </div>
      <hr
        style={{
          margin: 0,
          borderColor: '#27a397',
        }}
      />
      <div width={'100%'} className='payment-info p-2'>
        <span>
          Payment ID
        </span>
        <span className='float-right'>
          {response.order_id}
        </span>
      </div>
    </div>
    <div
      className='row m-1'
      style={{
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div className='p-2'>
        Harap selesaikan pembayaran sebelum: 
        <span style={{ fontWeight: 500 }}>
          {addMinutes(new Date(response.created_at), 30).toLocaleString()}
        </span>
      </div>
      <hr
        style={{
          margin: 0,
          borderColor: '#27a397',
        }}
      />
      <div className='p-2'>
        <div>
          Nomor rekening
        </div>
        <h1>
          {BCAVACompanyCode} {response.transaction_id}
        </h1>
      </div>
    </div>
  </div>
);

Content.propTypes = {
  response: PropTypes.object,
};

const BcaModal = ({onClose, response}) => {
  const [open, setOpen] = React.useState(true);
  const closeModal = () => {
    setOpen(false);
    onClose();
  }

  return (
    <Dialog open={open} onClose={closeModal} maxWidth='xs' fullWidth>
      <DialogTitle className='text-center' id="customized-dialog-title" onClose={closeModal}>
        BCA VA
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: '#f0f0f0'
        }}
        dividers
      >
        <Content response={response} />
      </DialogContent>
      <DialogContent
        style={{
          backgroundColor: '#27a397',
          color: 'white',
          fontWeight: '900',
        }}
        onClick={closeModal}
      >
        TUTUP
      </DialogContent>
    </Dialog>
  );
}

BcaModal.propTypes = {
  onClose: PropTypes.func,
  response: PropTypes.object,
}

const bca = {}

bca.pay = async (trip_id, { finishLoading }) => {
  const result = await network.POST(`/bca/${trip_id}/pay`, {
    type: 'retail',
  }, {}, true);

  finishLoading();

  // FIXME: open modal with VA number as content
  // block while waiting for modal to be closed
  /* global Promise */
  await new Promise((resolve) => {
    ReactDOM.render(
      <BcaModal onClose={resolve} response={result} />,
      document.getElementById('modal'),
    )
  });

  ReactDOM.unmountComponentAtNode(document.getElementById('modal'));

  return result;
}

export default bca;
