const initialState = '';

function reference_no(state = initialState, action) {
  if (action.type === 'orders.reference_no') {
    return action.payload;
  } else {
    return state
  }
}

export default reference_no;