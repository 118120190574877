const initialState = {
  remove_drop: false,
  add_drop: true,
  optimize: false,
};

function button(state = initialState, action) {
  switch (action.type) {
  case 'orders.button':
    return {
      ...state,
      ...action.payload
    };
  default:
    return state
  }
}

export default button;
