import React, {Component} from 'react'
import {connect} from 'react-redux'
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import gmaps from '../lib/gmaps';
import {gmapsKey} from '../config';
import './Maps.css'
import _ from 'lodash'
import { apkGeojson } from '../lib/ApkHelper';

// eslint-disable-next-line react/prop-types
const Marker = ({idx, arr}) => (
  <div className="marker">
    <span className="marker-label">
      {idx + 1}
    </span>
    <img
      alt=""
      src={idx === 0 ? "/assets/img/marker/start-point.svg" : idx === arr.length - 1 ? "/assets/img/marker/end-point.svg" : "/assets/img/marker/drop-point.svg"}
      style={{
        maxHeight: '40px',
        position: 'relative',
        bottom: '50px',
        right: '7px',
      }}
    />
  </div>
);

class MapsApk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggable: true,
      city: '',
      area: '',
      state: '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      startDragging: {
        id: null,
        lat: 0,
        lng: 0,
        text: ''
      },
      showAddBtn: true,
      showOptimize: false,
      showRemoveBtn: false,
    };
  }

  onChildMouseMove(childKey, childProps, mouse) {
    if (this.props.step !== 0) return;
    if (childProps.idx === 0 && this.props.disableDragOrigin) return;
    this.setState({
      draggable: false,
    });

    if (!mouse) return;
    this.props.updateMarker(Number(childKey), mouse.lat, mouse.lng);
  }

  onChildMouseDown(childKey, childProps) {
    if (this.props.step !== 0) return;
    if (childProps.idx === 0 && this.props.disableDragOrigin) return;
    this.setState({
      startDragging: {
        id: Number(childKey),
        lat: childProps.lat,
        lng: childProps.lng,
        text: childProps.text,
      }
    })
  }

  geocode = ({lat, lng}) => {
    /* global Promise */
    return new Promise((resolve, reject) => {
      /* global google */
      const Geocoder = new google.maps.Geocoder();

      Geocoder.geocode({
        location: { lat, lng }
      }, function (results, status) {
        if (status !== 'OK') {
          reject(status);
        }

        resolve({results, status});
      });
    });
  }

  onChildMouseUp(childKey, childProps, mouse) {
    if (this.props.step !== 0) return;
    if (childProps.idx === 0 && this.props.disableDragOrigin) return;
    this.geocode(mouse).then(
      response => {
        let origin_area = _.get(this.props.locations, '[0].area', '')

        if (!origin_area) {
          Swal.fire({
            position: 'middle',
            type: 'info',
            text: `Silakan pilih Asal terlebih dahulu`,
            showConfirmButton: true,
          })
        } else {
          const destPoint = [mouse.lng, mouse.lat];
          const area = apkGeojson.lookupDestinationArea(destPoint, origin_area);
          
          // console.log('area', area);
          if (!area) {
            this.props.updateMarker(Number(this.state.startDragging.id), this.state.startDragging.lat, this.state.startDragging.lng);
            gmaps.drawRoute(this.props.locations);
  
            let html = '<span>Mohon maaf, saat ini Ritase hanya melayani pengiriman ke pulau yang sama. Jika anda tetap membutuhkan jasa kami silahkan masukan nomer telfon, atau kunjungi <a href="https://ritase.zendesk.com/hc/id/requests/new">link berikut </a></span>';
  
            Swal.fire({
              html,
              input: 'number',
              onOpen: (mod) =>{
                mod.getElementsByClassName('swal2-input')[0].addEventListener("keypress", function (evt) {
                  if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57)
                  {
                    evt.preventDefault();
                  }
                });
              },
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Hubungi Saya',
              showLoaderOnConfirm: true,
              preConfirm: (phone) => {
                return phone
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.value) {
                Swal.fire({
                  position: 'middle',
                  type: 'success',
                  title: 'Terima kasih',
                  text: `Kami akan segera menghubungi Anda di ${result.value}`,
                  showConfirmButton: false,
                  timer: 2000
                })
              }
            });
            return;
          }
          /*if (childProps.idx === 0)
            console.log(`Pengiriman dari ${area}`);*/
  
          const address = response.results[0].formatted_address;
          const zipcode = gmaps.getZipcode(response.results);
          this.props.updateLocation(Number(childKey), mouse.lat, mouse.lng, address, zipcode, area);
        }
      },
      () => {
        Swal.fire('Terjadi Kesalahan', 'Mohon maaf kami tidak dapat memproses pengiriman dari Alamat ini');
        this.props.updateMarker(Number(this.state.startDragging.id), this.state.startDragging.lat, this.state.startDragging.lng);
        gmaps.drawRoute(this.props.locations);
        return false;
      }
    );
    this.setState({
      draggable: true,
    });
    gmaps.drawRoute(this.props.locations)
  }

  render() {
    const defaultMapOptions = {
      fullscreenControl: false,
    };
    return (
      <div id='gmaps-container'>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({map, maps}) => {
            map.setOptions({
              zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER
              },
              styles: [
                {
                  "featureType": "administrative",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#444444"
                    }
                  ]
                },
                {
                  "featureType": "administrative.country",
                  "elementType": "geometry.fill",
                  "stylers": [
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "administrative.province",
                  "elementType": "labels.icon",
                  "stylers": [
                    {
                      "hue": "#ff0000"
                    },
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "landscape",
                  "elementType": "all",
                  "stylers": [
                    {
                      "color": "#f2f2f2"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "all",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "all",
                  "stylers": [
                    {
                      "saturation": -100
                    },
                    {
                      "lightness": 45
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "all",
                  "stylers": [
                    {
                      "visibility": "simplified"
                    }
                  ]
                },
                {
                  "featureType": "road.arterial",
                  "elementType": "labels.icon",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "transit",
                  "elementType": "all",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "all",
                  "stylers": [
                    {
                      "color": "#46bcec"
                    },
                    {
                      "visibility": "on"
                    }
                  ]
                }
              ]
            });
            gmaps.setObject(map, maps);
            gmaps.drawRoute(this.props.locations);
          }}

          bootstrapURLKeys={{
            key: gmapsKey,
            language: 'id',
            region: 'id',
          }}
          center={this.props.center}
          zoom={this.props.zoom}
          defaultOptions={defaultMapOptions}
          draggable={this.state.draggable}
          onChildMouseMove={this.onChildMouseMove.bind(this)}
          onChildMouseDown={this.onChildMouseDown.bind(this)}
          onChildMouseUp={this.onChildMouseUp.bind(this)}
        >
          {this.props.locations.map((location, idx, array) => {
            if (!location.latitude || !location.longitude) return null;
            return <Marker
              arr={array}
              key={location.id}
              lat={location.latitude}
              lng={location.longitude}
              idx={idx}
              draggable={this.props.step === 0}
            />
          })}
        </GoogleMapReact>
      </div>
    )
  }
}

MapsApk.propTypes = {
  center: PropTypes.exact({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  })),
  updateMarker: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

Marker.propTypes = {
  text: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locations: state.orders.locations,
  center: state.orders.maps.center,
  zoom: state.orders.maps.zoom,
  step: state.orders.step,
});

const mapDispatchToProps = (dispatch) => ({
  updateMarker: (id, lat, lng) => {
    dispatch({
      type: 'orders.location.update',
      payload: {
        id,
        latitude: lat,
        longitude: lng,
      }
    });
  },

  updateLocation: (id, lat, lng, address, zipcode, area) => {
    dispatch({
      type: 'orders.location.update',
      payload: {
        id: id,
        latitude: lat,
        longitude: lng,
        address: address,
        zipcode: zipcode,
        area: area,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapsApk);