import auth from './auth';
import {baseURL} from '../config';
import {parseError} from './errors';
import Swal from 'sweetalert2';
import store from '../store';

const network = {};

const queryParams = (params = {}) => {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
};

network.POST = (url, body, headers = {}, includeAuth = false) => {
  const request = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': includeAuth ? auth.get() : null,
      'X-App-Language': 'id',
      ...headers,
    },
    body: JSON.stringify(body),
  };
  return fetch(baseURL + url, request)
    .then(response => response.json())
    .then(parseError('POST', rewriteURL(url)));
};

network.GET = (url, params, headers = {}, includeAuth = false) => {
  return fetch(baseURL + url + '?' + queryParams(params), {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': includeAuth ? auth.get() : null,
      'X-App-Language': 'id',
      ...headers,
    },
  }).then(handleReponse)
    .then(parseError('GET', rewriteURL(url)));
};

network.PUT = (url, body, headers = {}, includeAuth = false) => {
  const request = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': includeAuth ? auth.get() : null,
      'X-App-Language': 'id',
      ...headers,
    },
    body: JSON.stringify(body),
  };
  return fetch(baseURL + url, request)
    .then(handleReponse)
    .then(parseError('PUT', rewriteURL(url)));
};

network.logout = (message, redirect = false) => {
  network.POST('/users/sign_out', {}, {}, true)
    .then(Swal.fire({
      type: 'success',
      text: message || 'Log out berhasil.',
    })).then(function () {
      store.dispatch({ type: 'auth.clear' });
      localStorage.clear();
      sessionStorage.clear();
      if (redirect) {
        window.location.href = `#/login?next=${window.location.hash.substr(1)}`;
        return;
      }
      document.location.reload()
    });
}

network.getMe = () => network.GET("/users/me", {}, {}, true);

const transformers = [
  {
    pattern: 'TDS-TRI-[0-9]{20}',
    key: 'trip_id',
  },
  {
    pattern: 'TDS-ORD-[0-9]{20}',
    key: 'order_id',
  },
  {
    pattern: 'TDS-ADM-[0-9]{20}',
    key: 'user_id',
  },
];

const handleReponse = response => {
  if (response.status === 401) {
    network.logout('Session Habis. Silahkan login kembali.', true);
  }
  return response.json()
}

const replacePattern = ({url, params}, {pattern, key}) => {
  const paramTest = new RegExp(pattern);
  const param = paramTest.exec(url);
  if (param) {
    url = url.replace(param[0], ':' + key);
    params = {
      ...params,
      [key]: param[0],
    }
  }

  return {
    url,
    params,
  }
};

const rewriteURL = (url) => {
  return transformers.reduce(replacePattern, {
    url,
    params: {},
  });
}

export default network;
