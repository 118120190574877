import Login from "../auth/login"
import Register from "../auth/register"
import Dashboard from '../Dashboard'
import Forgot from "../auth/forgot";
import Reset from "../auth/reset";
import TnC from "../components/TnC";
import Profile from '../Profile';
import { GUEST, LOGGED } from "../config";
import ImportGatepass from "../components/ImportGatepass";
import ProformaCalculation from "../components/ImportGatepass/ProformaCalculation";
import Invoice from "../components/ImportGatepass/Invoice";
import ListGatepass from "../components/ImportGatepass/ListGatepass";
import PrintGatepass from "../components/ImportGatepass/PrintGatepass";
import OrderContainer from "../components/ImportGatepass/OrderContainer";
import ExportGatepass from "../components/ImportGatepass/ExportGatepass";

const routes = [
  {
    path: '/forgot',
    component: Forgot,
    when: GUEST,
  },
  {
    path: '/login',
    component: Login,
    when: GUEST,
  },
  {
    path: '/register',
    component: Register,
    when: GUEST,
  },
  {
    path: '/reset',
    component: Reset,
    when: GUEST,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/tnc',
    component: TnC,
  },
  {
    path: '/profile',
    component: Profile,
    when: LOGGED,
  },
  {
    path: '/npct1/delivery-order/:action',
    component: ImportGatepass,
  },
  {
    path: '/npct1/proforma-calculation/:reffNumber',
    component: ProformaCalculation,
  },
  {
    path: '/npct1/invoice/:proformaNo',
    component: Invoice,
  },
  {
    path: '/npct1/gatepass/:reffNumber/print/:tar',
    component: PrintGatepass,
  },
  {
    path: '/npct1/gatepass/:reffNumber',
    component: ListGatepass,
  },
  {
    path: '/npct1/order-container',
    component: OrderContainer,
  },
  {
    path: '/npct1/delivery-order-export',
    component: ExportGatepass,
  }
];

export default routes;
