import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../spinner/Pulse";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import idLocale from "date-fns/locale/id";
import { format } from 'date-fns';
import _ from 'lodash';
import network from '../../lib/network';

class Step1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      search: '',
      requestType: this.props.requestType,
      requestNumber: this.props.requestNumber,
      requestDate: this.props.requestDate ? new Date(this.props.requestDate) : null,
      responseType: this.props.responseType,
      responseNumber: this.props.responseNumber,
      responseDate: this.props.responseDate ? new Date(this.props.responseDate) : null,
      check: {
        requestType: false,
        requestNumber: false,
        requestDate: false,
        responseType: false,
        responseNumber: false,
        responseDate: false,
      },
      actionType: this.props.actionType
    };
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleDateChange = (type, value) => {
    this.setState({
      [type]: value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        requestType: this.state.requestType.length > 0,
        requestNumber: this.state.requestNumber.length > 0,
        requestDate: this.state.requestDate ? true : false,
        responseType: this.state.responseType.length > 0,
        responseNumber: this.state.responseNumber.length > 0,
        responseDate: this.state.responseDate ? true : false,
      }
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      const { requestType, requestNumber, requestDate, responseType, responseNumber, responseDate } = this.state
      let body = {
        action: this.state.actionType,
        requestType,
        requestNumber,
        requestDate: format(new Date(requestDate), 'yyyy-MM-dd'),
        responseType,
        responseNumber,
        responseDate: format(new Date(responseDate), 'yyyy-MM-dd'),
        messageId: this.props.messageId,
      };

      const response = await network.POST(`/econ/customs`, body, {}, true);
      
      if (parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        body.step1 = response.objects;
        this.props.setImportGatepass(body);
        this.props.setStep(2);
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  filterContainer = () => {
    let containers = _.get(this.props, 'step0.containers', []);
    const { search } = this.state;
    if (search.length > 0) {
      return _.filter(containers, container => _.get(container, 'contNo', '').toLowerCase().includes(search.toLowerCase()));
    }

    return containers;
  }

  render() {
    const { ritaseTheme, step0 } = this.props;
    const responseTypeOpt = [
      { key: 'SPB', value: 'SPPB PIB (BC 2.0)' },
      { key: 'B23', value: 'SPPB PIB BC 2.3' },
      { key: 'RP', value: 'RETURNABLE PACKAGE (RP)IMPORT' },
      { key: 'POS', value: 'PAKET POS' },
      { key: 'B16', value: 'SPPB PLB / BC 1.6' }
    ]

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Dokumen Bea Cukai</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <div className="row mt-5">
            <div className="col-md-9">
              <h4>Data Customs Document</h4>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="requestType">
                    Request Type
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <select 
                      name='requestType' 
                      id="requestType" 
                      className={this.state.validate && !this.state.check.requestType ? "form-control s-14 is-invalid" : "form-control s-14"}
                      value={this.state.requestType}
                      onChange={this.handleChange}
                    >
                      <option label="PIB" value="PIB">PIB</option>
                    </select>
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="requestNumber">
                    Request Number
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.requestNumber ? "form-control is-invalid" : "form-control"}
                      value={this.state.requestNumber}
                      onChange={this.handleChange}
                      id="requestNumber"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <label htmlFor="requestDate">
                    Request Date
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className={this.state.validate && !this.state.check.requestDate ? "form-group is-invalid" : "form-group"}>
                    <div className="input-group input-scheduled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon icon-calendar" />
                        </span>
                      </div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
                        <ThemeProvider theme={ritaseTheme}>
                          <DatePicker
                            className='form-control bg-white'
                            theme={ritaseTheme}
                            format="dd MMM yyyy"
                            onChange={(value) => this.handleDateChange('requestDate', value)}
                            value={this.state.requestDate}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="responseType">
                    Response Type
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <select 
                      name='responseType' 
                      id="responseType" 
                      className={this.state.validate && !this.state.check.responseType ? "form-control s-14 is-invalid" : "form-control s-14"}
                      value={this.state.responseType}
                      onChange={this.handleChange}
                    >
                      {responseTypeOpt.map(opt => (
                        <option key={opt.key} label={opt.value} value={opt.key}>{opt.value}</option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="responseNumber">
                    Response Number
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className="form-group">
                    <input type="text"
                      placeholder='Enter Request Number'
                      className={this.state.validate && !this.state.check.responseNumber ? "form-control is-invalid" : "form-control"}
                      value={this.state.responseNumber}
                      onChange={this.handleChange}
                      id="responseNumber"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <label htmlFor="responseDate">
                    Response Date
                    <i className="s-18 text-danger cur-help" onMouseDown={() => {
                      Swal.fire('', 'Bagian ini harus diisi', 'info');
                    }}>*</i>
                  </label>
                  <div className={this.state.validate && !this.state.check.responseDate ? "form-group is-invalid" : "form-group"}>
                    <div className="input-group input-scheduled">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon icon-calendar" />
                        </span>
                      </div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
                        <ThemeProvider theme={ritaseTheme}>
                          <DatePicker
                            className='form-control bg-white'
                            theme={ritaseTheme}
                            format="dd MMM yyyy"
                            onChange={(value) => this.handleDateChange('responseDate', value)}
                            value={this.state.responseDate}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-3">
              <h4>Data Vessel</h4>
              <div className="form-group">
                <label>Vessel / Voyage</label>
                <p>{ _.get(step0, 'orders.vesselName', '') } / { _.get(step0, 'orders.voyageIn', '') }</p>
              </div>
              <div className="form-group">
                <label>POD / SPOD</label>
                <p>{ _.get(step0, 'orders.pod', '').split('|')[0] } / { _.get(step0, 'orders.spod', '').split('|')[0] }</p>
              </div>
              <div className="form-group">
                <label>Agent</label>
                <p>{ _.get(step0, 'orders.agent', '').split('|')[0] }</p>
              </div>
            </div>
          </div>
          
          <div className="row mt-5">
            <div className="col-12">
              <h4>List Container</h4>
              <div className="mt-3 d-flex align-items-center">
                <div className="mr-3">Pencarian</div>
                <input 
                  id="search"
                  type="text" 
                  className="form-control" 
                  placeholder="Kata Kunci" 
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="mt-4">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>CONTAINER NO</th>
                      <th>SIZE</th>
                      <th>F/E</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.filterContainer().length > 0 ?
                      this.filterContainer().map((container, i) => (
                        <tr key={i}>
                          <td>{ container.contNo }</td>
                          <td>{ container.size }</td>
                          <td>{ container.fullEmpty }</td>
                        </tr>
                      ))
                    :
                      <tr>
                        <td colSpan={3} className="text-center">
                          Not found
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-3">
            <div className="col-sm-6">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => this.props.setStep(0)}>{this.state.loading ? <Pulse/> : 'Sebelumnya'}</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={() => {
                this.setState({
                  validate: true
                })
              }}>{this.state.loading ? <Pulse/> : 'Selanjutnya'}</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.import_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setImportGatepass: (payload) => dispatch({type: 'orders.import_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
