import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Pulse from '../../spinner/Pulse';
import network from '../../../lib/network';

class ExportVerificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      isExpired: false,
      verificationCode: '',
      minutes: 0,
      seconds: 0,
      check: {
        verificationCode: false
      }
    };
  }

  componentDidMount() {
    this.startCountdown(this.props.step4.tokenExpiredAt);
  }

  componentWillUnmount() {
    this.stopCountdown();
  }
  
  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        verificationCode: this.state.verificationCode.length > 0,
      }
    });
  }
  
  handleSubmitVerification = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      let body = {
        action: "export", 
        objectId: _.get(this.props, 'step4.objectId', ''), 
        tokenAccess: this.state.verificationCode
      }

      const response = await network.POST(`/econ/verification`, body, {}, true);
      if (parseInt(response.status) !== 200) {
        throw response;
      } else {
        this.stopCountdown();
        this.props.closeModal();
        this.props.resetExportGatepass();
        window.location.href = `/#/npct1/proforma-calculation/${response.collections.reffNumber}`;
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.message);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  resendOTP = (e) => {
    this.props.resend();
  }

  closeModal = () => {
    this.props.closeModal();
  }

  stopCountdown = () => {
    clearInterval(this.intervalHandle)
  };

  startCountdown = tokenExpiredAt => {
    let countDownDate = tokenExpiredAt;
    this.intervalHandle = setInterval(() => {
      let now = new Date().getTime();
      this.secondsRemaining = countDownDate - now;
      var minutes = Math.floor((this.secondsRemaining % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((this.secondsRemaining % (1000 * 60)) / 1000);
      
      if (minutes >= 0 && seconds >= 0) {
        this.setState({
          minutes, seconds
        })
      } else if (minutes < 0) {
        this.stopCountdown();
        this.setState({ isExpired: true });
      }
    }, 1000);
  };

  render() {
    const { openModal, step2, selectedContainer } = this.props;
    const { isExpired, minutes, seconds } = this.state;

    return (
      <Dialog open={openModal} onClose={this.closeModal} maxWidth='xs' fullWidth>
         <DialogTitle onClose={this.closeModal}>
          Verifikasi Order
          <i
            className="icon icon-close2 mt-2 float-right"
            style={{ paddingRight: 0, cursor: "pointer" }}
            onClick={this.closeModal}
          />
        </DialogTitle>
        <DialogContent dividers>
          <h4>Verifikasi Order</h4>
          <div className="mb-3">OTP telah dikirimkan ke nomor anda. Masukan kode verifikasi untuk menyetujui transaksi ini sebelum waktu tenggat transaksi habis.</div>
          <div>
            <div className="mb-1">Nomor Order</div>
            <div className="mb-3"><strong>{ _.get(step2, 'orders.bookingNo', '-') }</strong></div>
            <div className="mb-1">Total Box</div>
            <div>
              <span className="badge badge-success">{ selectedContainer.length } OK</span><span className="badge badge-danger">{ _.get(step2, 'containers.length', 0) - selectedContainer.length } NOK</span>
            </div>
          </div>
          <form name="form" className="mt-5"  action="/" onSubmit={this.handleSubmitVerification}>
            <div className="col-12">
              {!isExpired ?
                <div>
                  <div className="form-group text-center w-100">
                    <input type="text"
                      placeholder='Enter Verification Code'
                      className={this.state.validate && !this.state.check.verificationCode ? "form-control is-invalid" : "form-control"}
                      value={this.state.verificationCode}
                      onChange={this.handleChange}
                      id="verificationCode"
                    />
                    <div className="invalid-feedback">
                      Kolom ini harus diisi
                    </div>
                  </div>
                  <div className="form-group text-center w-100 mt-3">
                    <button type="submit" className="btn btn-primary btn-lg" onClick={() => {
                      this.setState({
                        validate: true
                      })
                    }}>{this.state.loading ? <Pulse/> : 'Selanjutnya'}</button>
                  </div>
                </div>
              :
                <div className="form-group text-center w-100">
                  <div>Kode Verifikasi telah habis masa berlakunya (expired)</div>
                </div>
              }
            </div>
          </form>
          <div className="col-sm-12 text-center">
            <span className="notes">Berakhir pada <span className="text-danger">{ minutes < 10 ? ('0' + minutes) : minutes }:{ seconds < 10 ? ('0' + seconds) : seconds }</span></span>
              <br />
            <span className="notes">Tidak menerima kode OTP? <span className="pointer text-ritase" onClick={this.resendOTP}>Kirim Ulang</span></span>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.export_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setExportGatepass: (payload) => dispatch({type: 'orders.export_gatepass', payload}),
  resetExportGatepass: () => dispatch({type: 'orders.export_gatepass.reset'})
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExportVerificationModal));