const initialState = null;

function pickup_time(state = initialState, action) {
  let time;
  switch (action.type) {
  case 'orders.pickup_time':
    if (action.payload === null)
      return action.payload;

    time = action.payload;
    time.setSeconds(0, 0);
    return time;
  default:
    return state
  }
}

export default pickup_time;
