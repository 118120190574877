const initialState = {};

function vehicle_area(state = initialState, action) {
  if (action.type === 'orders.vehicle_area') {
    return action.payload;
  } else {
    return state
  }
}

export default vehicle_area;
