import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {geocodeByAddress} from 'react-places-autocomplete'
import LocationInput from './LocationInput'
import Swal from 'sweetalert2'
import Select, { components } from 'react-select';
import gmaps from '../lib/gmaps';
import 'jquery-ui-touch-punch'
import _ from 'lodash';
import Bar from "./spinner/Bar";
import {apkGeojson} from '../lib/ApkHelper';

class LocationsApk extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.sortable = React.createRef();
  }

  componentDidMount = () => {
    window.$('#sortable').sortable({
      handle: '.drag-handle',
      stop: (event) => {
        let newLocations = [this.props.locations[0]];
        let el;
        // FIXME: black magic
        for(let item of event.target.children) {
          el = this.props.locations.filter(loc => loc.id === Number(item.id))[0];
          newLocations.push(el);
        }
        this.props.handleReplaceLocation(newLocations);
      }
    }).disableSelection();

    /*for (let key of Object.keys(this.database)) {
      this.database[key].on('value', snap => {
        this.setState({
          [key]: snap.val(),
        })
      });
    }*/
  };

  selectLocation(locationId, state, locations) {
    return async (suggestion) => {
      const allowed = await this.props.handleAutocompleteLocation(locationId, state, locations)(suggestion);
      if (allowed) {
        this.props.handleRecenter(this.props.locations);
      }
      gmaps.drawRoute(this.props.locations)
    }
  }

  render() {
    const origin = {...this.props.locations[0]};
    origin.value = _.get(this.props, 'locations[0].area', '');
    origin.label = _.get(this.props, 'locations[0].address', '');
    const destination = this.props.locations[1];

    const ValueContainer = ({ children, ...props }) => {
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <img src="/assets/img/marker/start-point.svg" alt='start'
              style={{maxWidth: "10px", margin: '0 .75rem'}}/>
            )}
            {children}
          </components.ValueContainer>
        )
      );
    };

    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: 'none',
        borderColor: '#dee2e6 !important'
      }),
      placeholder: (base, state) => ({
        ...base,
        paddingLeft: '2.75rem',
        color: '#d0d0d0'
      }),
      input: (base, state) => ({
        ...base,
        paddingLeft: '.5rem'
      }),
      singleValue: (base, state) => ({
        ...base,
        paddingLeft: '2.75rem',
        display: state.selectProps.menuIsOpen ? 'none' : 'block',
      }),
      valueContainer: (base, state) => ({
        ...base,
        paddingLeft: '0'
      }),
    };

    return (
      <div>
        {this.props.isLoadingPorts ? 
          <div className="mb-2">
            <Bar/>
          </div>
        :
          <Select
            value={origin.label ? origin : null}
            onChange={this.props.onOriginChange}
            options={this.props.ports}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ValueContainer }}
            placeholder={<div>Asal...</div>}
            styles={customStyles}
            className="mb-2"
          />
        }
        <LocationInput
          onChange={this.props.handleChangeContact(destination.id)}
          onSelect={this.selectLocation(destination.id, this.state, this.props.locations)}
          address={destination.address}
          placeholder='Tujuan...'
          origin={false}
          hideDragHandle={true}
          isLast={true}
        >
        </LocationInput>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  locations: state.orders.locations,
  button: state.orders.button
});

const mapDispatchToProps = (dispatch) => ({
  handleButton: (btn) => dispatch({
    type: 'orders.button',
    payload: btn
  }),
  handleReplaceLocation: (payload) => {
    console.log('handleReplaceLocation');
    dispatch({type: 'orders.location.replace', payload: payload})
  },
  handleChangeContact: (id) => (address) => {
    gmaps.clearRoute();
    dispatch({
      type: 'orders.location.update',
      payload: {
        id: id,
        address: address,
        latitude: null,
        longitude: null,
        city: null,
        zipcode: null,
      }
    })
  },
  handleAutocompleteLocation: (id, state, locations) => (async (suggestion) => {
    try {
      const results = await geocodeByAddress(suggestion);
      if (results.length < 1)
        throw new Error('no response');
      let result = results[0];
      let origin_area = _.get(locations, '[0].area', '')

      if (!origin_area) {
        await Swal.fire({
          position: 'middle',
          type: 'info',
          text: `Silakan pilih Asal terlebih dahulu`,
          showConfirmButton: true,
        })
        return false
      } else {
        const destPoint = [
          result.geometry.location.lng(),
          result.geometry.location.lat(),
        ];
        const area = apkGeojson.lookupDestinationArea(destPoint, origin_area);
  
        if (!area) {
          const html = '<span>Mohon maaf, saat ini Ritase hanya melayani pengiriman ke pulau yang sama. Jika anda tetap membutuhkan jasa kami silahkan masukan nomer telfon, atau kunjungi <a href="https://ritase.zendesk.com/hc/id/requests/new">link berikut </a></span>';
  
          const phoneModalResult = await Swal.fire({
            html,
            input: 'number',
            onOpen: (mod) => {
              mod.getElementsByClassName('swal2-input')[0].addEventListener("keypress", function (evt) {
                if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
                  evt.preventDefault();
                }
              });
            },
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Hubungi Saya',
            showLoaderOnConfirm: true,
            preConfirm: (phone) => {
              return phone
            },
            allowOutsideClick: () => !Swal.isLoading()
          })
  
          if (phoneModalResult.value) {
            await Swal.fire({
              position: 'middle',
              type: 'success',
              title: 'Terima kasih',
              text: `Kami akan segera menghubungi anda di ${phoneModalResult.value}`,
              showConfirmButton: false,
              timer: 2000
            })
          }
  
          dispatch({
            type: 'orders.location.update',
            payload: {
              id: id,
              address: '',
            }
          });
          return false;
        }
  
        dispatch({
          type: 'orders.location.update',
          payload: {
            id: id,
            address: suggestion,
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng(),
            city: gmaps.getCity(results),
            zipcode: gmaps.getZipcode(results),
            area
          },
        });
      }

      return true
    } catch (error) {
      // eslint-disable-next-line no-unused-vars
      Swal.fire('Terjadi Kesalahan', 'Mohon maaf kami tidak dapat memproses pengiriman dari Alamat ini');
      this.props.updateMarker(Number(this.state.startDragging.id), this.state.startDragging.lat, this.state.startDragging.lng);
      gmaps.drawRoute(this.props.locations);
      return false;
    }
  }),
  handleRecenter: (locations) => {
    dispatch({type: 'orders.maps.recenter', payload: gmaps.getBounds(locations)});
  },
});

LocationsApk.propTypes = {
  button: PropTypes.shape({
    add_drop: PropTypes.bool,
    remove_drop: PropTypes.bool,
    optimize: PropTypes.bool,
  }).isRequired,
  handleButton: PropTypes.func.isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleAutocompleteLocation: PropTypes.func.isRequired,
  handleRecenter: PropTypes.func.isRequired,
  handleReplaceLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
    contact: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
      remarks: PropTypes.string,
    }),
  })),
  ports: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsApk);
