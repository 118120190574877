const orders = {};

orders.origin = (locations) => {
  return locations[0].area
};

orders.destination = (locations) => {
  return locations[locations.length - 1].area || false
};

orders.hasSumatera = (locations) => {
  let found = false;
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].area === 'Sumatera') {
      found = true;
      break;
    }
  }
  return found;
};

orders.hasJawaBali = (locations) => {
  let found = false;
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].area === 'Jawa Bali') {
      found = true;
      break;
    }
  }
  return found;
};

orders.hasJabodetabek = (locations) => {
  let found = false;
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].area === 'Jabodetabek') {
      found = true;
      break;
    }
  }
  return found;
};

export default orders;