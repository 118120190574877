const initialState = '';

function delivery_type(state = initialState, action) {
  if (action.type === 'orders.delivery_type') {
    return action.payload;
  } else {
    return state
  }
}

export default delivery_type;