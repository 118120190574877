import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../spinner/Pulse";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import idLocale from "date-fns/locale/id";
import { format } from 'date-fns';
import network from '../../lib/network';
  
class Step0 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      delivery_order: this.props.doNumber,
      bl_number: this.props.blNumber,
      expired: this.props.doExpired ? new Date(this.props.doExpired) : null,
      payment_plan: this.props.paymentPlan ? new Date(this.props.paymentPlan) : null,
      check: {
        delivery_order: false,
        bl_number: false,
        expired: false,
        payment_plan: false,
      },
      actionType: this.props.actionType
    };
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  handleDateChange = (type, value) => {
    this.setState({
      [type]: value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        delivery_order: this.state.delivery_order.length > 0,
        bl_number: this.state.bl_number.length > 0,
        expired: this.state.expired ? true : false,
        payment_plan: (this.state.payment_plan && this.state.actionType == 'extension') || (this.state.actionType == 'import' && this.state.payment_plan && this.state.payment_plan <= this.state.expired) ? true : false
      }
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      let body = {
        action: this.state.actionType,
        blNumber: this.state.bl_number, 
        doNumber: this.state.delivery_order,
        messageId: this.props.messageId,
        paymentPlan: format(new Date(this.state.payment_plan), 'yyyy-MM-dd'),
      };

      if (this.state.actionType == 'extension') {
        body.paidThrough = format(new Date(this.state.expired), 'yyyy-MM-dd');
      } else if (this.state.actionType == 'import') {
        body.doExpired = format(new Date(this.state.expired), 'yyyy-MM-dd');
      }
      
      const response = await network.POST(`/econ/order`, body, {}, true);
      
      if (parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        body.step0 = response.objects;
        this.props.setImportGatepass(body);
        this.props.setStep(1);
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  render() {
    const { ritaseTheme } = this.props;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Data Delivery Order</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <label htmlFor="delivery_order">
            Delivery Order Number
            <i className="s-18 text-danger cur-help" onMouseDown={() => {
              Swal.fire('', 'Bagian ini harus diisi', 'info');
            }}>*</i>
          </label>
          <div className="form-group">
            <input type="text"
              placeholder='Enter delivery order number'
              className={this.state.validate && !this.state.check.delivery_order ? "form-control is-invalid" : "form-control"}
              value={this.state.delivery_order}
              onChange={this.handleChange}
              id="delivery_order"
            />
            <div className="invalid-feedback">
              Kolom ini harus diisi
            </div>
          </div>
          <label htmlFor="bl_number">
            BL Number
            <i className="s-18 text-danger cur-help" onMouseDown={() => {
              Swal.fire('', 'Bagian ini harus diisi', 'info');
            }}>*</i>
          </label>
          <div className="form-group">
            <input type="text"
              placeholder='Enter BL Number'
              className={this.state.validate && !this.state.check.bl_number ? "form-control is-invalid" : "form-control"}
              value={this.state.bl_number}
              onChange={this.handleChange}
              id="bl_number"
            />
            <div className="invalid-feedback">
              Kolom ini harus diisi
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="expired">
                { this.state.actionType == 'import' ? 'Delivery Order Expired' : 'Paid Through' }
                <i className="s-18 text-danger cur-help" onMouseDown={() => {
                  Swal.fire('', 'Bagian ini harus diisi', 'info');
                }}>*</i>
              </label>
              <div className={this.state.validate && !this.state.check.expired ? "form-group is-invalid" : "form-group"}>
                <div className="input-group input-scheduled">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon icon-calendar" />
                    </span>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
                    <ThemeProvider theme={ritaseTheme}>
                      <DatePicker
                        className='form-control bg-white'
                        theme={ritaseTheme}
                        format="dd MMM yyyy"
                        onChange={(value) => this.handleDateChange('expired', value)}
                        value={this.state.expired}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="invalid-feedback">
                  Kolom ini harus diisi
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="payment_plan">
                Payment Plan
                <i className="s-18 text-danger cur-help" onMouseDown={() => {
                  Swal.fire('', 'Bagian ini harus diisi', 'info');
                }}>*</i>
              </label>
              <div className={this.state.validate && !this.state.check.payment_plan ? "form-group is-invalid" : "form-group"}>
                <div className="input-group input-scheduled">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon icon-calendar" />
                    </span>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
                    <ThemeProvider theme={ritaseTheme}>
                      <DatePicker
                        className="form-control bg-white"
                        theme={ritaseTheme}
                        format="dd MMM yyyy"
                        onChange={(value) => this.handleDateChange('payment_plan', value)}
                        value={this.state.payment_plan}
                        maxDate={this.state.actionType == 'import' ? new Date(this.state.expired) : undefined}
                        maxDateMessage=''
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="invalid-feedback">
                  {this.state.payment_plan ?
                    'Payment plan tidak boleh melebihi tanggal Delivery Order Expired'
                  :
                    'Kolom ini harus diisi'
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={() => {
              this.setState({
                validate: true
              })
            }}>{this.state.loading ? <Pulse/> : 'Selanjutnya'}</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.import_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setImportGatepass: (payload) => dispatch({type: 'orders.import_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step0);
