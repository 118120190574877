const initialState = null

function promo(state = initialState, action) {
  switch (action.type) {
  case 'orders.promo':
    return action.payload;
  default:
    return state;
  }
}

export default promo;
