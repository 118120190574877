import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import network from '../lib/network';
import utils from '../lib/utils';
import * as Swal from "sweetalert2";
import Pulse from "../components/spinner/Pulse";
import ReCAPTCHA from "react-google-recaptcha";
import {captchaSiteKey} from '../config';
import auth from '../lib/auth';
import store from '../store';
import NumberFormat from 'react-number-format';


class Register extends Component {

  handleCaptcha = value =>{
    this.setState({
      "g-recaptcha-response": value
    });
    if (value === null) this.setState({ expired: "true" });
  };

  constructor(props) {
    super(props);

    this._reCaptchaRef = React.createRef();
    this.state = {
      "g-recaptcha-response": "",
      expired: false,
      loading: false,
      name: "",
      email: "",
      password: "",
      phone_number: "",
      npwp_number: "",
      password_confirmation: "",
      validate: false,
      check: {
        name: false,
        email: false,
        password: false,
        password_confirmation: false,
        phone: false,
        npwp_number: false
      }
    };
  }

  componentDidMount() {
    const params = utils.getParams(this.props.location.search);
    this.setState({ next: params.get('next') });
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        name: /^([a-zA-Z\s+]){4,32}$/.test(this.state.name),
        email: utils.isValidEmail(this.state.email) && this.state.email.length > 0,
        password: this.state.password.length > 0,
        password_confirmation: this.state.password === this.state.password_confirmation,
        phone: utils.isValidPhoneNumber(this.state.phone_number),
        npwp_number: this.state.npwp_number.length === 0 || this.state.npwp_number.length === 15
      }
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    if (this.validateForm()) {
      const next = this.state.next;
      network.POST('/users/retail/register', {...this.state, auto_login: true})
        .then((response) => {
          this._reCaptchaRef.current && this._reCaptchaRef.current.reset();
          this.handleCaptcha(null);
          this.setState({
            loading: false
          });
          if (response.key) {
            let text;
            switch (response.key) {
            case 'companies_email_unique':
              text = `Email ${this.state.email} telah terdaftar di sistem kami.`;
              break;
            case 'users_phone_number_user_type_unique':
            case 'companies_phone_number_unique':
              text = `Nomor ${this.state.phone_number} telah terdaftar di sistem kami.`;
              break;
            default:
              break;
            }
            Swal.fire({
              type: 'error',
              text: text,
            });
            return;
          }

          if (!response.success) {
            Swal.fire({
              type: 'error',
              text: 'Mohon periksa kembali data registrasi kamu.',
            });
            return;
          }

          if (response.login_data && response.login_data.token) {
            Swal.fire({
              type: 'success',
              text: 'Registrasi berhasil!',
            });
            auth.set(response.login_data.token);
            store.dispatch({ type: 'auth.update', data: { isAuthorized: true, user: response.login_data.user } })
            document.location.href = next ? '/#' + decodeURIComponent(next) : '/#/dashboard';
          } else {
            Swal.fire({
              type: 'success',
              text: 'Registrasi berhasil!. Silahkah Login!',
            });
            document.location.href = '/#/dashboard';
          }
        })
        .catch((err) => {
          this.setState({
            loading: false
          });
          Swal.fire({
            type: 'error',
            text: err.message,
          });
        });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  render() {
    //const { captcha, expired } = this.state || {};
    return (
      <main>
        <div id="primary" className="p-t-b-100 height-full">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-md-auto">
                <div className="text-center">
                  <img src="/assets/img/logo.png" alt="" style={{maxWidth: '75%'}}/>
                  <h3 className="mt-2">Daftar</h3>
                </div>
                <form action="/" onSubmit={this.handleSubmit}>
                  <label htmlFor="name">
                    Nama
                  </label>
                  <div className="form-group has-icon"><i className="icon-person"/>
                    <input type="text"
                      placeholder='Nama'
                      className={this.state.validate && !this.state.check.name ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.name}
                      onChange={this.handleChange}
                      id="name"
                    />
                    <div className="invalid-feedback">
                      Nama wajib huruf dan setidaknya 4-32 karakter
                    </div>
                  </div>
                  <label htmlFor="email">
                    Email
                  </label>
                  <div className="form-group has-icon"><i className="icon-envelope-o"/>
                    <input type="text"
                      placeholder='Email'
                      className={this.state.validate && !this.state.check.email ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                    />
                    <div className="invalid-feedback">
                      Format email tidak sesuai
                    </div>
                  </div>
                  <label htmlFor="phone_number">
                    Nomor handphone
                  </label>
                  <div className="form-group has-icon"><i className="icon-phone"/>
                    <input type="number"
                      placeholder='Nomor Handphone'
                      className={this.state.validate && !this.state.check.phone ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.phone_number}
                      onChange={this.handleChange}
                      id="phone_number"
                    />
                    <div className="invalid-feedback">
                      Nomor wajib angka dan setidaknya 10-13 karakter
                    </div>
                  </div>
                  <label htmlFor="npwp_number">
                    NPWP (optional)
                  </label>
                  <div className="form-group has-icon"><i className="icon-id-card-o"/>
                    <NumberFormat 
                      id="npwp_number"
                      value={this.state.npwp_number} 
                      className={this.state.validate && !this.state.check.npwp_number ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      placeholder="NPWP"
                      format="##.###.###.#-###.###"
                      thousandSeparator={false} 
                      allowLeadingZeros={true}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        const event = {
                          target: {
                            id: 'npwp_number',
                            value
                          }
                        }
                        this.handleChange(event)
                      }} />
                    <div className="invalid-feedback">
                      NPWP harus 15 angka
                    </div>
                  </div>
                  <label htmlFor="password">
                    Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-key2"/>
                    <input type="password"
                      placeholder='Password'
                      className={this.state.validate && !this.state.check.password ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                    />
                  </div>
                  <label htmlFor="password">
                    Konfirmasi Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-key2"/>
                    <input type="password"
                      placeholder='Konfirmasi Password'
                      className={this.state.validate && !this.state.check.password_confirmation ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.password_confirmation}
                      onChange={this.handleChange}
                      id="password_confirmation"
                    />
                    <div className="invalid-feedback">
                      Password tidak sama.
                    </div>
                  </div>
                  <div className="form-group has-icon text-center">
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      theme="dark"
                      ref={this._reCaptchaRef}
                      sitekey={captchaSiteKey}
                      onChange={this.handleCaptcha}
                      asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                  </div>
                  <div>
                    <button type="submit" className="btn btn-ritase btn-lg btn-block" onClick={() => {
                      this.setState({
                        validate: true
                      })
                    }}>{this.state.loading ? <Pulse/> : 'Daftar'}</button>
                    <br/>
                    <span>Sudah memiliki akun?&nbsp;
                      <Link to={`/login${this.state.next ? '?next=' + encodeURIComponent(this.state.next) : ''}`}>
                        Login disini
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Register.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Register);
