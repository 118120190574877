import APKRoute from "../components/wizard/APKRoute";
import APKContacts from "../components/wizard/APKContacts";
import APKSummary from "../components/wizard/APKSummary";

const routes = [
  {
    path: '/dashboard/apk/route',
    component: APKRoute
  },
  {
    path: '/dashboard/apk/contact',
    component: APKContacts
  },
  {
    path: '/dashboard/apk/pay',
    component: APKSummary
  },
];

export default routes;