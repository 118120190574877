import {combineReducers} from 'redux';

import orders from './orders'
import auth from './auth'
import import_gatepass from './import_gatepass'
import export_gatepass from './export_gatepass'

const reducer = combineReducers({
  orders,
  auth,
  import_gatepass,
  export_gatepass
});

export default reducer;
