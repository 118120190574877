import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core';
import {PulseLoader} from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export default class Pulse extends Component {
  render() {
    const props = this.props;
    return (
      <PulseLoader
        css={override}
        sizeUnit={"px"}
        size={props.size ? props.size : 14}
        color={props.color ? props.color : '#fff'}
      />
    )
  }
}

Pulse.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};