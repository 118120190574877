const errors = {};

const translateFieldNames = (field) => {
  switch (field) {
  case 'locations':
    return 'lokasi';
  case 'pickupType':
    return 'jenis penjemputan';
  case 'pickupTime':
    return 'waktu penjemputan';
  case 'vehicleType':
    return 'jenis truk';
  case 'addons':
    return 'layanan tambahan';
  case 'name':
    return 'nama';
  case 'address':
    return 'alamat';
  case 'latitude':
  case 'longitude':
  case 'city':
  case 'zipcode':
    return 'lokasi';
  case 'phone_number':
    return 'nomor kontak';
  case 'trip_id':
    return 'nomor order';
  case 'email':
    return 'email';
  case 'password':
    return 'password';
  default:
    throw new Error('Mohon cek kembali data anda');
  }
}

errors['GET /midtrans/retail'] = {
  'NOT_FOUND': 'Order anda tidak dapat ditemukan',
  'HAS_BEEN_PAID': 'Order anda telah dibayar',
  'TIME_LIMIT_EXCEEDED': 'Batas waktu pembayaran telah habis',
};

errors['POST /users/retail/register'] = {
  'INVALID': 'Data yang anda berikan tidak valid',
  'CAPTCHA_FAILED': 'Captcha tidak valid',
};

errors['POST /users/retail/forgot'] = {
};

errors['POST /users/retail/reset'] = {
};

errors['POST /users/v2/sign_in'] = {
};

errors['POST /users/sign_out'] = {
};

errors['POST /orders/retail'] = {
  'BEFORE_NOW': 'Waktu pickup telah lewat',
  'UNKNOWN_PICKUP_TYPE': 'Tipe pickup tidak ditemukan',
  'OUT_OF_BOUNDS': 'Lokasi di luar jangkauan',
  'TOO_LATE': 'Pesanan paling jauh adalah 7 hari ke depan',
  'TOO_EARLY': 'Pesanan paling cepat adalah 6 jam sebelum waktu pickup',
  'ILLEGAL_CHARS': 'Kode promo mengandung karakter ilegal',
  'NOT_ALLOWED': 'Waktu pickup diluar jam kerja',
  'NOT_FOUND': 'Kode promo yang anda gunakan tidak dapat ditemukan',
};

errors['POST /orders/retail/check_fee'] = {
  'BEFORE_NOW': 'Waktu pickup telah lewat',
  'UNKNOWN_PICKUP_TYPE': 'Tipe pickup tidak ditemukan',
  'OUT_OF_BOUNDS': 'Lokasi di luar jangkauan',
  'TOO_LATE': 'Pesanan paling jauh adalah 7 hari ke depan',
  'TOO_EARLY': 'Pesanan paling cepat adalah 6 jam sebelum waktu pickup',
  'ILLEGAL_CHARS': 'Kode promo mengandung karakter ilegal',
  'NOT_ALLOWED': 'Waktu pickup diluar jam kerja',
  'NOT_FOUND': 'Kode promo yang anda gunakan tidak dapat ditemukan',
  'PROMO_EXPIRED': 'Kode promo ini sudah tidak dapat digunakan',
  'PROMO_NOT_AVAILABLE': 'Kode promo ini tidak dapat digunakan',
};

errors['POST /orders/retail/promo'] = {
  'BEFORE_NOW': 'Waktu pickup telah lewat',
  'UNKNOWN_PICKUP_TYPE': 'Tipe pickup tidak ditemukan',
  'OUT_OF_BOUNDS': 'Lokasi di luar jangkauan',
  'TOO_LATE': 'Pesanan paling jauh adalah 7 hari ke depan',
  'TOO_EARLY': 'Pesanan paling cepat adalah 6 jam sebelum waktu pickup',
  'ILLEGAL_CHARS': 'Kode promo mengandung karakter ilegal',
  'NOT_ALLOWED': 'Waktu pickup diluar jam kerja',
  'NOT_FOUND': 'Kode promo yang anda gunakan tidak dapat ditemukan',
  'PROMO_EXPIRED': 'Kode promo ini sudah tidak dapat digunakan',
  'PROMO_NOT_AVAILABLE': 'Kode promo ini tidak dapat digunakan',
};

errors['GET /trips'] = {
};

errors['GET /tracking/public/:trip_id'] = 'Data tracking tidak tersedia';

errors['POST /trips/:trip_id/cancel'] = {
  'STATUS_CANNOT_CANCEL': 'Maaf, Anda tidak dapat membatalkan order dengan status ini',
  'NOT_RETAIL_ORDER': 'Maaf, order ini bukan order retail',
  'NOT_FOUND': 'Order ini tidak dapat ditemukan',
  'HAS_BEEN_CANCELED': 'Order ini telah dibatalkan',
};

const handlers = [];

handlers.push((method, url, response, params) => {
  if ('ERR_CODE' in response && errors[`${method} ${url}`][response.ERR_CODE].constructor === Function)
    throw new Error(errors[`${method} ${url}`][response.ERR_CODE](response, params));

  return response;
});

handlers.push((method, url, response) => {
  if ('ERR_CODE' in response && typeof(errors[`${method} ${url}`]) !== 'undefined')
    throw new Error(errors[`${method} ${url}`][response.ERR_CODE]);

  return response;
});

handlers.push((method, url, response) => {
  if ('0' in response) {
    throw new Error(`Mohon cek kembali kolom ${translateFieldNames(response[0].field)}`);
  }

  return response;
});

export const parseError = (method, {url, params}) => (response) => {

  if (typeof(response) !== 'object' || response === null)
    return response;

  // check for error
  if (!('errorMessage' in response)) {
    return response;
  }

  console.error(response.errorMessage);

  if ('ERR_CODE' in response
    && errors
    && errors[`${method} ${url}`]
    && errors[`${method} ${url}`][response.ERR_CODE]
    && errors[`${method} ${url}`][response.ERR_CODE].constructor === Function
  ) {
    throw new Error(errors[`${method} ${url}`][response.ERR_CODE](response, params));
  }

  if ('ERR_CODE' in response && typeof(errors[`${method} ${url}`]) !== 'undefined')
    throw new Error(errors[`${method} ${url}`][response.ERR_CODE]);

  if ('0' in response) {
    throw new Error(`Mohon cek kembali kolom ${translateFieldNames(response[0].field)}`);
  }

  return response;
};
