import React, { Component } from 'react';
import _ from 'lodash';
import * as Swal from "sweetalert2";
import QRCode from 'react-qr-code'
import auth from '../../lib/auth';
import network from '../../lib/network';

class PrintGatepass extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      selectedListData: [],
      item: {},
      isLoading: true
    }
  }
  
  componentDidMount = async () => {
    if (auth.check()) {
      var referenceNumber = this.props.match.params.reffNumber;
      var tar = decodeURIComponent(this.props.match.params.tar);
      const response = await network.POST('/econ/gatepass', { referenceNumber }, {}, true);
      const list = await network.GET('/econ/list', { reference_number : referenceNumber }, {}, true);

      var data = [];
      let selectedListData = [];
      var item = {};
      var loaded = false;
      if (response.errorMessage || parseInt(response.status, 10) !== 200) {
        let errorMessage = response && (response.errorMessage || response.messages);
        Swal.fire(
          'Gagal!',
          'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
          'error'
        ).then(function (e) {
          window.history.back();
        });
      } else {
        data = _.get(response, 'collections.gatepass', []);
        selectedListData = _.get(list, 'rows', []);
        item = _.find(data, gp => gp.tar === tar);
        loaded = true;
      }
      
      this.setState({
        data,
        item,
        selectedListData,
        isLoading: false
      })
      
      if (loaded) window.print();
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  }

  render() {
    const { isLoading, item, selectedListData } = this.state;
    let containerNo = _.get(item, 'containerNo', '');

    return (
      <div style={{
        maxWidth: '800px',
        margin: 'auto',
        padding: '30px',
        border: '1px solid #eee',
        boxShadow: '0 0 10px rgba(0, 0, 0, .15)',
        fontSize: '13px',
        lineHeight: '24px',
        color: '#555',
      }}>
        <div className="mb-5 row">
          <div style={{width: '85%'}}>
            <div className="ml-5">
              <h3 className="text-center font-weight-bold">Gate Pass</h3>
              <h3 className="text-center font-weight-bold">New Priok Container Terminal 1</h3>
              <h3 className="text-center font-weight-bold">NPCT 1</h3>
            </div>
          </div>
          <div style={{width: '10%'}}>
            {!isLoading &&
              <div>
                <QRCode 
                  value={_.get(item, 'tar', '')} 
                  size={120}
                />
              </div>
            }
          </div>
        </div>
        <div>
          <table className="w-100 table-borderless">
            <tbody>
              <tr>
                <td>{ containerNo.substring(0,4) }</td>
                <td style={{width: '33%'}}>{ _.get(item, 'isoCode', '') }</td>
                <td style={{width: '20%'}}>{ _.get(item, 'grossWeight', '') }</td>
              </tr>
              <tr>
                <td>{ containerNo.substr(4, containerNo.length - 5)}{ containerNo.substr(containerNo.length - 1, 1)}</td>
                <td style={{width: '33%'}}></td>
                <td style={{width: '20%'}}></td>
              </tr>
              <tr>
                <td>{ _.get(item, 'containerStatus', '') }</td>
                <td style={{width: '33%'}}></td>
                <td style={{width: '20%'}}>{ _.get(item, 'gatepassType', '') } { selectedListData && selectedListData.length > 0 && _.get(selectedListData, '[0].is_extension') == 1 ? 'EXTENSION' : '' }</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="content" class="text-center mt-5">
          <div>{ _.get(item, 'vesselName', '') }</div>
          <div>Voyage : { _.get(item, 'voyage', '') }</div>
          {_.get(item, 'gatepassType', '').toLowerCase() === 'import' ?
            <div>Paid Through: { _.get(item, 'paidThrough', '') }</div>
          : _.get(item, 'gatepassType', '').toLowerCase() === 'export' &&
            <div>Yard Closing Time: { _.get(item, 'yardClosingTime', '') }</div>
          }
        </div>
        <div id="content" class="text-center mt-5">
          <div>{ _.get(item, 'pod', '') } / { _.get(item, 'spod', '') }</div>
          <div>{ _.get(item, 'customerName', '') }</div>
        </div>
        <div id="content" className="mt-5">
          <div>IMO Code</div>
          <div>BC Document &nbsp;&nbsp;&nbsp; { _.get(item, 'customsType', '') } { _.get(item, 'customsNumber', '') } &nbsp; { _.get(item, 'customsDate', '') }</div>
        </div>
      </div> 
    );
  }
}

export default PrintGatepass;