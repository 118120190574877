import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Swal from "sweetalert2";
import Pulse from "../spinner/Pulse";
import _ from 'lodash';
import OrderVerificationModal from './OrderVerificationModal';
import network from '../../lib/network';

class Step3 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: '',
      openModal: false,
      actionType: this.props.actionType
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {
    if (event) event.preventDefault();
    this.setState({
      loading: true
    });
    try {
      let body = {
        action: this.state.actionType,
        messageId: this.props.messageId,
        containers: {}
      };

      _.each(this.props.selectedContainer, container => {
        body.containers[container.contNo] = {
          contNo: container.contNo,
          paidThroughDate: container.paidThroughDate
        }
      })

      const response = await network.POST(`/econ/confirmation`, body, {}, true);
      
      if (parseInt(response.status, 10) !== 200) {
        throw response;
      } else {
        body.step3 = response.collections;
        this.props.setImportGatepass(body);
        this.setState({
          openModal: true
        })
      }

      this.setState({
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false
      });
      let errorMessage = err && (err.errorMessage || err.messages);
      if (!errorMessage) errorMessage = 'Order gagal diproses'
      await Swal.fire({
        type: 'error',
        text: 'Error: ' + (errorMessage && errorMessage.length > 100 ? `${errorMessage.substring(0, 100)} ...` : errorMessage),
      });
    }
  };

  filterContainer = () => {
    let containers = _.get(this.props, 'selectedContainer', []);
    const { search } = this.state;
    if (search.length > 0) {
      return _.filter(containers, container => _.get(container, 'contNo', '').toLowerCase().includes(search.toLowerCase()));
    }

    return containers;
  }

  closeModal = () => {
    this.setState({
      openModal: false
    })
  }

  render() {
    const { step1, customerName, parseToDate } = this.props;

    return (
      <div>
        <div className="text-center">
          <h3 className="mt-2">Konfirmasi Order</h3>
        </div>
        <form action="/" onSubmit={this.handleSubmit}>
          <div className="row mt-5">
            <div className="col-3">
              <h4>Data Pelanggan</h4>
              <div className="form-group">
                <label>NPWP</label>
                <p>{ _.get(step1, 'orders.taxId', '-') }</p>
              </div>
              <div className="form-group">
                <label>Nama Pelanggan</label>
                <p>{ customerName }</p>
              </div>
            </div>

            <div className="col-3">
              <h4>Data Vessel</h4>
              <div className="form-group">
                <label>Vessel / Voyage</label>
                <p>{ _.get(step1, 'orders.vesselName', '') } / { _.get(step1, 'orders.voyageIn', '') }</p>
              </div>
              <div className="form-group">
                <label>Agent</label>
                <p>{ _.get(step1, 'orders.agent', '').split('|')[0] }</p>
              </div>
            </div>
            
            <div className="col-3">
              <h4>Data Delivery Order</h4>
              <div className="form-group">
                <label>Nomor DO / BL</label>
                <p>{ _.get(step1, 'orders.doNumber', '') } / { _.get(step1, 'orders.blNumber', '') }</p>
              </div>
              <div className="form-group">
                <label>Tanggal Jatuh Tempo</label>
                <p>{ parseToDate(_.get(step1, 'orders.doExpired', ''), 'date') }</p>
              </div>
              <div className="form-group">
                <label>Tanggal Pembayaran</label>
                <p>{ parseToDate(_.get(step1, 'orders.paymentPlan', ''), 'date') }</p>
              </div>
            </div>

            <div className="col-3">
              <h4>Data Bea Cukai</h4>
              <div className="form-group">
                <label>Dokumen Bea Cukai</label>
                <p>{ _.get(step1, 'orders.responseType', '') }</p>
              </div>
              <div className="form-group">
                <label>Nomor Dokumen Bea Cukai</label>
                <p>{ _.get(step1, 'orders.responseNo', '') }</p>
              </div>
              <div className="form-group">
                <label>Tanggal Dokumen Bea Cukai</label>
                <p>{ parseToDate(_.get(step1, 'orders.responseDate', ''), 'date') }</p>
              </div>
            </div>
          </div>
          
          <div className="row mt-5">
            <div className="col-12">
              <h4>List Container</h4>
              <div className="mt-3 d-flex align-items-center">
                <div className="mr-3">Pencarian</div>
                <input 
                  id="search"
                  type="text" 
                  className="form-control" 
                  placeholder="Kata Kunci" 
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="mt-4">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>CONTAINER NO</th>
                      <th>ISO CODE</th>
                      <th>SIZE</th>
                      <th>F/E</th>
                      <th>DG</th>
                      <th>OOG</th>
                      <th>REEFER</th>
                      <th>DISCHARGE</th>
                      <th>PLUG IN</th>
                      <th>PLUG OUT</th>
                      <th>PAID THROUGH</th>
                      <th>BILLING</th>
                      <th>BEHANDLE</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.filterContainer().length > 0 ?
                      this.filterContainer().map((container, i) => (
                        <tr key={i}>
                          <td>{ container.contNo }</td>
                          <td>{ container.isocode }</td>
                          <td>{ container.size }</td>
                          <td>{ container.fullEmpty }</td>
                          <td>{ container.dg }</td>
                          <td>{ container.oogs }</td>
                          <td>{ container.reefer }</td>
                          <td>{ parseToDate(container.inTime, 'dateTime') }</td>
                          <td></td>
                          <td></td>
                          <td>{ parseToDate(container.paidThroughDate, 'dateTime') }</td>
                          <td>{ container.billing || '-' }</td>
                          <td>{ container.behandle || '-' }</td>
                          <td>{ container.status }</td>
                        </tr>
                      ))
                    :
                      <tr>
                        <td colSpan={15} className="text-center">
                          Not found
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-3">
            <div className="col-sm-6">
              <button className="btn btn-primary btn-lg btn-block" onClick={() => this.props.setStep(2)}>{this.state.loading ? <Pulse/> : 'Sebelumnya'}</button>
            </div>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary btn-lg btn-block">
                {this.state.loading ? <Pulse/> : 'Submit Order'}
              </button>
            </div>
          </div>
        </form>
      
        {this.state.openModal &&
          <OrderVerificationModal 
            openModal={this.state.openModal}
            closeModal={() => this.closeModal()}
            resend={() => this.handleSubmit()}
            actionType={this.state.actionType}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.import_gatepass
  }
}

const mapDispatchToProps = (dispatch) => ({
  setImportGatepass: (payload) => dispatch({type: 'orders.import_gatepass', payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
