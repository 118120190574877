const initialState = '';

function remarks(state = initialState, action) {
  switch (action.type) {
  case 'orders.remarks':
    return action.payload;
  default:
    return state;
  }
}

export default remarks;
