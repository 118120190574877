import {combineReducers} from 'redux';

import step from './step'
import locations from './locations'
import vehicle_type from './vehicle_type'
import vehicle_area from './vehicle_area'
import pickup_time from './pickup_time'
import pickup_type from "./pickup_type";
import button from './button'
import remarks from './remarks'
import maps from './maps'
import addons from "./addons";
import promo from "./promo";
import delivery_type from "./delivery_type";
import gatepass_no from "./gatepass_no";
import reference_no from "./reference_no";
import depo from "./depo";
import is_depo from "./is_depo";
import apk from "./apk";

const compose = (...args) => (state, action) => {
  return args.reduce((currentState, f) => f(currentState, action), state);
};

const reorderReducer = (state, action) => {
  switch (action.type) {
  case 'orders.reorder':
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};

const orders = compose(
  combineReducers({
    step,
    locations,
    vehicle_type,
    vehicle_area,
    pickup_time,
    pickup_type,
    button,
    addons,
    remarks,
    maps,
    promo,
    delivery_type,
    gatepass_no,
    reference_no,
    depo,
    is_depo,
    apk,
  }),
  reorderReducer,
);

export default orders;
