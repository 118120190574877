import React, {Component} from 'react'
import PropTypes from 'prop-types'

import './Negotiation.css'
import Stepper from './Stepper';

import {connect} from 'react-redux';
import routes from "../routes/apk";
import {Redirect, Route, Switch} from "react-router-dom";
import utils from '../lib/utils';
import MapsApk from './MapsApk';

class APK extends Component {

  constructor(props) {
    super(props);
    this.thisRef = React.createRef();
  }

  steps = [
    {
      label: 'Rute',
      icon: ['assets/img/icon/route.png', 'assets/img/icon/route-inactive.png'],
      path: '/dashboard/apk/route',
    },
    {
      label: 'Detail',
      icon: ['assets/img/icon/detail.png', 'assets/img/icon/detail-inactive.png'],
      path: '/dashboard/apk/contact',
    },
    {
      label: 'Bayar',
      icon: ['assets/img/icon/price.png', 'assets/img/icon/price-inactive.png'],
      path: '/dashboard/apk/pay',
    },
  ];

  scrollToTop = () => {
    this.thisRef.current && this.thisRef.current.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const lastGoodStep = utils.getLastGoodStep(this.props.orders);
    return (
      <div className="row" style={{margin: 0}}>
        <div ref={this.thisRef} className="col-xs-12 col-md-7 col-sm-7 col-lg-4 col-xl-4 scrollable" style={{
          minWidth: '320px',
          maxWidth: '450px',
          zIndex: 1,
          minHeight: 'calc(100vh - 60px)',
          paddingLeft: '0px',
          paddingRight: '0px'
        }}>
          <div className="card-body info-body pos-unset-xs" style={{position: 'absolute', minWidth: '100%'}}>
            <div className="shadow bg-white hard-round">
              <Stepper
                model={{steps: this.steps, lastGoodStep}}
                onClick={this.props.handleStepperClick}
              />
              <div className="p-4">
                <Switch>
                  {routes.map(route => (
                    <Route
                      key={route.path}
                      path={route.path}
                      render={(props) => <route.component scrollToTop={this.scrollToTop} {...props} />}
                    />
                  ))}
                  <Redirect from='/dashboard/apk/' to='/dashboard/apk/route'/>
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <div style={{width: '100%', position: 'absolute'}} className='pos-unset-xs'>
          <MapsApk
            disableDragOrigin={true}
          />
        </div>
      </div>
    )
  }
}

APK.propTypes = {
  match: PropTypes.object,
  step: PropTypes.number.isRequired,
  handleStepperClick: PropTypes.func.isRequired,
  orders: PropTypes.object,
};

const mapStateToProps = (state) => ({
  step: state.orders.step,
  orders: state.orders,
});

const mapDispatchToProps = (dispatch) => ({
  handleStepperClick: (idx) => dispatch({type: 'orders.step.goto', payload: idx}),
});

export default connect(mapStateToProps, mapDispatchToProps)(APK);
