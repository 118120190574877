import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Maps from "./Maps";
import './Home.css'

class Home extends Component {

  constructor(props) {
    super(props);
    this.thisRef = React.createRef();
  }

  render() {
    return (
      <div className="row" style={{ margin: 0 }}>
        <div
          ref={this.thisRef}
          className="col-xs-12 col-md-7 col-sm-7 col-lg-4 col-xl-4 scrollable"
          style={{
            minWidth: "320px",
            maxWidth: "450px",
            zIndex: 1,
            minHeight: "calc(100vh - 60px)",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div
            className="card-body info-body pos-unset-xs"
            style={{ position: "absolute", minWidth: "100%", height: "100%" }}
          >
            <div
              className="shadow bg-white hard-round"
              style={{ minHeight: "100%", display: "contents" }}
            >
              <div className="card" style={{ minHeight: "100%" }}>
                <div className="card-body">
                  <h4 className="card-title head-home-text">
                    Selamat datang di Ritase Retail Logistic andalan UMKM
                    Indonesia!
                  </h4>
                  <Link to="/dashboard/orders">
                    <div className="card card-home mt-5 mb-3">
                      <div className="card-img">
                        <img
                          src="assets/img/order-cepat.svg"
                          alt="Order Cepat"
                        ></img>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Order cepat</h4>
                        <p className="card-text">
                          Harga fix, jaminan dapat armada, pengiriman khusus
                          area Jabodetabek
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/dashboard/negotiation">
                    <div className="card card-home mb-3">
                      <div className="card-img">
                        <img
                          src="assets/img/minta-penawaran.svg"
                          alt="Minta Penawaran"
                        ></img>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Minta penawaran</h4>
                        <p className="card-text">
                          Harga flexible, bisa nego harga, untuk pengiriman luar
                          kota dan antar pulau
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/dashboard/npct1">
                    <div className="card card-home mb-3">
                      <div className="card-img">
                        <img
                          src="assets/img/npctretail.svg"
                          alt="Trailer Truck Order"
                        ></img>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Trailer Truck Order</h4>
                        <p className="card-text">
                          Solusi armada dengan harga terbaik untuk kargo
                          container di pelabuhan
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/dashboard/apk">
                    <div className="card card-home">
                      <div className="card-img">
                        <img
                          src="assets/img/apkicon.svg"
                          alt="Angkasa Pura Kargo Order"
                        ></img>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Angkasa Pura Kargo Order</h4>
                        <p className="card-text">
                          Pengiriman terintegrasi dengan bandara. Dapatkan kemudahan pengiriman kargo langsung ke tempat Anda
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="card-footer footer-track-button">
                  <a
                    href="http://track.ritase.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn-block btn btn-outline-ritase"
                  >
                    Lacak Pesanan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ width: "100%", position: "absolute" }}
          className="pos-unset-xs"
        >
          <Maps />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  match: PropTypes.object,
};

export default Home;
