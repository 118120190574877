const initialState = {
    action: "",
    bookingNo: "",
    blNumber: "",
    doExpired: null,
    doNumber: "",
    messageId: "",
    paymentPlan: null,
    requestType: 'PEB',
    requestNumber: '',
    requestDate: null,
    responseType: 'NPE',
    responseNumber: '',
    responseDate: null,
    step0: {},
    step1: {},
    step3: {},
    selectedContainer: []
  };
  
  function export_gatepass(state = initialState, action) {
    if (action.type === 'orders.export_gatepass') {
      return {
        ...state,
        ...action.payload
      };
    } if (action.type === 'orders.export_gatepass.reset') {
      return initialState
    } else {
      return state
    }
  }
  
  export default export_gatepass;
  