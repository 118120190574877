import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Contact from "../Contact";
import utils from '../../lib/utils';
import 'firebase/database';
import * as Swal from "sweetalert2";

import routeOrders from '../../routes/orders';

class Contacts extends Component {

  componentDidMount = () => {
    this.props.scrollToTop();
    this.props.setStep();
    const lastGoodStep = utils.getLastGoodStep(this.props.orders);
    if (lastGoodStep < 0) {
      window.location.href = '#' + routeOrders[lastGoodStep + 1].path;
      return;
    }
  }

  handleNext = () => {
    try {
      utils.validateOrder(this.props.orders, 1);
      this.props.handleNext();
      window.location.href = '#/dashboard/orders/pay';
    } catch (err) {
      Swal.fire({
        type: 'error',
        text: err.message,
      })
    }
  }

  render() {
    return (
      <div id="add-ons">
        <h6 className="mb-2"><b>Informasi Kontak</b></h6>
        {this.props.locations.map((location, id) => (
          <Contact
            key={id}
            {...location}
            idx={id}
            handleChange={this.props.handleChangeContact(location.id)}
          />
        ))}

        <div className="mt-5">
          <button className="btn btn-outline-danger" style={{width: '45%'}} onClick={() => {
            window.location.href = '#/dashboard/orders/route';
            this.props.handleBack()
          }}>
            Kembali
          </button>
          <button className="btn btn-primary float-right" style={{width: '45%'}} onClick={this.handleNext}>
            Selanjutnya
          </button>
        </div>
      </div>
    )
  }
}

Contacts.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleChangeRemarks: PropTypes.func.isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  remarks: PropTypes.string,
  locations: PropTypes.array,
  step: PropTypes.number.isRequired,
  orders: PropTypes.object,
  setStep: PropTypes.func,
  scrollToTop: PropTypes.func,
};

const mapStateToProps = (state) => ({
  remarks: state.orders.remarks,
  locations: state.orders.locations,
  step: state.orders.step,
  orders: state.orders,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: () => dispatch ({type: 'orders.step.goto', payload: 1}),
  handleNext: () => {
    dispatch({type: 'orders.step.next'})
  },
  handleBack: () => dispatch({type: 'orders.step.back'}),
  handleChangeRemarks: (event) => dispatch({type: 'orders.remarks', payload: event.target.value}),
  handleChangeContact: (id) => (field) => (event) => {
    dispatch({
      type: 'orders.location.update',
      payload: {
        id,
        [field]: event.target.value
      }
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
