import React, {Component} from 'react'
import PropTypes from "prop-types";

export default class Input extends Component {
  render() {
    const props = this.props;
    return (
      <li className="list-group-item" key={props.name}>
        <div className="row">
          <div className="align-top col-6">
            <div>{props.label}
              <i className="icon icon-info2 ml-2 s-18 cur-help"
                onMouseDown={this.props.handleTooltip.bind(this, props)}/></div>
            <span style={{fontSize: '10px'}} className="mt-0 text-success align-top">{props.note}</span>
          </div>
          <div className="form-group-sm m-auto col-6">
            <input type="text" name={props.name} className="form-control" onChange={this.props.handleChange} value={this.props.value}/>
          </div>
        </div>
      </li>
    )
  }
}

Input.propTypes = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleTooltip: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};
