import React, {Component} from 'react';
import network from './lib/network';
import utils from './lib/utils';
import * as Swal from "sweetalert2";
import Pulse from "./components/spinner/Pulse";
import store from './store';
import NumberFormat from 'react-number-format';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expired: false,
      loading: false,
      name: "",
      email: "",
      phone_number: "",
      npwp: "",
      password: "",
      password_confirmation: "",
      validate: false,
      user_id: '',
      check: {
        name: false,
        email: false,
        phone: false,
        npwp: false,
        password: "",
        password_confirmation: "",
      }
    };
  }

  validateForm() {
    return Object.values(this.state.check).every(x => x)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, this.check);
  };

  check() {
    this.setState({
      check: {
        name: /^([a-zA-Z\s+]){4,32}$/.test(this.state.name),
        email: utils.isValidEmail(this.state.email) && this.state.email.length > 0,
        phone: utils.isValidPhoneNumber(this.state.phone_number),
        npwp: this.state.npwp.length === 0 || this.state.npwp.length === 15,
        password: !this.state.password || (this.state.password && this.state.password.length >= 8),
        password_confirmation: this.state.password === this.state.password_confirmation
      }
    });
  }

  componentDidMount = async () => {
    const params = utils.getParams(this.props.location.search);
    this.setState({ next: params.get('next') });

    const me = await network.GET('/users/me', {}, {}, true);
    this.setState({
      user_id: me.id,
      email: me.email,
      phone_number: me.phone_number,
      name: me.name,
      npwp: me.company.npwp_number || ""
    });
    this.check()
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.check();
    this.setState({
      loading: true
    });
    if (!this.validateForm()) {
      this.setState({
        loading: false
      });
      return;
    }
    try {
      let data = {
        name: this.state.name,
        phone_number: this.state.phone_number,
        email: this.state.email,
        npwp_number: this.state.npwp
      }

      if (this.state.password && this.state.password.length > 0) {
        data.password = this.state.password;
      }

      const response = await network.PUT(`/users/${this.state.user_id}`, data, {}, true);

      if ('errorMessage' in response) {
        if (response.errorMessage.includes('ER_DUP_ENTRY'))
          throw new Error('Mohon maaf, data yang Anda masukkan telah terdaftar di sistem kami');
        throw new Error(response.errorMessage);
      } else {
        const me = await network.GET('/users/me', {}, {}, true);
        store.dispatch({ type: 'auth.update', data: { user: me } })
        Swal.fire({
          type: 'success',
          text: 'Perubahan berhasil!',
        });
      }

      this.setState({
        loading: false
      });

      document.location.href = this.state.next ? '/#' + this.state.next : '/#/dashboard'
    } catch (err) {
      this.setState({
        loading: false
      });
      await Swal.fire({
        type: 'error',
        text: err,
      });
    }
  };

  render() {
    return (
      <main>
        <div id="primary" className="p-t-b-100 height-full">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-md-auto">
                <div className="text-center">
                  <img src="/assets/img/logo.png" alt="" style={{maxWidth: '75%'}}/>
                  <h3 className="mt-2">Profil Anda</h3>
                </div>
                <form action="/" onSubmit={this.handleSubmit}>
                  <label htmlFor="name">
                    Nama
                  </label>
                  <div className="form-group has-icon"><i className="icon-person"/>
                    <input type="text"
                      placeholder='Nama'
                      className={this.state.validate && !this.state.check.name ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.name}
                      onChange={this.handleChange}
                      id="name"
                    />
                    <div className="invalid-feedback">
                      Nama wajib huruf dan setidaknya 4-32 karakter
                    </div>
                  </div>
                  <label htmlFor="email">
                    Email
                  </label>
                  <div className="form-group has-icon"><i className="icon-envelope-o"/>
                    <input type="text"
                      placeholder='Email'
                      className={this.state.validate && !this.state.check.email ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                    />
                    <div className="invalid-feedback">
                      Format email tidak sesuai
                    </div>
                  </div>
                  <label htmlFor="phone_number">
                    Nomor handphone
                  </label>
                  <div className="form-group has-icon"><i className="icon-phone"/>
                    <input type="tel"
                      placeholder='Nomor Handphone'
                      className={this.state.validate && !this.state.check.phone ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.phone_number}
                      onChange={this.handleChange}
                      id="phone_number"
                    />
                    <div className="invalid-feedback">
                      Nomor wajib angka dan setidaknya 10-13 karakter
                    </div>
                  </div>
                  <label htmlFor="npwp">
                    NPWP (optional)
                  </label>
                  <div className="form-group has-icon"><i className="icon-id-card-o"/>
                    <NumberFormat 
                      id="npwp"
                      value={this.state.npwp} 
                      className={this.state.validate && !this.state.check.npwp ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      placeholder="NPWP"
                      format="##.###.###.#-###.###"
                      thousandSeparator={false} 
                      allowLeadingZeros={true}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        const event = {
                          target: {
                            id: 'npwp',
                            value
                          }
                        }
                        this.handleChange(event)
                      }} />
                    <div className="invalid-feedback">
                      NPWP harus 15 angka
                    </div>
                  </div>
                  <label htmlFor="password">
                    Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-eye"/>
                    <input type="password"
                      placeholder='Password'
                      className={this.state.validate && !this.state.check.password ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                    />
                  </div>
                  <label htmlFor="password">
                    Konfirmasi Password
                  </label>
                  <div className="form-group has-icon"><i className="icon-eye"/>
                    <input type="password"
                      placeholder='Konfirmasi Password'
                      className={this.state.validate && !this.state.check.password_confirmation ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                      value={this.state.password_confirmation}
                      onChange={this.handleChange}
                      id="password_confirmation"
                    />
                    <div className="invalid-feedback">
                      Password tidak sama.
                    </div>
                  </div>
                  <div className='mt-5'>
                    <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={() => {
                      this.setState({
                        validate: true
                      })
                    }}>{this.state.loading ? <Pulse/> : 'Ubah'}</button>
                  </div>
                </form>
                <button id='back' className="btn btn-lg btn-outline-danger btn-block mt-2" onClick={() => {
                  window.history.back();
                }}>
                  Kembali
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Profile;
