import React, {Component} from 'react';
import network from "../lib/network";
import * as Swal from "sweetalert2";
import utils from '../lib/utils';
import Pulse from "../components/spinner/Pulse";

class Forgot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: "",
      loading: false,
      validate: false,
    };
  }

  validateForm() {
    return (utils.isValidEmail(this.state.email)) && this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {

    event.preventDefault();
    this.setState({
      validate: true
    });

    if (this.validateForm()) {
      this.setState({
        loading: true,
      });

      event.preventDefault();
      network.POST('/users/retail/forgot', {
        email: this.state.email
      }).then((result) => {
        if (result && result.errorMessage) throw result;
        else {
          Swal.fire({
            type: 'success',
            title: 'Terima kasih',
            text: 'Kami akan mengirimkan email perubahan password pada email yang terdaftar.',
          });
          document.location.href = '#/';
        }
      }).catch((error) => {
        this.setState({
          loading: false,
        });
        Swal.fire({
          type: 'error',
          text: error && error.errorMessage || 'Permintaan lupa password gagal. Mohon coba kembali.',
        });
      });
    }
  };

  render() {
    return (
      <main>
        <div id="primary" className="p-t-b-100 height-full ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-md-auto">
                <div className="text-center">
                  <img src="/assets/img/logo.png" alt="" style={{maxWidth: '75%'}}/>
                  <h3 className="mt-2">Lupa Password</h3>
                </div>
                <form action="/" onSubmit={this.handleSubmit}>
                  <label htmlFor="email">
                      Email
                  </label>
                  <div className="form-group has-icon"><i className="icon-envelope-o"/>
                    <input type="text"
                      className={`form-control form-control-lg ${!this.validateForm() && this.state.validate ? 'is-invalid' : ''}`}
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                      placeholder="Email"
                    />
                    <div className="invalid-feedback">
                      Format email tidak sesuai
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="btn btn-primary btn-lg btn-block">{this.state.loading ? <Pulse/> : 'Kirim'}</button>
                    <br/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Forgot;
