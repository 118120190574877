import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import network from '../../lib/network';
import utils from '../../lib/utils';
import auth from '../../lib/auth';
import { Link } from 'react-router-dom';
import Pulse from '../spinner/Pulse';

class ProformaCalculation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: [],
      isLoading: false,
      container: null,
      listItem: null,
      isWaitingForCalculation: false,
    }
  }

  getProformaCalculation = async () => {
    if (auth.check()) {
      this.setState({
        isLoading: true,
      });
      const referenceNumber = this.props.match.params.reffNumber;
      try {
        const item = await network.POST('/econ/proforma/calculation', { referenceNumber }, {}, true);
        if (item.errorMessage) {
          throw item;
        }
        let container = null;
        if (_.get(item, 'collections.container.length', 0) > 0) {
          container = item.collections.container;
        }
        const list = await network.GET('/econ/list', { reference_number: referenceNumber }, {}, true);

        let listItem = null;
        if (_.get(list, 'rows.length', 0) > 0) {
          listItem = list.rows[0];
          if (listItem.invoice_due_date) listItem.invoice_due_date = new Date(listItem.invoice_due_date);
        }

        this.setState({
          item, container, listItem, isLoading: false, isWaitingForCalculation: false
        });
      } catch (e) {
        console.log('error', e);
        this.setState({
          isWaitingForCalculation: true,
          isLoading: false,
        });
      }
    } else {
      network.logout('Silakan login terlebih dahulu', true);
    }
  }

  componentDidMount = async () => {
    this.getProformaCalculation();
  }

  openPdf = (type) => {
    var baseUrl = window.location.origin;

    var url = `${baseUrl}/assets/files/econ/${type}.pdf`;

    window.open(url, 'blank');
  }

  render() {
    const { item, container, listItem } = this.state;
    const payments = [
      { value: 'payment_via_mandiri_internet_banking', label: 'Mandiri Internet Banking' },
      { value: 'payment_via_bca_internet_banking', label: 'BCA Internet Banking' },
      { value: 'payment_via_atm', label: 'ATM (Anjungan Tunai Mandiri)' },
      { value: 'payment_via_edc', label: 'EDC (Electronic Data Capture)' },
    ]

    return (
      <Fragment>
        <Navbar/>
        <Sidebar/>
        <Content>
          <div id="primary" className="p-t-b-50 height-full">
            <div className="container">
              <div className="row">
                <span title="Kembali ke halaman NPCT-1 Gate Pass">
                  <Link to='/npct1/order-container'>NPCT-1 Gate Pass</Link>
                </span>
                <span>&nbsp;/&nbsp;</span>
                <span>Proforma</span>
              </div>
              <div className="row">
                <div className="col-lg-12 mx-md-auto">
                  <div className="text-center">
                    <h3 className="mt-2">Proforma</h3>
                  </div>
                  {this.state.isWaitingForCalculation && !this.state.isLoading && <div className="alert alert-info">
                    <span>Harap menunggu proses NPCT1</span>
                    <span className="float-right">
                      <button className="btn btn-link" style={{ padding: '0' }} onClick={() => this.getProformaCalculation() }>
                        <i className="icon-refresh"></i>&nbsp; Refresh Data
                      </button>
                    </span>
                  </div>}
                  {
                    !this.state.isLoading ?
                    (
                      <div>
                        <div className="row mt-5">
                          <div className="col-md-9">
                            <h4>Item</h4>
                            <div className="mt-3 mb-3">
                              <table className="table bg-white black-text">
                                <thead className="bg-ritase white-text">
                                  <tr>
                                    <th>Deskripsi</th>
                                    <th>Jumlah</th>
                                    <th>UOM</th>
                                    <th>Tarif</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>
                                {_.get(item, 'collections.detail', []).map((dt, i) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td colSpan={5} className="text-left">{ dt.description }</td>
                                    </tr>
                                    <tr>
                                      <td className="text-left">{dt.item}</td>
                                      <td>{ dt.qty ? Number(dt.qty) : 0 }</td>
                                      <td>{ dt.uom }</td>
                                      <td>{ utils.formatRupiah(dt.tarif) }</td>
                                      <td>{ utils.formatRupiah(dt.total) }</td>
                                    </tr>
                                  </tbody>
                                ))}
                                <tbody>
                                  <tr>
                                    <td colSpan={4} className="text-left">Biaya Administrasi</td>
                                    <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.adminCharges', '')) }</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4} className="text-left">Dasar Pengenaan Pajak</td>
                                    <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.basicTax', '')) }</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4} className="text-left">Jumlah PPN</td>
                                    <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.tax', '')) }</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4} className="text-left">Bea Materai Lunas</td>
                                    <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.stamp', '')) }</td>
                                  </tr>
                                  <tr className="grey">
                                    <td colSpan={4} className="text-left">Total Jumlah Bayar</td>
                                    <td colSpan={1} className="text-right">{ utils.formatRupiah(_.get(item, 'collections.header.totalPayment', '')) }</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <h4>Pelanggan</h4>
                            <div className="mt-3">
                              <div className="mb-3">
                                <h6>Vessel / Voyage</h6>
                                <h5 className="font-weight-bold">{ _.get(item, 'collections.header.vesselName', '') } / { _.get(item, 'collections.header.voyage', '') }</h5>
                              </div>
                              <div className="mb-3">
                                <h6>Customer</h6>
                                <h5 className="font-weight-bold">{ _.get(item, 'collections.header.customerName', '') }</h5>
                              </div>
                              <div className="mb-3">
                                <h6>Alamat</h6>
                                <h5 className="font-weight-bold">{ _.get(listItem, 'company_address', '') }</h5>
                              </div>
                              <div className="mb-3">
                                <h6>Tax ID</h6>
                                <h5 className="font-weight-bold">{ _.get(listItem, 'company_tax_id', '') }</h5>
                              </div>
                            </div>

                            <h4 className="mt-5">Proforma</h4>
                            <div className="mt-3">
                              <div className="mb-3">
                                <h6>No.</h6>
                                <h5 className="font-weight-bold">{ _.get(item, 'collections.header.proformaNo', '') } / { _.get(item, 'collections.header.voyage', '') }</h5>
                              </div>
                              <div className="mb-3">
                                <h6>Paid Through</h6>
                                <h5 className="font-weight-bold">{ _.get(listItem, 'invoice_due_date', false) && format(new Date(listItem.invoice_due_date), 'dd MMMM yyyy') }</h5>
                              </div>
                              <div className="mb-3">
                                <h6>Total Tagihan</h6>
                                <h5 className="font-weight-bold">{ utils.formatRupiah(_.get(item, 'collections.header.totalPayment', '')) }</h5>
                              </div>
                            </div>

                            <div className="mt-4">
                              {_.get(listItem, 'status_payment', '').toLowerCase() === 'paid' ?
                                <div className="alert alert-success">
                                  Pembayaran telah terkonfirmasi. Klik <Link to={`/npct1/invoice/${encodeURIComponent(listItem.proforma_number)}`}>disini untuk data invoice</Link>
                                </div>
                                :
                                <div className="alert alert-warning">
                                  Silahkan lakukan pembayaran
                                </div>
                              }
                            </div>

                            <h4 className="mt-4">Tata Cara Pembayaran</h4>
                            <div className="mt-3">
                              <ul className="pl-3">
                                {payments.map(payment => (
                                  <li
                                    key={payment.value}
                                    className="pointer mb-1 btn-link grey-text"
                                    style={{listStyle: 'disc'}}
                                    onClick={() => this.openPdf(payment.value)}
                                  >
                                    {payment.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-md-9">
                            <h4>Petikemas</h4>
                            {container && container.map((cont, i) => (
                              <div key={i} className="w-100 text-center p-2 mb-2" style={{border: '1px dashed #9e9e9e'}}>
                                { _.get(cont, 'containerNo', '') } / { _.get(cont, 'containerSize', '') } / { _.get(cont, 'containerType', '') } / { _.get(cont, 'containerStatus', '') }
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : <div className="text-center" style={{ paddingTop: '25%' }}>
                        <Pulse size='32' color='#606676' />
                      </div>      
                    }
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

export default ProformaCalculation;
