const initialState = {
  action: "",
  blNumber: "",
  doExpired: null,
  doNumber: "",
  messageId: "",
  paymentPlan: null,
  requestType: 'PIB',
  requestNumber: '',
  requestDate: null,
  responseType: 'SPB',
  responseNumber: '',
  responseDate: null,
  step0: {},
  step1: {},
  step3: {},
  selectedContainer: []
};

function import_gatepass(state = initialState, action) {
  if (action.type === 'orders.import_gatepass') {
    return {
      ...state,
      ...action.payload
    };
  } if (action.type === 'orders.import_gatepass.reset') {
    return initialState
  } else {
    return state
  }
}

export default import_gatepass;
