const init = 0;

function step(state = init, action) {
  switch (action.type) {
  case 'orders.step.next':
    return state + 1;
  case 'orders.step.back':
    return state - 1;
  case 'orders.step.goto':
    return action.payload;
  default:
    return state
  }
}

export default step;
